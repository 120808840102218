  import { Component, HostListener, OnInit,ViewChild,ElementRef,Input } from '@angular/core';
  import { ChatbotService } from './../../common/services/chatbot.service';
  import { AlertMessageModel, InsightsModel, results } from 'src/app/common/models/common.model';
  import { CrosstabService } from '../../common/services/crosstab.service';
  import { LoaderService } from 'src/app/common/services/loader.service';
  import { ExportDownloadServices } from 'src/app/common/services/exports-downloads.service';
  import {
    ExcelExportReport,
    ExcelExportReportInputs,
  } from 'src/app/common/models/excel-export-report.model';
  import { Subscription } from 'rxjs';
  import {
    DataObject,
    checkboxSelection,
    checkboxSelectionWithParent,
    Level,
    OutputDataObject,
    OutputSelection,
    OutPutSelectionWithParent,
    CrossTabOutputItem,
    ExcelDataModel,
    Data,
    Child,
    Entry,
    DataEntry,
    SelectionObject,
    StackChart,
    LineChart,
    GroupBarChart,
    ChartDetails,
    CancelEntryPointItem,

  } from 'src/app/common/models/crosstab.model';
  import { Feature } from 'src/app/common/models/usermanagement.model';
  import { InsightspopupService } from 'src/app/common/services/insightspopup.service';
  import { Status } from 'src/app/common/models/enum/status.enum';
  import { set } from 'lodash';
  import { fomData, GetDataArray, StudyData } from 'src/app/common/models/chatbotexpand.model';
  @Component({
    selector: 'app-chatbotexpand',
    templateUrl: './chatbotexpand.component.html',
    styleUrls: ['./chatbotexpand.component.css']
  })

  export class ChatbotexpandComponent implements OnInit {
    IndexData: any;
    isTranspose: boolean = false;
    isTotalBase: boolean = false;
    isTotalBaseFromPrompt: string = "false";
    isAnswerBase: boolean = false;
    selectedWeight: string = "";
    selectedIndex: string = "";
    insightsVal: any = ""
    insufficientBase: string = "";
    DefaultConfidenceLevel: string = "";
    isSampleSize: boolean = false;
    isWeightedLabel: boolean = false;
    LowBase: string = "";
    isPairWise: boolean = false;
    DefaultSampleSize: string = 'None';
    @Input() exportDownloads: boolean = true;
    @Input() pptExport: boolean = true;
    threshold: string = "";
    isTotal: boolean = false;
    displayTable: boolean = false;
    confidenceLevelDropDown: boolean = false;
    referenceLevelDropDown: boolean = false;
    is_Time_Period_Present: boolean = true;
    sampleSizeDropdown: boolean = false;
    isSignificanceContainerDisabled: boolean = false; // to disable significance container
    isReferenceSig: boolean = false;
    isReference: boolean = false;
    isDisablePairWise: boolean = true;
    isDisableReference: boolean = true;
    isDemoStudy: boolean = false;
    isDocumentRepositoryClick: boolean = false;
    mandatoryVariableMapping: any;
    
    chartType = this.isTranspose == true ? 'bar' : 'column';
    inputValue: any = "";
    inputValueShow: any = "";
    isInsightspopupOpen: boolean = false;
    OutputData: any;
    selected_study: any;
    selected_client: any
    isVerticalBarChartClick: boolean = false;
    isTableView: boolean = false;
    isChartView: boolean = true;
    isTableClick: boolean = false;
    isLineChartClick: boolean = false;
    isVerticalStackChartClick: boolean = false;
    isVerticalGroupBarChartClick: boolean = false;
    isSaveSelectionClick = false;
    isSaveCustomGroupSelectionClick = false;
    isSaveCustomCalculationSelectionClick = false;
    disableStackChart: boolean = false;
    disableLineChart: boolean = false;
    SampleSizeList: any = [];
    isCreateBtnClick: boolean = false;
    showBarTooltip: boolean = false;
    searchTextLevel1: string = '';
    is_confidenceLevel: any = [];
    showStackTooltip: boolean = false;
    SignificanceVsReference: string = '';
    is_TransposeBtn: boolean = false;
    showMainTooltip: boolean = false;
    showLineTooltip: boolean = false;
    dataDropDown: boolean = false;
    is_chart_visualization: boolean = false;
    DefaultDataDropDown: string = 'Data';
    is_indexing: boolean = false;
    isSig: boolean = true;
    @Input() isIndex: boolean = false;
    is_sigtesting: boolean = false;
    DefaultReferenceLevel: string = 'None';
    SampleSizeContainer:boolean = false;
    isSampleSizeBlock: boolean = false;
    
    dataDropDownList: string[] = [
      'Data',
      'Sample Size',
      'Weighted Sample',
      'Weighted Numerator',
      'Unweighted Numerator',
    ];
    @ViewChild('summaryText') summaryText: ElementRef | undefined;
    @ViewChild('SidePanel') SidePanel: ElementRef | undefined;
    @ViewChild('inforRibbon', { static: false }) inforRibbon:
      | ElementRef
      | undefined;
    GeneralToolTip: string[] = [
      "Charting Option to be disabled when using Indexation.",
      "Charting Option to be disabled when anything is selected <br> in Row Nesting.",
      "Charting Option to be disabled when more than one metric <br> is selected in Rows.",
      "Charting Option to be disabled when anything is selected <br> in Column Nesting."
    ];

    StackChartToolTip: string[] = [
      "This option is disabled for multipunch variables when<br>selected in the Rows.",
      "This will be enabled only when all the options within the<br>selected metric are chosen in the Rows.",
      "You can select a maximum of 15 options in the Rows.<br>If you select more than 15 options,charting options <br> will be disabled.",
      "Charting Option to be disabled when anything is<br>selected in Row Nesting.",
      "Charting Option to be disabled when more than one<br>metric is selected in Rows.",
      "Charting Option to be disabled when anything is<br>selected in Column Nesting."
    ];

    LineChartToolTip: string[] = [
      "Trend will be available only when a single<br>Time Period Group is selected in the columns.",
      "There will be one trend line for each option<br>selected in the Rows.",
      "You can select a maximum of 15 options in the Rows.<br> If you select more than 15 options,charting<br>options will be disabled.",
      "Charting Option to be disabled when anything<br>is selected in Row Nesting.",
      "Charting Option to be disabled when more<br>than one metric is selected in Rows.",
      "Charting Option to be disabled when anything<br>is selected in Column Nesting."
    ];

    BarChartToolTip: string[] = [
      "You can select a maximum of 15 options in the Rows. If you select more than 15 options, charting options will be disabled.",
      "Charting Option to be disabled when anything is selected <br> in Row Nesting.",
      "Charting Option to be disabled when more than one metric <br> is selected in Rows.",
      "Charting Option to be disabled when anything is selected <br> in Column Nesting."
    ];
    variableTabData: any;
    StackFlag: boolean = false;
    isData: boolean = true;
    is_brand_analysis: boolean = false;
    isWeightedNumerator: boolean = false;
    isUnWeightedNumerator: boolean = false;
    isBrandAnalysis: boolean = false;
    $unSubscribeSubmitData1!: Subscription;
    $unSubscribeSubmitData2!: Subscription;
    $unSubscribeSubmitData3!: Subscription;
    $unSubscribeSubmitData4!: Subscription;
    $unSubscribeSubmitData5!: Subscription;
    $unSubscribeSubmitData6!: Subscription;
    $unSubscribeSubmitData7!: Subscription;
    $unSubscribeSubmitData8!: Subscription;
    $unSubscribeSubmitData9!: Subscription;
    receivedData: StudyData[] = [];
    clientcode: any;
    is_sticky_selection: boolean = false;
    is_reset_selection: any;
    excelExport: boolean = false;
    is_Transpose: any;
    is_ranking: any;
    isFilterToggle: any;
    isAnswerToggle: any;
    comparisionpoint: any;
    confidenceLevel: any;
    repository: any;
    lowBaseIdentifier: any;
    is_AI_Integration: any;
    is_StoryBoard: any;
    is_Column_Row_Percentage: any;
    insufficentBase: any;
    lowBase: any;
    configweights: any;
    iscustomgrp: any;
    iscustomCalc: any;
    iscustomreport: any;
    changeEntryPoint: any;
    isWeightedBase: boolean = false;
    aggregatedVariables: any;
    isSelectAllMetrics: any;
    isWeightedSampleBlock: boolean = false;
    filteringAnsBlock: boolean = false;
    AnsBaseBlock: boolean = false;
    isRowNesting: boolean = false;
    isColumnNesting: boolean = false;
    isSelectAllMetricsLevel: boolean = false;
    sidePanelDisplay: boolean = false;
    sidePanelNestingDisplay: boolean = false;
    columnPanelDisplay: boolean = false;
    columnNestingPanelDispaly: boolean = false;
    filterPanelDisplay: boolean = false;
    isSelectionSummary: boolean = false;
    weightDropDown: boolean = false;
    showCustomMetricsPopup: boolean = false;
    dataArray: any[] = [];
    fomDataArray:any[]=[];
    isAggregatedData: boolean = false;
    isSemiAggregatedData: boolean = false;
    columnLength: number = 0;
    listBItemsList: {
      Level0: string;
      Level1: string;
      tooltip: string;
      has_nextLevel: boolean;
      sortId: number;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      is_dimension: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
    }[] = [];
    listDItemsList: {
      Level0: string;
      Level1: string;
      tooltip: string;
      has_nextLevel: boolean;
      sortId: number;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      is_dimension: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
    }[] = [];
    selectedDataFormat: string = '';
    DefaultWeight: string = '';
    selectionSummary: string = '';
    isFilteringActive: boolean = true;
    leftpanelDataArray: any[] = [];
    FooterNote: boolean = false;
    filteredlist: StudyData | undefined;
    isExistingDataPresent: boolean=false;
    dataexists: any;
    newdataexists: any;
    listCItemsList: {
      Level0: string;
      Level1: string;
      tooltip: string;
      has_nextLevel: boolean;
      sortId: number;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      is_dimension: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
      is_entrypoint:boolean
    }[] = [];
    listEItemsList: {
      Level0: string;
      Level1: string;
      tooltip: string;
      has_nextLevel: boolean;
      sortId: number;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      is_dimension: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
      is_entrypoint:boolean
    }[] = [];
    listAItemsList: {
      Level0: string;
      Level1: string;
      tooltip: string;
      has_nextLevel: boolean;
      sortId: number;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      is_dimension: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
      is_entrypoint:boolean
    }[] = [];
    entryList: any[] = [];
    public previewDataObject: DataObject = {
      Row: [],
      Column: [],
      RowNesting: [],
      ColNesting: [],
      Filter: [],
      CustomGroup: [],
      CustomCalculation: [],
    };
    public FinalOutputdataObject: OutputDataObject = {
      Row: [],
      Column: [],
    };
    WeightDropDownList: {
      Label: string;
      responseCode: string;
      tooltip: string;
    }[] = [];
    is_dimension_Present: boolean = false;
    is_indexingShow:boolean=false;
    isRespondentLevel: boolean = true;
    strdata: any;
    arktabaccess: boolean=false;
    
    constructor(private _loaderService: LoaderService, private _exportDownloads: ExportDownloadServices, private apiService: ChatbotService, private crossTabService: CrosstabService, private _insightsSerive: InsightspopupService) { }
    CrossTabOutPutData: any;
    CrossTabOutPutEntireData: any;
    ngOnInit(): void {
      this.selected_study = "Brand Insights"
      this.selected_client = "Fidelity"
      
      this.displayTable = false;
      //  this.arktabaccess=true;
      this.arktabaccess=true;
      this.$unSubscribeSubmitData1 = this.crossTabService
      .getClientStudyData({
        client_code: this.selected_client,
        study: this.selected_study
      }, this.isBrandAnalysis)
      .subscribe((result: results) => {
        // debugger
        if (result.HasValidData) {
          const studyData: StudyData = {
            study: this.selected_study ,
            client_name: this.selected_client
        };
          this.receivedData = JSON.parse(result.Data);
          this.clientcode = this.selected_client;
          this.filteredlist = this.receivedData.find((u: StudyData) => 
            u.study === this.selected_study && u.client_name === this.selected_client
        );
          this.getVariableData(this.filteredlist);
        } 
      });
    
    }
    ngOnDestroy() {
      if (this.$unSubscribeSubmitData1) {
        this.$unSubscribeSubmitData1.unsubscribe();
      }
      if (this.$unSubscribeSubmitData2) {
        this.$unSubscribeSubmitData2.unsubscribe();
      }
      if (this.$unSubscribeSubmitData3) {
        this.$unSubscribeSubmitData3.unsubscribe();
      }
      if (this.$unSubscribeSubmitData4) {
        this.$unSubscribeSubmitData4.unsubscribe();
      }
      if (this.$unSubscribeSubmitData5) {
        this.$unSubscribeSubmitData5.unsubscribe();
      }
      if (this.$unSubscribeSubmitData6) {
        this.$unSubscribeSubmitData6.unsubscribe();
      }
      // if (this.$unSubscribeSubmitData7) {
      //   this.$unSubscribeSubmitData7.unsubscribe();
      // }
      if (this.$unSubscribeSubmitData8) {
        this.$unSubscribeSubmitData8.unsubscribe();
      }
      if (this.$unSubscribeSubmitData9) {
        this.$unSubscribeSubmitData9.unsubscribe();
      }
    }
    getVariableData(receivedData: any): void {
      // debugger
      //  this.arktabaccess=true;
      const getdata: GetDataArray = {
        EntryPoint1: {
        Level1: receivedData.Level1,
        Level2: [
          {
            Parent: receivedData.Level2,
            MetricOptions: [
              {
                Name: receivedData.MetricOption,
                responseCode: receivedData.ResponseCode,
                isActive: true,
                Dependency_e1: receivedData.e1_variable_code,
                Dependency_e2: receivedData.e2_variable_code
              }
            ]
          }
        ],
        variableCode: receivedData.VariableCode
      },
      clientName: receivedData.client_name,
      studyName: receivedData.study,
      mandatory_variable_mapping: receivedData.mandatory_variable_mapping,
      dimension: receivedData.dimension
    };
    this.dataArray.push(getdata);
    
      
      this.$unSubscribeSubmitData3 = this.crossTabService
        .getVariableData(this.dataArray, this.isBrandAnalysis)
        .subscribe((result: results) => {
          // Response is valid
          this.arktabaccess=false;
          if (result.HasValidData) {

            let JsonData = JSON.parse(result.Data);
            this.fomDataArray= JSON.parse(result.Data);
    
            if (this.isBrandAnalysis) {
              JsonData.forEach((x: any) => {
                const is_multi_punch = x.is_multi_punch > 0 ? true : false;
                if (is_multi_punch) {
                    x.MetricOption = '';
                }
                  //   x.Level2 = '';
                switch (x.is_brandanalysis) {
                  case 1:

                    x.Level2 = x.Level3;
                    x.Level3 = x.Level4;
                    x.Level4 = x.Level5;
                    // if (is_multi_punch && x.Level3 == '') {
                    //   x.MetricOption = '';
                    //   x.Level2 = '';
                    // } else if (is_multi_punch && x.Level3 !== '') {
                    //   x.Level2 = x.Level3;
                    //   x.Level3 = '';
                    //   // x.MetricOption = x.Level3;
                    //   // x.Level2 = '';
                    // } else if (is_multi_punch && x.Level4 !== '') {
                    //   x.MetricOption = x.Level4;
                    //   x.Level2 = x.Level3;
                    // } else if (is_multi_punch && x.Level5 !== '') {
                    //   x.MetricOption = x.Level5;
                    //   x.Level3 = x.Level4;
                    //   x.Level2 = x.Level3;
                    // } else if (is_multi_punch && x.Level6 !== '') {
                    //   x.MetricOption = x.Level6;
                    //   x.Level4 = x.Level5;
                    //   x.Level3 = x.Level4;
                    //   x.Level2 = x.Level3;
                    // } else {
                    //   x.Level2 = x.Level3;
                    //   x.Level3 = x.Level4;
                    //   x.Level4 = x.Level5;
                    // }
                    break;
                  case 2:
                    x.Level3 = x.Level4;
                    x.Level4 = x.Level5;
                    // if (is_multi_punch && x.Level4 == '') {
                    //   x.MetricOption = '';
                    //   x.Level3 = '';
                    // } else if (is_multi_punch && x.Level4 !== '') {
                    //   x.MetricOption = x.Level4;
                    //   x.Level3 = '';
                    // } else if (is_multi_punch && x.Level5 !== '') {
                    //   x.MetricOption = x.Level5;
                    //   x.Level3 = x.Level4;
                    // } else if (is_multi_punch && x.Level6 !== '') {
                    //   x.MetricOption = x.Level6;
                    //   x.Level4 = x.Level5;
                    //   x.Level3 = x.Level4;
                    // } else {
                    //   x.Level3 = x.Level4;
                    //   x.Level4 = x.Level5;
                      
                    // }
                    break;
                  case 3:
                    x.Level4 = x.Level5;
                    // if (is_multi_punch && x.Level5 == '') {
                    //   x.MetricOption = '';
                    //   x.Level4 = '';
                    // } else if (is_multi_punch && x.Level5 !== '') {
                    //   x.MetricOption = x.Level5;
                    //   x.Level4 = '';
                    // } else if (is_multi_punch && x.Level6 !== '') {
                    //   x.MetricOption = x.Level6;
                    //   x.Level4 = x.Level5;
                    // } else {
                    //   x.Level4 = x.Level5;
                    // }
                    break;
                  case 4:
                    x.Level5 = '';
                    // if (is_multi_punch && x.Level6 == '') {
                    //   x.MetricOption = '';
                    //   x.Level6 = '';
                    // } else if (is_multi_punch && x.Level6 !== '') {
                    //   x.MetricOption = x.Level6;
                    //   x.Level6 = '';
                    // } else {
                    //   x.Level5 = '';
                    // }
                    break;
                  case 5:
                    x.Level6 = '';
                    break;
                  case 6:
                    x.MetricOption = '';
                    break;
                }
              });
            }
          //   console.log(JsonData);

          //   this.variableTabData = JsonData;
          //    this.arktabaccess=true;
          //   if (this.variableTabData.length > 0) {
          //     this.isAggregatedData =
          //       this.variableTabData[0].is_aggregated_data == 1 ? true : false;
          //     this.isSemiAggregatedData =
          //       this.variableTabData[0].is_semi_aggregated_data == 1
          //         ? true
          //         : false;
          //     //this.BindVariableDataLeftPanel(this.variableTabData);
          //     this.crossTabService.setVariableTabData(this.variableTabData);
          //   }
          // } else {
          //   // this.errorPopup(result.ExceptionMessage);
          //    this.arktabaccess=false;
          // }
          //hide the loader
          this.arktabaccess=false;
          }
        });
    }
    public OutputdataObject: OutputDataObject = {
      Row: [],
      Column: [],
    };
    public fomData: fomData = {
        Header: "",
      Level1: "",
      Level2: "",
      MetricOption: "",
      data_variable_code: "",
      response_code: "",
      derived_metric_calculation: "",
      Level0: "",
      Level3: "",
      Level4: "",
      Level5: "",
      Level6: "" ,
      sort_id: 0,
      level1_sort_id: 0,
      is_multi_punch: 0,
      is_derived_variable: false,
      is_dimension: false,
      calculation_type: "",
      url_logo: "" ,
      tooltip: "",
      year_code: "" ,
      year_label: "",
      date: "",
      isActive: false,
      is_CustomGroup: false,
      customGroupQuery: "",
      is_CustomCalculation: false,
      customCalculationQuery: "",
      dataYearCode: "",
      is_time_Period_Present: false,
      is_dimension_Present: false,
      is_shared: false,
      user_name: "" ,
      customGroupId: "" ,
      customCalculationId: "",
      is_brandanalysis: 0,
      variable_code: "",
      is_aggregated_data: 0,
      is_semi_aggregated_data: 0,
      is_snapshot: 0,
      sequence: 0
    };
    public LineChart: LineChart = {
      title: '',
      Data: [],
      Properties: [],
    };
    public StackChart: StackChart = {
      title: '',
      Data: [],
      Properties: [],
    };
    public BarChart: GroupBarChart = {
      title: '',
      Data: [],
      Properties: [],
    };

    public dataObject: DataObject = {
      Row: [],
      Column: [],
      RowNesting: [],
      ColNesting: [],
      Filter: [],
      CustomGroup: [],
      CustomCalculation: [],
    };
    isSelected(SelectedValue: string): boolean {
      return this.selectedIndex === SelectedValue;
    }
    isSelectedSignificance(SelectedValue: string): boolean {
      return this.SignificanceVsReference === SelectedValue;
    }
    BindTableOutput(CrossTabOuputData: any) {
      // Iterate through CrossTabOutPutData and structure the data

      this.CrossTabOutPutData.forEach((item: CrossTabOutputItem) => {
        // Create OutputSelection objects for row and column

        const nestLevel2Selection: OutputSelection = {
          Child: item.Level2ColChild,
          variablecode: item.Level2NestColVariableCode,
          responsecode: item.Level2ColResponseCode,
          NestedArray: [],
          isDerived: item.isDerived,
          customGroupId: item.customGroupId,
          isCustomGroup: item.isCustomGroup,
          CalculationType: item.CalculationType,
          isCustomCalculation: false
        };

        const nestRowLevel2Selection: OutputSelection = {
          Child: item.Level2RowChild,
          variablecode: item.Level2NestRowVariableCode,
          responsecode: item.Level2RowResponseCode,
          NestedArray: [],
          isDerived: item.isDerived,
          customGroupId: item.customGroupId,
          isCustomGroup: item.isCustomGroup,
          CalculationType: item.CalculationType,
          isCustomCalculation: false
        };

        const NestedRowSelection: OutputSelection = {
          Child: item.Level1RowChild,
          variablecode: item.Level1NestRowVariableCode,
          responsecode: item.Level1RowResponseCode,
          NestedArray: item.Level2RowChild ? [nestRowLevel2Selection] : [],
          isDerived: item.isDerived,
          customGroupId: item.customGroupId,
          isCustomGroup: item.isCustomGroup,
          CalculationType: item.CalculationType,
          isCustomCalculation: false
        };

        const NestedColumnSelection: OutputSelection = {
          Child: item.Level1ColChild,
          variablecode: item.Level1NestColVariableCode,
          responsecode: item.Level1ColResponseCode,
          NestedArray: item.Level2ColChild ? [nestLevel2Selection] : [],
          isDerived: item.isDerived,
          customGroupId: item.customGroupId,
          isCustomGroup: item.isCustomGroup,
          CalculationType: item.CalculationType,
          isCustomCalculation: false
        };

        const rowSelection: OutputSelection = {
          Child: item.rowChild,
          variablecode: item.rowVariableCode,
          responsecode: item.rowResponseCode,
          NestedArray: item.Level1RowChild ? [NestedRowSelection] : [],
          isDerived: item.isDerived,
          customGroupId: item.customGroupId,
          isCustomGroup: item.isCustomGroup,
          CalculationType: item.CalculationType,
          isCustomCalculation: false
        };

        const colSelection: OutputSelection = {
          Child: item.colChild,
          variablecode: item.colVariableCode,
          responsecode: item.colResponseCode,
          NestedArray: item.Level1ColChild ? [NestedColumnSelection] : [],
          isDerived: item.isDerived,
          customGroupId: item.customGroupId,
          isCustomGroup: item.isCustomGroup,
          CalculationType: item.CalculationType,
          isCustomCalculation: false
        };

        // Create OutPutSelectionWithParent objects
        const rowWithParent: OutPutSelectionWithParent = {
          Parent: ((item.rowChild.indexOf('Custom Group-User Defined') > -1 || item.rowChild.indexOf('Custom Group - Shared With Me') > -1) ? item.rowChild.split('|')[1] : item.rowChild.split('|')[0]),
          Data: [rowSelection],
          isCustomGroup: item.isCustomGroup,
          customGroupId: item.customGroupId,
          isCustomCalculation: false
        };

        const colWithParent: OutPutSelectionWithParent = {
          Parent: ((item.colChild.indexOf('Custom Group-User Defined') > -1 || item.colChild.indexOf('Custom Group - Shared With Me') > -1) ? item.colChild.split('|')[1] : item.colChild.split('|')[0]),
          Data: [colSelection],
          isCustomGroup: item.isCustomGroup,
          customGroupId: item.customGroupId,
          isCustomCalculation: false
        };

        // Check if the same parent and data combination already exists in the Row array
        const existingRowWithParent = this.OutputdataObject.Row.find(existingItem => {
          if (rowWithParent.isCustomGroup && rowWithParent.Parent == ('Custom Group-User Defined' || 'Custom Group - Shared With Me')) {
            return existingItem.Parent === rowWithParent.Parent && existingItem.customGroupId === rowWithParent.customGroupId;
          } else {
            return existingItem.Parent === rowWithParent.Parent;
          }
        });

        // If it doesn't exist, push the new object
        if (!existingRowWithParent) {
          this.OutputdataObject.Row.push(rowWithParent);
        } else {
          // If it exists, append the new data to the existing object

          const existingRowWithChild = existingRowWithParent.Data.find(
            (existingItem) => {
              if (
                existingItem.Child === rowSelection.Child &&
                existingItem.variablecode === rowSelection.variablecode
              ) {
                if (existingItem.isDerived && existingItem.CalculationType.toLowerCase() == "mean") {
                  // When isDerviedVariable is true, don't consider existingItem.responsecode === rowSelection.responsecode
                  return true;
                } else {
                  // When isDerviedVariable is false, consider existingItem.responsecode === rowSelection.responsecode
                  return existingItem.responsecode === rowSelection.responsecode;
                }
              }
              return false;
            }
          );

          if (!existingRowWithChild) {
            existingRowWithParent.Data.push(rowSelection);
          }

          if (existingRowWithChild) {
            const existsInNestedArray = existingRowWithChild?.NestedArray?.some(
              (existingNestedItem) =>
                rowSelection?.NestedArray?.some(
                  (nestedArrayItem) =>
                    nestedArrayItem.Child === existingNestedItem.Child
                )
            );
            if (!existsInNestedArray && NestedRowSelection.Child != null) {
              existingRowWithChild.NestedArray.push(NestedRowSelection);
            }

            const existsInNestLeveledArray =
              existingRowWithChild?.NestedArray?.some(
                (existingNestedItem) =>
                  existingNestedItem.NestedArray?.length === 0 ||
                  existingNestedItem.NestedArray?.some(
                    (existingChildItem) =>
                      rowSelection?.NestedArray?.length === 0 ||
                      rowSelection?.NestedArray?.some(
                        (nestedArrayItem) =>
                          nestedArrayItem.NestedArray?.length === 0 ||
                          nestedArrayItem.NestedArray?.some(
                            (childItem) =>
                              childItem.Child === existingChildItem.Child &&
                              existingNestedItem.Child == nestedArrayItem.Child
                          )
                      )
                  )
              );
            if (
              !existsInNestLeveledArray &&
              nestRowLevel2Selection.Child != null
            ) {
              if (existingRowWithChild.NestedArray.length > 0) {
                existingRowWithChild.NestedArray.forEach(
                  (existingNestedItem: any, index: number) => {
                    rowSelection.NestedArray.forEach((colItem: any) => {
                      if (existingNestedItem.Child === colItem.Child) {
                        existingRowWithChild.NestedArray[index].NestedArray.push(
                          nestRowLevel2Selection
                        );
                      }
                    });
                  }
                );
              }
            }
          }
        }

        // Perform a similar check for the Column array
        //const existingColWithParent = this.OutputdataObject.Column.find(
        //  (existingItem) => existingItem.Parent === colWithParent.Parent
        //);

        const existingColWithParent = this.OutputdataObject.Column.find(
          (existingItem) => {
            if (
              colWithParent.isCustomGroup &&
              colWithParent.Parent == ('Custom Group-User Defined' || 'Custom Group - Shared With Me')
            ) {
              return (
                existingItem.Parent === colWithParent.Parent &&
                existingItem.customGroupId === colWithParent.customGroupId
              );
            } else {
              return existingItem.Parent === colWithParent.Parent;
            }
          }
        );

        if (!existingColWithParent) {
          this.OutputdataObject.Column.push(colWithParent);
        } else {
          const existingColumnWithChild = existingColWithParent.Data.find(
            (existingItem) =>
              existingItem.Child === colSelection.Child &&
              existingItem.responsecode === colSelection.responsecode &&
              existingItem.variablecode === colSelection.variablecode
          );

          if (!existingColumnWithChild) {
            existingColWithParent.Data.push(colSelection);
          }
          // Check if the same NestedArray of Child already exists
          if (existingColumnWithChild) {
            const existsInNestedArray =
              existingColumnWithChild?.NestedArray?.some((existingNestedItem) =>
                colSelection?.NestedArray?.some(
                  (nestedArrayItem) =>
                    nestedArrayItem.Child === existingNestedItem.Child
                )
              );
            if (!existsInNestedArray && NestedColumnSelection.Child != null) {
              existingColumnWithChild.NestedArray.push(NestedColumnSelection);
            }
            const existsInNestLeveledArray =
              existingColumnWithChild?.NestedArray?.some(
                (existingNestedItem) =>
                  existingNestedItem.NestedArray?.length === 0 ||
                  existingNestedItem.NestedArray?.some(
                    (existingChildItem) =>
                      colSelection?.NestedArray?.length === 0 ||
                      colSelection?.NestedArray?.some(
                        (nestedArrayItem) =>
                          nestedArrayItem.NestedArray?.length === 0 ||
                          nestedArrayItem.NestedArray?.some(
                            (childItem) =>
                              childItem.Child === existingChildItem.Child &&
                              existingNestedItem.Child == nestedArrayItem.Child
                          )
                      )
                  )
              );
            if (!existsInNestLeveledArray && nestLevel2Selection.Child != null) {
              if (existingColumnWithChild.NestedArray.length > 0) {
                existingColumnWithChild.NestedArray.forEach(
                  (existingNestedItem: any, index: number) => {
                    colSelection.NestedArray.forEach((colItem: any) => {
                      if (existingNestedItem.Child === colItem.Child) {
                        existingColumnWithChild.NestedArray[
                          index
                        ].NestedArray.push(nestLevel2Selection);
                      }
                    });
                  }
                );
              }
            }
          }
        }
      });
      console.log(this.OutputdataObject);
    }
    replacePipe(input: string): string {
      return input.replace(/\|/g, ' : ');
    }
    onTableIconClick() {
      this.isTableClick = true;
      this.displayTable = true;
      this.isLineChartClick = false;
      this.createTable(false, false);
      this.is_Time_Period_Present=true;
    }
    sortOutputData() {
      this.OutputdataObject.Row.sort((a, b) => {
        const indexA = this.dataObject.Row.findIndex(
          (item) => item.Parent === a.Parent
        );
        const indexB = this.dataObject.Row.findIndex(
          (item) => item.Parent === b.Parent
        );
        return indexA - indexB;
      });

      this.OutputdataObject.Column.sort((a, b) => {
        const indexA = this.dataObject.Column.findIndex(
          (item) => item.Parent === a.Parent
        );
        const indexB = this.dataObject.Column.findIndex(
          (item) => item.Parent === b.Parent
        );
        return indexA - indexB;
      });

      this.OutputdataObject.Row.forEach((row) => {
        const parentData = this.dataObject.Row.find(
          (item) => item.Parent === row.Parent
        );
        if (parentData) {
          row.Data.sort((a, b) => {
            const indexA = parentData.Data.findIndex(
              (data) => data.Child === a.Child
            );
            const indexB = parentData.Data.findIndex(
              (data) => data.Child === b.Child
            );
            return indexA - indexB;
          });
        }
      });

      this.OutputdataObject.Row.forEach((row) => {
        row.Data.forEach((outputSelection) => {
          this.dataObject.RowNesting.forEach((rowNest) => {
            outputSelection.NestedArray.sort((a, b) => {
              const indexA = rowNest.Data.findIndex(
                (data) => data.Child === a.Child
              );
              const indexB = rowNest.Data.findIndex(
                (data) => data.Child === b.Child
              );
              return indexA - indexB;
            });
          });
        });
      });

      this.OutputdataObject.Row.forEach((row) => {
        row.Data.forEach((outputSelection) => {
          outputSelection.NestedArray.forEach((rowNest1) => {
            this.dataObject.RowNesting.forEach((rowNest) => {
              rowNest1.NestedArray.sort((a, b) => {
                const indexA = rowNest.Data.findIndex(
                  (data) => data.Child === a.Child
                );
                const indexB = rowNest.Data.findIndex(
                  (data) => data.Child === b.Child
                );
                return indexA - indexB;
              });
            });
          });
        });
      });

      this.OutputdataObject.Column.forEach((column) => {
        const parentData = this.dataObject.Column.find(
          (item) => item.Parent === column.Parent
        );
        if (parentData) {
          column.Data.sort((a, b) => {
            const indexA = parentData.Data.findIndex(
              (data) => data.Child === a.Child
            );
            const indexB = parentData.Data.findIndex(
              (data) => data.Child === b.Child
            );
            return indexA - indexB;
          });
        }
      });

      this.OutputdataObject.Column.forEach((column) => {
        column.Data.forEach((outputSelection) => {
          this.dataObject.ColNesting.forEach((colNest) => {
            outputSelection.NestedArray.sort((a, b) => {
              const indexA = colNest.Data.findIndex(
                (data) => data.Child === a.Child
              );
              const indexB = colNest.Data.findIndex(
                (data) => data.Child === b.Child
              );
              return indexA - indexB;
            });
          });
        });
      });

      this.OutputdataObject.Column.forEach((column) => {
        column.Data.forEach((outputSelection) => {
          outputSelection.NestedArray.forEach((colNest1) => {
            this.dataObject.ColNesting.forEach((colNest) => {
              colNest1.NestedArray.sort((a, b) => {
                const indexA = colNest.Data.findIndex(
                  (data) => data.Child === a.Child
                );
                const indexB = colNest.Data.findIndex(
                  (data) => data.Child === b.Child
                );
                return indexA - indexB;
              });
            });
          });
        });
      });
      //If object modified then update the object
      this.OutputdataObject = JSON.parse(JSON.stringify(this.OutputdataObject));
    }
    @HostListener('document:click', ['$event'])
    
    onDocumentClick(event: Event): void {
      // debugger
      //Check if the click event occurred outside the <div> block.
      //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
      //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
      if (this.sidePanelDisplay == true) {
        if (!this.isClickInside(event, '.RowPanel')) {
          this.sidePanelDisplay = false;
        }
      }
      if (this.sidePanelNestingDisplay == true) {
        if (!this.isClickInside(event, '.RowNestPanel')) {
          this.sidePanelNestingDisplay = false;
        }
      }
      if (this.columnPanelDisplay == true) {
        if (!this.isClickInside(event, '.ColPanel')) {
          this.columnPanelDisplay = false;
        }
      }
      if (this.columnNestingPanelDispaly == true) {
        if (!this.isClickInside(event, '.ColNestPanel')) {
          this.columnNestingPanelDispaly = false;
        }
      }
      if (this.filterPanelDisplay == true) {
        if (!this.isClickInside(event, '.FilterPanel')) {
          this.filterPanelDisplay = false;
        }
      }
      if (this.isSelectionSummary == true) {
        if (!this.isClickInside(event, '.summaryPanel')) {
          this.isSelectionSummary = false;
        }
      }
      if (this.sampleSizeDropdown == true) {
        if (!this.isClickInside(event, '.significance')) {
          this.sampleSizeDropdown = false;
          this.isReference = false;
        }
      }
      if (this.dataDropDown == true) {
        if (!this.isClickInside(event, '.dataDropdown')) {
          this.dataDropDown = false;
        }
      }
      if (this.confidenceLevelDropDown == true) {
        if (!this.isClickInside(event, '.confidance')) {
          this.confidenceLevelDropDown = false;
        }
      }
      if (this.referenceLevelDropDown) {
        if (!this.isClickInside(event, '.reference')) {
          this.referenceLevelDropDown = false;
        }
      }
      if (this.weightDropDown) {
        if (!this.isClickInside(event, '.weight')) {
          this.weightDropDown = false;
        }
      }
      if (this.showCustomMetricsPopup) {
        if (!this.isClickInside(event, '.customMetric')) {
          this.showCustomMetricsPopup = false;
        }
      }
      //if (this.handleLeftPanel != "" && this.entryFlag == true) {
      //  if (!this.isClickInside(event, ".leftPanelLvl0")) {
      //    this.handleLeftPanel = "";
      //  }
      //}
    }
    private downloadFile(data: Blob, filename: string) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      // Trigger download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }

    private downloadPPTFile(data: Blob, filename: string) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // MIME type for PPTX
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      // Trigger download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
    getCurrentDateTime() {
      const now = new Date();

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
      return formattedDateTime;
    }
    private isClickInside(event: Event, className: string): boolean {
      const elements = document.querySelectorAll(className);
      for (let i = 0; i < elements.length; i++) {
        const container = elements[i];
        if (container.contains(event.target as Node)) {
          return true;
        }
      }
      return false;
    }
    OnTotalClick() {
      this.isTotal = !this.isTotal;
      if (this.isTotal) this.CrossTabOutPutData = this.CrossTabOutPutEntireData;
      else
        this.CrossTabOutPutData = this.CrossTabOutPutEntireData.filter(
          (x: any) => {
            return !x.isTotal;
          }
        );

      this.OutputdataObject = {
        Row: [],
        Column: [],
      };
      if(this.isIndex && this.selectedIndex!=''){
        this.manipulateIndexData();
      }
      this.BindTableOutput(this.CrossTabOutPutData);
      this.sortOutputData();
      this.crossTabService.setCrossTabOuputData(this.CrossTabOutPutData);
    }
    toggleDataBlock(block: string): void {
      this.isTableClick = false;
      this.isLineChartClick = false;
      this.displayTable = true;
      this.isVerticalBarChartClick = false;
      this.isVerticalStackChartClick = false;
      this.isVerticalGroupBarChartClick = false;
      if (!this.isVerticalGroupBarChartClick) {
        this.disableStackChart = true;
      }

      const rowdata = this.dataObject['Row'];
      const columndata = this.dataObject['Column'];
      const TimePeriodinColumn = columndata.filter((parent) => {
        return parent.Data.some((child) => child.isTimePeriod === true);
      });
      const rowDataLength: number =
        rowdata.length > 0 ? rowdata[0].Data.length : 0;
      this.isChartView =
        this.listBItemsList.length > 0 ||
          this.listDItemsList.length > 0 ||
          rowdata.length > 1 ||
          rowDataLength > 15
          ? false
          : true;
      if (this.isChartView) {
        const isMultiPunchRow = rowdata.filter((parent) => {
          return parent.Data.some(
            (child) => child.isMultiPunch === Status.MultiPunchDisableChart
          );
        });
        const isMultiPunchColumn = columndata.filter((parent) => {
          return parent.Data.some(
            (child) => child.isMultiPunch === Status.MultiPunchDisableChart
          );
        });

        const isDefaultRespGrp = rowdata.filter((parent) => {
          return parent.Data.some(
            (child) =>
              child.CalculationType === 'RespGrp' &&
              child.isDerviedVariable == true
          );
        });

        const isMeanMetric = rowdata.filter((parent) => {
          return parent.Data.some(
            (child) =>
              child.CalculationType.toLowerCase() === 'mean' &&
              child.isDerviedVariable == true
          );
        });

        if (this.isChartView) {
          this.disableLineChart = TimePeriodinColumn.length == 1 ? true : false;

          if (rowdata.length == 1) {
            let filteredData: any = [];
            const disctinctRowValues = this.getDistinctChildValues(
              this.dataObject.Row
            );

            let distinctFirstParts = this.getDistinctValuesByPipeCount(
              disctinctRowValues,
              0
            );
            let distinctSecondParts = this.getDistinctValuesByPipeCount(
              disctinctRowValues,
              1
            );
            let distinctThirdParts = this.getDistinctValuesByPipeCount(
              disctinctRowValues,
              2
            );
            let distinctFourthParts = this.getDistinctValuesByPipeCount(
              disctinctRowValues,
              3
            );
            let distinctFifthParts = this.getDistinctValuesByPipeCount(
              disctinctRowValues,
              4
            );

            if (
              distinctFifthParts.length > 0 &&
              distinctFourthParts.length == 1 &&
              distinctThirdParts.length == 1 &&
              distinctSecondParts.length == 1 &&
              distinctFirstParts.length == 1
            ) {
              this.StackFlag = true;
            } else if (
              distinctFourthParts.length > 0 &&
              distinctThirdParts.length == 1 &&
              distinctSecondParts.length == 1 &&
              distinctFirstParts.length == 1
            ) {
              this.StackFlag = true;
            } else if (
              distinctFourthParts.length > 0 &&
              distinctThirdParts.length > 0 &&
              distinctSecondParts.length == 1 &&
              distinctFirstParts.length == 1
            ) {
              this.StackFlag = false;
            } else if (
              distinctThirdParts.length > 0 &&
              distinctSecondParts.length == 1 &&
              distinctFirstParts.length == 1
            ) {
              this.StackFlag = true;
            } else if (
              distinctThirdParts.length > 0 &&
              distinctSecondParts.length > 0 &&
              distinctFirstParts.length == 1
            ) {
              this.StackFlag = false;
            } else if (
              distinctSecondParts.length > 0 &&
              distinctFirstParts.length == 1
            ) {
              this.StackFlag = true;
            }

            if (this.StackFlag) {
              rowdata[0].Data.forEach((item) => {
                const pipeCount = (item.Child.match(/\|/g) || []).length;
                if (pipeCount === 1) {
                  const [level1] = item.Child.split('|');
                  filteredData = this.variableTabData.filter(
                    (x: any) => x.Level1 === level1 && x.Header == 'VariableList'
                  );
                } else if (pipeCount === 2) {
                  const [level1, level2] = item.Child.split('|');
                  filteredData = this.variableTabData.filter(
                    (x: any) =>
                      x.Level1 === level1 &&
                      x.Level2 === level2 &&
                      x.Header == 'VariableList'
                  );
                } else if (pipeCount === 3) {
                  const [level1, level2, level3] = item.Child.split('|');
                  filteredData = this.variableTabData.filter(
                    (x: any) =>
                      x.Level1 === level1 &&
                      x.Level2 === level2 &&
                      x.level3 === level3 &&
                      x.Header == 'VariableList'
                  );
                } else if (pipeCount === 4) {
                  const [level1, level2, level3, level4] = item.Child.split('|');
                  filteredData = this.variableTabData.filter(
                    (x: any) =>
                      x.Level1 === level1 &&
                      x.Level2 === level2 &&
                      x.level3 === level3 &&
                      x.level4 == level4 &&
                      x.Header == 'VariableList'
                  );
                } else if (pipeCount === 5) {
                  const [level1, level2, level3, level4, level5] =
                    item.Child.split('|');
                  filteredData = this.variableTabData.filter(
                    (x: any) =>
                      x.Level1 === level1 &&
                      x.Level2 === level2 &&
                      x.level3 === level3 &&
                      x.level4 == level4 &&
                      x.level5 == level5 &&
                      x.Header == 'VariableList'
                  );
                }
              });
            } else {
              this.StackFlag = false;
            }
            this.disableStackChart =
              isMultiPunchRow.length > 0 ||
                isMultiPunchColumn.length > 0 ||
                isDefaultRespGrp.length > 0 ||
                isMeanMetric.length > 0
                ? false
                : rowdata[0].Data.length == filteredData.length
                  ? true
                  : false;
          }
        }
      }
      this.DefaultDataDropDown = block;
      if (block === 'Data') {
        this.isData = true;
        this.isSampleSize = false;
        this.isWeightedLabel = false;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
        this.disableLineChart = TimePeriodinColumn.length == 1 ? true : false;
      } else if (block === 'Sample Size') {
        this.isData = false;
        this.isSampleSize = true;
        this.isWeightedLabel = false;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
      } else if (block === 'Weighted Sample') {
        this.isData = false;
        this.isSampleSize = false;
        this.isWeightedLabel = true;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
      } else if (block == 'Weighted Numerator') {
        this.isData = false;
        this.isSampleSize = false;
        this.isWeightedLabel = false;
        this.isWeightedNumerator = true;
        this.isUnWeightedNumerator = false;
      } else if (block == 'Unweighted Numerator') {
        this.isData = false;
        this.isSampleSize = false;
        this.isWeightedLabel = false;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = true;
      }

      if (this.isIndex) {
        this.isChartView = false;
      }
    }

    toggleSigBlockbkp(block: string): void {
      if (block === 'Sig') {
        // debugger
        this.isSig = true;
        this.isIndex = false;
        this.isChartView = true;
        this.isSampleSize = false;
        this.isWeightedLabel = false;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
        this.isCreateBtnClick = true;
        // this.sendInputMessage();
        // this.createTable(false, Feature.CrosstabOutput, false);
      } else if (block === 'Index') {
        this.displayTable = true;
        this.isData = true;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
        this.DefaultDataDropDown = 'Data';
        this.isVerticalGroupBarChartClick = false;
        this.isVerticalBarChartClick = false;
        this.isVerticalStackChartClick = false;
        this.isLineChartClick = false;
        this.isSig = false;
        this.isIndex = true;
        this.isChartView = false;
        this.DefaultReferenceLevel = 'None';
        this.DefaultSampleSize = 'None';
        this.DefaultConfidenceLevel = 'None';
        this.SignificanceVsReference = '';
        this.isReference = false;
        this.selectedIndex = '';
        this.isPairWise = false;
        if (this.isTranspose) {
          this.isTranspose = false;
          this.reverseTranspose();
        }
        this.IndexData = this.getLastLevelColumnNestingValues();
        //this.SelectionSummary();
        // if (this.OutputdataObject.Column.length > 0) {
        //   if (this.OutputdataObject['Column'][0]['Data'].length > 0) {
        //     let firstLevelLength =
        //       this.OutputdataObject['Column'][0]['Data'].length;

        //     if (firstLevelLength > 0) {
        //       let level0 =
        //         this.OutputdataObject['Column'][0]['Data'][firstLevelLength - 1];
        //       if (level0.NestedArray.length > 0) {
        //         let secondLevelLength = level0.NestedArray.length;
        //         if (secondLevelLength > 0) {
        //           let level1 = level0.NestedArray[secondLevelLength - 1];

        //           if (level1.NestedArray.length > 0) {
        //             let finalData =
        //               this.OutputdataObject['Column'][0]['Data'][
        //                 firstLevelLength - 1
        //               ].NestedArray[secondLevelLength - 1].NestedArray;
        //             this.IndexData = finalData;
        //           } else {
        //             this.IndexData = level0.NestedArray;
        //           }
        //         }
        //       } else {
        //         this.IndexData = this.OutputdataObject['Column'][0]['Data'];
        //       }
        //     }
        //   }
        // }
      }
    }
    toggleSigBlock(block: string): void {
      if (block === 'Sig') {
        this.isSig = true;
        this.isIndex = false;
        this.isChartView = true;
        this.isSampleSize = false;
        this.isWeightedLabel = false;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
        this.createTable(false, false);
        // this.sampleSizeDropdown=true;
        this.is_Time_Period_Present=true;
      } else if (block === 'Index') {
        this.displayTable = true;
        this.isData = true;
        this.isWeightedNumerator = false;
        this.isUnWeightedNumerator = false;
        this.DefaultDataDropDown = 'Data';
        this.isVerticalGroupBarChartClick = false;
        this.isVerticalBarChartClick = false;
        this.isVerticalStackChartClick = false;
        this.isLineChartClick = false;
        this.isSig = false;
        this.isIndex = true;
        this.isChartView = false;
        this.DefaultReferenceLevel = 'None';
        this.DefaultSampleSize = 'None';
        this.DefaultConfidenceLevel = 'None';
        this.SignificanceVsReference = '';
        this.isReference = false;
        this.selectedIndex = '';
        this.isPairWise = false;
        if (this.isTranspose) {
          this.isTranspose = false;
          this.reverseTranspose();
        }
        this.IndexData = this.getLastLevelColumnNestingValues();
        // this.SelectionSummary();
        // if (this.OutputdataObject.Column.length > 0) {
        //   if (this.OutputdataObject['Column'][0]['Data'].length > 0) {
        //     let firstLevelLength =
        //       this.OutputdataObject['Column'][0]['Data'].length;

        //     if (firstLevelLength > 0) {
        //       let level0 =
        //         this.OutputdataObject['Column'][0]['Data'][firstLevelLength - 1];
        //       if (level0.NestedArray.length > 0) {
        //         let secondLevelLength = level0.NestedArray.length;
        //         if (secondLevelLength > 0) {
        //           let level1 = level0.NestedArray[secondLevelLength - 1];

        //           if (level1.NestedArray.length > 0) {
        //             let finalData =
        //               this.OutputdataObject['Column'][0]['Data'][
        //                 firstLevelLength - 1
        //               ].NestedArray[secondLevelLength - 1].NestedArray;
        //             this.IndexData = finalData;
        //           } else {
        //             this.IndexData = level0.NestedArray;
        //           }
        //         }
        //       } else {
        //         this.IndexData = this.OutputdataObject['Column'][0]['Data'];
        //       }
        //     }
        //   }
        // }
      }
    }
    createTable(
      isSampleSize: boolean,
      isIndexing: boolean
    ) {
      // this.hidePanel = !this.hidePanel;
      this.displayTable = true;
      this.isDemoStudy = false;
      this.isSig = isIndexing ? false : true;
      this.isIndex = isIndexing ? true : false;
      this.referenceLevelDropDown = false; //isIndexing ? true : false;
      this.DefaultReferenceLevel = isIndexing
        ? this.DefaultReferenceLevel
        : 'None';
      this.selectedIndex = isIndexing ? this.selectedIndex : '';
      const rowdata = this.dataObject['Row'];
      const RowNesting = this.dataObject['RowNesting'];
      const columndata = this.dataObject['Column'];
      const ColNesting = this.dataObject['ColNesting'];
      const Filter = this.dataObject['Filter'];
      let isTimePeriodPresent = this.variableTabData.filter(
        (a: any) => a.is_time_Period_Present === true
      );
      const columnDataLength: number =
        columndata.length > 0 ? columndata.length : 0;
      let isDimensionPresent = this.variableTabData.filter(
        (a: any) => a.is_dimension_Present === true
      );

      this.is_Time_Period_Present =
        isTimePeriodPresent.length == 0 ? false : true;
      this.is_dimension_Present = isDimensionPresent.length == 0 ? false : true;
      this.is_indexingShow =
        this.selectedDataFormat == 'Respondent Level' && this.is_indexing
          ? columnDataLength > 1
            ? false
            : true
          : false;
      this.isDisableReference = columnDataLength > 1 ? true : false;
      const TimePeriodinColumn = columndata.filter((parent) => {
        return parent.Data.some((child) => child.isTimePeriod === true);
      });

      const isDefaultRespGrp = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) =>
            child.CalculationType === 'RespGrp' && child.isDerviedVariable == true
        );
      });

      const isMeanMetric = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) =>
            child.CalculationType.toLowerCase() === 'mean' &&
            child.isDerviedVariable == true
        );
      });

      const isMultiPunchRow = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) => child.isMultiPunch === Status.MultiPunchDisableChart
        );
      });
      const isMultiPunchColumn = columndata.filter((parent) => {
        return parent.Data.some(
          (child) => child.isMultiPunch === Status.MultiPunchDisableChart
        );
      });
      const rowDataLength: number =
        rowdata.length > 0 ? rowdata[0].Data.length : 0;
      this.isChartView =
        this.listBItemsList.length > 0 ||
          this.listDItemsList.length > 0 ||
          rowdata.length > 1 ||
          rowDataLength > 15
          ? false
          : true;
      if (this.isChartView) {
        this.disableLineChart = TimePeriodinColumn.length == 1 ? true : false;

        if (rowdata.length == 1) {
          let filteredData: any = [];
          const disctinctRowValues = this.getDistinctChildValues(
            this.dataObject.Row
          );

          let distinctFirstParts = this.getDistinctValuesByPipeCount(
            disctinctRowValues,
            0
          );
          let distinctSecondParts = this.getDistinctValuesByPipeCount(
            disctinctRowValues,
            1
          );
          let distinctThirdParts = this.getDistinctValuesByPipeCount(
            disctinctRowValues,
            2
          );
          let distinctFourthParts = this.getDistinctValuesByPipeCount(
            disctinctRowValues,
            3
          );
          let distinctFifthParts = this.getDistinctValuesByPipeCount(
            disctinctRowValues,
            4
          );

          if (
            distinctFifthParts.length > 0 &&
            distinctFourthParts.length == 1 &&
            distinctThirdParts.length == 1 &&
            distinctSecondParts.length == 1 &&
            distinctFirstParts.length == 1
          ) {
            this.StackFlag = true;
          } else if (
            distinctFourthParts.length > 0 &&
            distinctThirdParts.length == 1 &&
            distinctSecondParts.length == 1 &&
            distinctFirstParts.length == 1
          ) {
            this.StackFlag = true;
          } else if (
            distinctFourthParts.length > 0 &&
            distinctThirdParts.length > 0 &&
            distinctSecondParts.length == 1 &&
            distinctFirstParts.length == 1
          ) {
            this.StackFlag = false;
          } else if (
            distinctThirdParts.length > 0 &&
            distinctSecondParts.length == 1 &&
            distinctFirstParts.length == 1
          ) {
            this.StackFlag = true;
          } else if (
            distinctThirdParts.length > 0 &&
            distinctSecondParts.length > 0 &&
            distinctFirstParts.length == 1
          ) {
            this.StackFlag = false;
          } else if (
            distinctSecondParts.length > 0 &&
            distinctFirstParts.length == 1
          ) {
            this.StackFlag = true;
          }

          if (this.StackFlag) {
            rowdata[0].Data.forEach((item) => {
              const pipeCount = (item.Child.match(/\|/g) || []).length;
              if (pipeCount === 1) {
                const [level1] = item.Child.split('|');
                filteredData = this.variableTabData.filter(
                  (x: any) => x.Level1 === level1 && x.Header == 'VariableList'
                );
              } else if (pipeCount === 2) {
                const [level1, level2] = item.Child.split('|');
                filteredData = this.variableTabData.filter(
                  (x: any) =>
                    x.Level1 === level1 &&
                    x.Level2 === level2 &&
                    x.Header == 'VariableList'
                );
              } else if (pipeCount === 3) {
                const [level1, level2, level3] = item.Child.split('|');
                filteredData = this.variableTabData.filter(
                  (x: any) =>
                    x.Level1 === level1 &&
                    x.Level2 === level2 &&
                    x.level3 === level3 &&
                    x.Header == 'VariableList'
                );
              } else if (pipeCount === 4) {
                const [level1, level2, level3, level4] = item.Child.split('|');
                filteredData = this.variableTabData.filter(
                  (x: any) =>
                    x.Level1 === level1 &&
                    x.Level2 === level2 &&
                    x.level3 === level3 &&
                    x.level4 == level4 &&
                    x.Header == 'VariableList'
                );
              } else if (pipeCount === 5) {
                const [level1, level2, level3, level4, level5] =
                  item.Child.split('|');
                filteredData = this.variableTabData.filter(
                  (x: any) =>
                    x.Level1 === level1 &&
                    x.Level2 === level2 &&
                    x.level3 === level3 &&
                    x.level4 == level4 &&
                    x.level5 == level5 &&
                    x.Header == 'VariableList'
                );
              }
            });
          } else {
            this.StackFlag = false;
          }
          this.disableStackChart =
            isMultiPunchRow.length > 0 ||
              isMultiPunchColumn.length > 0 ||
              isDefaultRespGrp.length > 0 ||
              isMeanMetric.length > 0
              ? false
              : rowdata[0].Data.length == filteredData.length
                ? true
                : false;
        }
      }
      if (isSampleSize) {
        this.isLineChartClick = this.isLineChartClick || this.isTableClick;
        this.isVerticalGroupBarChartClick =
          this.isVerticalGroupBarChartClick || this.isTableClick;
        this.isVerticalBarChartClick =
          this.isVerticalBarChartClick || this.isTableClick;
        this.isVerticalStackChartClick =
          this.isVerticalStackChartClick || this.isTableClick;
        this.isTableClick = false;
        this.displayTable =
          this.isLineChartClick ||
            this.isVerticalGroupBarChartClick ||
            this.isVerticalStackChartClick
            ? false
            : true;
        this.isData =
          this.isLineChartClick ||
            this.isVerticalGroupBarChartClick ||
            this.isVerticalStackChartClick
            ? false
            : true;
      } else {
        this.isTableClick = false;
        this.isLineChartClick = false;
        this.isVerticalGroupBarChartClick = false;
        this.isVerticalBarChartClick = false;
        this.isVerticalStackChartClick = false;
        this.isData = true;
        this.DefaultDataDropDown = 'Data';
        this.isTranspose = false;
        this.displayTable = true;
        this.isTotal = this.isIndex ? this.isTotal : false;
        if (
          this.dataObject.ColNesting.length == 0 &&
          this.dataObject.Column.length == 1 &&
          this.dataObject.Column[0].Data.length <= 10
        )
          this.isDisablePairWise = false;
      }
      this.isSampleSize = false;
      this.isWeightedLabel = false;
      this.sampleSizeDropdown = false;
      this.confidenceLevelDropDown = false;
      //this.DefaultSampleSize = isSampleSize ? this.DefaultSampleSize : 'None';
      //this.DefaultConfidenceLevel = isSampleSize ? this.DefaultConfidenceLevel : 'None';
      const matchColumnFound = this.isLevel1TimePeriodPresent(
        this.listCItemsList
      );
      const matchColumnNestFound = this.isLevel1TimePeriodPresent(
        this.listDItemsList
      );
      const matchFilterNestFound = this.isLevel1TimePeriodPresent(
        this.listEItemsList
      );

      const matchColumnDimension = this.isDimensionPresent(this.listCItemsList);

      const matchColumnNestDimension = this.isDimensionPresent(
        this.listDItemsList
      );

      const matchFilterDimension = this.isDimensionPresent(this.listEItemsList);

      const distinctValuesForListA = this.getDistinctLevel0Values(
        this.listAItemsList
      );

      const distinctValuesForListC = this.getDistinctLevel0Values(
        this.listCItemsList
      );
      const distinctValuesForListD = this.getDistinctLevel0Values(
        this.listDItemsList
      );
      const distinctValuesForListE = this.getDistinctLevel0Values(
        this.listEItemsList
      );

      const allDistinctValues = [
        ...distinctValuesForListC,
        ...distinctValuesForListD,
        ...distinctValuesForListE,
      ];

      const allDistinctValueAggregated = [
        ...distinctValuesForListA,
        ...distinctValuesForListC,
        ...distinctValuesForListD,
        ...distinctValuesForListE,
      ];

      const distinctEntryList = this.getDistinctENtryList(this.entryList);

      const distinctAggregatedMetric = this.getDistinctAggregaredMetrics(
        this.variableTabData
      );

      const allEntryListPresent = distinctEntryList.every((entry) =>
        allDistinctValues.includes(entry)
      );

      const allEntryListPresentAggregated = distinctAggregatedMetric.every(
        (entry) => allDistinctValueAggregated.includes(entry)
      );

      const matchingCount = distinctEntryList.filter((entry) =>
        allDistinctValues.includes(entry)
      ).length;
      // let Isshowpopup = this.ShowCustomValidationPopUp(
      //   rowdata,
      //   columndata,
      //   RowNesting,
      //   ColNesting,
      //   Filter,
      //   matchingCount,
      //   matchColumnDimension,
      //   matchColumnNestDimension,
      //   matchFilterDimension,
      //   matchColumnFound,
      //   matchColumnNestFound,
      //   matchFilterNestFound,
      //   distinctEntryList,
      //   allEntryListPresent,
      //   allEntryListPresentAggregated,
      //   allDistinctValueAggregated
      // );
  let Isshowpopup=false;


      // if (this.listAItemsList.length == 0 || this.listCItemsList.length == 0) {
      //   this.getValidation('createtable', 'Rows/Columns', '');
      // }else if (matchingCount != this.entryList.length && this.entryList.length > 0) {
      //   this.getValidation('entry', 'entrypoints', '');
      // }else if (!(matchColumnFound || matchColumnNestFound || matchFilterNestFound)) {
      //   this.getValidation('missingtimeperiod', 'Rows/Columns/Filters', '');
      // }else if (distinctEntryList.length == 0 && !(matchColumnDimension || matchColumnNestDimension || matchFilterDimension)) {
      //   this.getValidation('dimension', 'output', '');
      // } else if (this.listAItemsList.length > 0 || this.listCItemsList.length > 0) {
      // show messages for the not selected option list in selection panel

      // }
      // this.getValidation('MetricSelection', 'Rows/Columns', '');
      // } else if (this.listBItemsList.length != RowNesting.length) {
      //   this.getValidation('MetricSelection', 'Rows/Columns', '');
      // } else if (this.listCItemsList.length != columndata.length) {
      //   this.getValidation('MetricSelection', 'Rows/Columns', '');
      // } else if (this.listDItemsList.length != ColNesting.length) {
      //   this.getValidation('MetricSelection', 'Rows/Columns', '');
      // } else if (this.listEItemsList.length != Filter.length) {
      //   this.getValidation('MetricSelection', 'Rows/Columns', '');
      // }
      //  else if (allEntryListPresent == false) {
      //   this.getValidation('missingentry', 'Rows/Columns/Filters', '');
      // }
      if (Isshowpopup == false) {
        //const disctinctRowValues = this.getDistinctChildValues(
        //  this.dataObject.Row
        //);
        //const disctinctRowNestingValues = this.getDistinctChildValues(
        //  this.dataObject.RowNesting
        //);
        //const disctinctColumnValues = this.getDistinctChildValues(
        //  this.dataObject.Column
        //);
        //const disctinctColumnNestingValues = this.getDistinctChildValues(
        //  this.dataObject.ColNesting
        //);
        //const FilterValues = this.getDistinctChildValues(this.dataObject.Filter);

        //const RowValues = this.transformData(disctinctRowValues);
        //const RowNestingValues = this.transformData(disctinctRowNestingValues);
        //const ColumnValues = this.transformData(disctinctColumnValues);
        //const ColumnNestingValues = this.transformData(
        //  disctinctColumnNestingValues
        //);
        //const FilterData = this.transformData(FilterValues);
        //this.BindSelectionSummary(
        //  RowValues,
        //  RowNestingValues,
        //  ColumnValues,
        //  ColumnNestingValues,
        //  FilterData,
        //  false
        //);
        this.SelectionSummary();
        this.isCreateBtnClick = true;
        this.clearOutputDataObject();
        this.arktabaccess=true;

        // Corrected variable declarations
        var weightColumnName: string = '';
        var isBoostFactor: boolean = false;

        //if boost factor is selected in weights dropdown then send default weight column
        if (this.DefaultWeight.trim() == 'Boost Factor') {
          //consider first weight as the default weight
          weightColumnName = this.WeightDropDownList[0].responseCode;
          isBoostFactor = true;
        }

        var istimePeriodWave = this.isWaveTimePeriod();
        //this.mandatoryVariableMapping;
        // debugger
        this.AssignDataToPreviousDataObject(this.dataObject);
        
        this.$unSubscribeSubmitData4 = this.crossTabService
          .getOutPutData(
            this.previewDataObject,
            this.selected_client,
            this.selected_study,
            this.DefaultSampleSize,
            this.isFilteringActive,
            this.DefaultConfidenceLevel,
            this.isTotalBase,
            this.selectedWeight,
            weightColumnName,
            isBoostFactor,
            this.SignificanceVsReference,
            this.columnLength,
            this.dataArray,
            false,
            this.isAggregatedData,
            this.isSemiAggregatedData,
            this.isRespondentLevel,
            this.aggregatedVariables,
            istimePeriodWave
          )
          .subscribe((result: results) => {
            // Response is valid
            if (result.HasValidData) {
              let JsonData = JSON.parse(result.Data);
              // this.CrossTabOutPutData = JsonData;
              this.isReference = false;
              this.CrossTabOutPutEntireData = JsonData;
              let chartType = 'column';
              chartType = this.isTranspose ? 'column' : 'bar';
              if (this.CrossTabOutPutEntireData.length > 0) {
                this.CrossTabOutPutData = this.CrossTabOutPutEntireData;
                if (!this.isTotal)
                  this.CrossTabOutPutData = this.CrossTabOutPutEntireData.filter(
                    (x: any) => {
                      return !x.isTotal;
                    }
                  );
                if(this.isIndex && this.selectedIndex!=''){
                  this.manipulateIndexData();
                }
                // this.sampleSizeDropdown=true;
                this.BindTableOutput(this.CrossTabOutPutData);
                this.sortOutputData();

                //this.LimitDataForOutput();
                // Check if the 'isTranspose' flag is set to true
                if (this.isTranspose) {
                  // If 'isTranspose' is true, call a function to modify row column based on transpose selection
                  this.handleOutputdataObjectOnTransposeSelect();
                }
                this.crossTabService.setCrossTabOuputData(
                  this.CrossTabOutPutData
                );

                if (this.isLineChartClick) {
                  this.onTrendChartIconClick();
                } else if (
                  this.isVerticalGroupBarChartClick ||
                  this.isVerticalBarChartClick
                ) {
                  this.VerticalChartClick(chartType);
                } else if (this.isVerticalStackChartClick) {
                  this.onVerticalStackBarChartIconClick(chartType);
                }
              }
            } 
            // For usage log
            // this.usageDataLog(feature);
            //hide the loader
            this.arktabaccess=false;
          });
      }

      if (columnDataLength > 1) this.isDisableReference = true;
      else this.isDisableReference = false;
    }
    AssignDataToPreviousDataObject(dataObject: DataObject) {
      // debugger
      this.previewDataObject.Row=dataObject.Row;
      this.previewDataObject.Column=dataObject.Column;
      this.previewDataObject.ColNesting=dataObject.ColNesting;
      this.previewDataObject.RowNesting=dataObject.RowNesting;
      this.previewDataObject.Filter=dataObject.Filter
      
    }

    private isWaveTimePeriod() {
      if (
        this.mandatoryVariableMapping == undefined ||
        this.mandatoryVariableMapping == null
      )
        return false;
      else {
        let timePeriodEntries: any = this.mandatoryVariableMapping.filter(
          (entry: any) =>
            entry.variable_label === 'Time Period' &&
            entry.variable_code &&
            entry.variable_code.trim() !== ''
        );

        let isWaveTimePeriod =
          timePeriodEntries.length === 0 ||
          timePeriodEntries.every(
            (entry: any) =>
              entry.variable_code === timePeriodEntries[0].variable_code
          );

        return isWaveTimePeriod;
      }
    }
    clearOutputDataObject(): void {
      this.OutputdataObject = {
        Row: [],
        Column: [],
      };
      this.FinalOutputdataObject = {
        Row: [],
        Column: [],
      };
    }
      getDistinctAggregaredMetrics(list: any[]) {
        const distinctLevel0Values = [...new Set(list.map((item) => item.Level0))];
        return distinctLevel0Values;
      }
      getDistinctENtryList(list: any[]) {
        const distinctLevel0Values = [...new Set(list.map((item) => item.Level1))];
        return distinctLevel0Values;
      }
      getDistinctLevel0Values(list: any[]) {
        const distinctLevel0Values = [...new Set(list.map((item) => item.Level0))];
        return distinctLevel0Values;
      }
      isDimensionPresent(list: any[]): boolean {
        return list.some((entry) => entry.is_dimension === true);
      }
    
      isLevel1TimePeriodPresent(list: any[]): boolean {
        return list.some((entry) => entry.Level0 === 'Time Period');
      }
    getLastLevelColumnNestingValues() {
      var indexData;
      if (this.OutputdataObject.Column.length > 0) {
        if (this.OutputdataObject['Column'][0]['Data'].length > 0) {
          let firstLevelLength =
            this.OutputdataObject['Column'][0]['Data'].length;

          if (firstLevelLength > 0) {
            let level0 =
              this.OutputdataObject['Column'][0]['Data'][firstLevelLength - 1];
            if (level0.NestedArray.length > 0) {
              let secondLevelLength = level0.NestedArray.length;
              if (secondLevelLength > 0) {
                let level1 = level0.NestedArray[secondLevelLength - 1];

                if (level1.NestedArray.length > 0) {
                  let finalData =
                    this.OutputdataObject['Column'][0]['Data'][
                      firstLevelLength - 1
                    ].NestedArray[secondLevelLength - 1].NestedArray;
                  indexData = finalData;
                  this.columnLength = 2;
                } else {
                  indexData = level0.NestedArray;
                  this.columnLength = 1;
                }
              }
            } else {
              indexData = this.OutputdataObject['Column'][0]['Data'];
              this.columnLength = 0;
            }
          }
        }
      }

      return indexData;
    }
    SelectionSummary() {
      const disctinctRowValues = this.getDistinctChildValues(this.dataObject.Row);
      const disctinctRowNestingValues = this.getDistinctChildValues(
        this.dataObject.RowNesting
      );
      const disctinctColumnValues = this.getDistinctChildValues(
        this.dataObject.Column
      );
      const disctinctColumnNestingValues = this.getDistinctChildValues(
        this.dataObject.ColNesting
      );
      const FilterValues = this.getDistinctChildValues(this.dataObject.Filter);

      const RowValues = this.transformData(disctinctRowValues);
      const RowNestingValues = this.transformData(disctinctRowNestingValues);
      const ColumnValues = this.transformData(disctinctColumnValues);
      const ColumnNestingValues = this.transformData(
        disctinctColumnNestingValues
      );
      const FilterData = this.transformData(FilterValues);
      this.BindSelectionSummary(
        RowValues,
        RowNestingValues,
        ColumnValues,
        ColumnNestingValues,
        FilterData,
        false
      );
    }
    BindSelectionSummary(
      RowValues: string[],
      RowNestingValues: string[],
      ColumnValues: string[],
      ColumnNestingValues: string[],
      FilterData: string[],
      isSavedSelection: boolean
    ) {
      let rowNestingSection = '';
      let colNestSection = '';
      let FiltersSection = '';
      let significanceSection = '';
      let confidenceSection = '';
      let Reference = '';
      let Weight = '';
      let Base = '';
      let study = '';
      if (RowNestingValues.length > 0) {
        rowNestingSection = ` <span class="grey-separator"> || </span> <span class="summary-label">Row Nesting:</span> ${RowNestingValues.join(
          '&nbsp;,&nbsp;'
        )}`;
      }
      if (ColumnNestingValues.length > 0) {
        colNestSection = ` <span class="grey-separator"> || </span> <span class="summary-label">Column Nesting:</span> ${ColumnNestingValues.join(
          '&nbsp;,&nbsp;'
        )}`;
      }
      if (FilterData.length > 0) {
        FiltersSection = `  <span class="summary-label">Filters:</span> ${FilterData.join(
          '&nbsp;,&nbsp;'
        )} <span class="grey-separator"> || </span> `;
      }
      if (!isSavedSelection && this.selectedDataFormat == 'Respondent Level') {
        if (this.SignificanceVsReference != '') {
          significanceSection = `  <span class="summary-label">Significance:</span> ${this.DefaultSampleSize
            } : ${this.replacePipe(
              this.SignificanceVsReference
            )} <span class="grey-separator"> || </span> `;
        } else {
          significanceSection = `  <span class="summary-label">Significance:</span> ${this.DefaultSampleSize} <span class="grey-separator"> || </span> `;
        }
        confidenceSection = `  <span class="summary-label">Confidence Level:</span> ${this.DefaultConfidenceLevel} <span class="grey-separator"> || </span> `;
      }
      if (
        this.selectedIndex != '' &&
        this.isIndex &&
        this.selectedDataFormat == 'Respondent Level'
      ) {
        Reference = `  <span class="summary-label">Reference:</span> ${this.replacePipe(
          this.selectedIndex
        )} <span class="grey-separator"> || </span> `;
      }
      if (
        this.DefaultWeight != '' &&
        this.selectedDataFormat == 'Respondent Level'
      ) {
        Weight = `  <span class="summary-label">Calculation Weight:</span> ${this.DefaultWeight} <span class="grey-separator"> || </span> `;
      }
      if (this.selectedDataFormat == 'Respondent Level') {
        Base = `<span class="summary-label">Calculation Base:</span> ${this.isTotalBase ? 'Total Base' : 'Answer Base'
          } <span class="grey-separator"> || </span> `;
      }
      study = `<span class="summary-label">Study Type:</span> ${this.selected_study} <span class="grey-separator"> || </span> `;

      this.selectionSummary = `${study}  ${Base} <span class="summary-label">Nesting Type:</span> ${this.isFilteringActive ? 'Filtering' : 'Profiling'
        } <span class="grey-separator"> || </span> ${Weight} ${significanceSection} ${confidenceSection} ${Reference}  ${FiltersSection} <span class="summary-label">Row:</span> ${RowValues.join(
          '&nbsp;,&nbsp;'
        )}
      ${rowNestingSection} <span class="grey-separator"> || </span> <span class="summary-label">Column:</span> ${ColumnValues.join(
          '&nbsp;,&nbsp;'
        )} ${colNestSection}`;

      this.selectionSummary = this.selectionSummary.replaceAll('@', ' , ');
    }

    transformData(dataList: string[]): string[] {
      const transformedData: { [key: string]: { [subKey: string]: string[] } } =
        {};

      dataList.forEach((item) => {
        const parts = item.split('|');
        const mainKey = parts[0];

        if (parts.length > 1) {
          const subKey = parts.slice(1, -1).join('|');
          const value = parts.slice(-1)[0];

          if (!transformedData[mainKey]) {
            transformedData[mainKey] = {};
          }

          if (!transformedData[mainKey][subKey]) {
            transformedData[mainKey][subKey] = [];
          }

          transformedData[mainKey][subKey].push(value);
        } else {
          // Handle single-value case
          if (!transformedData[mainKey]) {
            transformedData[mainKey] = { '': [] };
          }

          transformedData[mainKey][''].push(parts[0]);
        }
      });

      return Object.keys(transformedData).map((mainKey) => {
        const subKeys = Object.keys(transformedData[mainKey]);
        const subStrings = subKeys.map((subKey) => {
          const subString =
            subKey === ''
              ? transformedData[mainKey][subKey]
              : `${subKey}: ${transformedData[mainKey][subKey].join(',')}`;
          return subString;
        });
        // return `${mainKey}: ${subStrings.join(' , ')}`;
        return `${mainKey}: ${subStrings.join(',').replace(/,/g, ' , ')}`; // Add space before and after the comma
      });
    }
    reverseTranspose() {
      if (
        this.OutputdataObject != null &&
        (this.OutputdataObject['Row'].length > 0 ||
          this.OutputdataObject['Column'].length > 0)
      ) {
        // Create copies of rows and columns arrays to preserve the original data
        let tempRow = [...this.OutputdataObject.Column];
        let tempColumn = [...this.OutputdataObject.Row];

        // Swap rows and columns to transpose the data
        this.OutputdataObject.Row = [...tempRow];
        this.OutputdataObject.Column = [...tempColumn];
      }
    }
    SampleSizeDropDownClick(SelectedValue: string) {
      this.isPairWise = false;
      this.isReference = false;
      // this.SignificanceVsReference = "";

      if (SelectedValue == 'Reference') {
        this.isReference = true;

        this.IndexData = this.getLastLevelColumnNestingValues();
      } else {
        this.SignificanceVsReference = '';
        this.isReference = false;
        this.DefaultDataDropDown = 'Data';
        this.DefaultSampleSize = SelectedValue;
        if (SelectedValue == 'None') {
          this.DefaultConfidenceLevel = 'None';
        } else if (SelectedValue == 'Pairwise') {
          this.DefaultConfidenceLevel = this.is_confidenceLevel.length >0 ? this.is_confidenceLevel[0]['key']:'95%';
          this.isPairWise = true;
        } else {
          this.DefaultConfidenceLevel = this.is_confidenceLevel.length > 0 ? this.is_confidenceLevel[0]['key'] : '95%';
        }
        this.sampleSizeDropdown = false;
        this.createTable(true, false);
        // this.sampleSizeDropdown=true;
        this.is_Time_Period_Present=true;

      }
    }
    confidenceDropDownClick(SelectedValue: string) {
      this.DefaultConfidenceLevel = SelectedValue;
      this.confidenceLevelDropDown = false;
      this.DefaultDataDropDown = 'Data';
      this.createTable(true, false);
      this.is_Time_Period_Present=true;
    }

    referenceDropDownClickIndexSignificance(SelectedValue: string) {
      ;
    this.SignificanceVsReference = SelectedValue;
    this.DefaultConfidenceLevel = this.is_confidenceLevel.length > 0 ? this.is_confidenceLevel[0]['key'] : '95%';
    this.DefaultSampleSize = 'Reference';
    this.DefaultDataDropDown = 'Data';
    this.createTable(true, false);
    this.is_Time_Period_Present=true;
      // this.sampleSizeDropdown=true;
  }

    referenceDropDownClick(SelectedValue: string) {
      this.DefaultReferenceLevel = this.replacePipe(SelectedValue);
      this.selectedIndex = SelectedValue;
      this.createTable(false, true);

      //let data = this.CrossTabOutPutData;
      //this.CrossTabOutPutData = [];
      //this.CrossTabOutPutData = data;
      //if (this.CrossTabOutPutData.length > 0) {
      //  this.crossTabService.setCrossTabOuputData(this.CrossTabOutPutData);
      //}
      //this.referenceLevelDropDown = false;
      //this.createTable(true, Feature.ConfidenceLevel);
    }

    togglePanel(SelectedDropDown: string) {
      if (SelectedDropDown == 'significance') {
        this.sampleSizeDropdown = !this.sampleSizeDropdown;
        this.isReference = false;
      } else if (SelectedDropDown == 'confidence') {
        this.confidenceLevelDropDown = !this.confidenceLevelDropDown;
      } else if (SelectedDropDown == 'reference') {
        this.referenceLevelDropDown = !this.referenceLevelDropDown;
      } else if (SelectedDropDown == 'weight') {
        this.weightDropDown = !this.weightDropDown;
      } else if (SelectedDropDown == 'datadropdown') {
        this.dataDropDown = !this.dataDropDown;
      }
    }
    handleExportClick() {
      debugger
      const FilterValues = this.getDistinctChildValues(this.dataObject.Filter);
      const FilterData = this.transformData(FilterValues);
      this.arktabaccess=true;
      let excelinputs: ExcelDataModel = {
        significance: this.DefaultSampleSize,
        isTranspose: this.isTranspose,
        baseType: this.isTotalBase,
        confidenceLevel: this.DefaultConfidenceLevel,
        output: '', //JSON.stringify(this.CrossTabOutPutData),
        studyName: this.selected_study,
        clientName: this.selected_client,
        filters: FilterData.join(','),
        nestingType: this.isFilteringActive ? 'Filtering' : 'Profiling',
        isFiltering: this.isFilteringActive,
        selectionInput: this.dataObject,
        // selectionSummary: this.summaryText==undefined?"":this.summaryText?.nativeElement.innerText,
        selectionSummary:JSON.stringify(this.insightsVal),
        failedComments: '',
        isTotal: this.isTotal,
        lowBase: this.LowBase == '' ? 0 : Number(this.LowBase),
        InsufficientBase:
          this.insufficientBase == '' ? 0 : Number(this.insufficientBase),
        threshold: this.threshold == '' ? 0 : Number(this.threshold),
        selectedIndex: this.selectedIndex,
        selectedWeight: this.selectedWeight,
        DefaultWeight: this.DefaultWeight,
        isBrandAnalysis: false,
        SignificanceVsReference: this.replacePipe(this.SignificanceVsReference),
        DataFormat: this.selectedDataFormat,
        isSampleSizeBlock: this.isSampleSizeBlock,
        isWeightedSampleBlock: this.isWeightedSampleBlock,
      };
      if (!this.FooterNote) {
        excelinputs.output = JSON.stringify(this.CrossTabOutPutData);
        this.$unSubscribeSubmitData5 = this.apiService
          .exportToExcelARK(excelinputs)
          .subscribe(
            (excelBlob: Blob) => {
              let datetimeFormat = this.getCurrentDateTime();
              let fileName =
                'KantarXTab_' +
                excelinputs.clientName +
                '_' +
                excelinputs.studyName +
                '_' +
                datetimeFormat +
                '.xlsx';
              // Trigger file download
              this.downloadFile(excelBlob, fileName);
              this.arktabaccess=false;
              // this.usageDataLog(Feature.ExportExcel);
            },
            (error) => {
              console.error('Error exporting to Excel', error);
              // this.errorPopup('Something went wrong, please try again');
              this.arktabaccess=false;
            }
          );
      } else {
        this._exportDownloads
          .exportToExcelLargeSelection(excelinputs)
          .subscribe((result: results) => {
            // Check if the response has valid data
            if (result.HasValidData) {
              let ExportReport: ExcelExportReport = JSON.parse(result.Data);
              if (ExportReport != null) {
                // this.errorPopup(this.largeSelectionText, 'Alert');
                console.log('Started in Background');
              }
            }
            // Hide the loader after the deletion process
            this.arktabaccess=false;

            // this.usageDataLog(Feature.ExportExcel);
          });
      }
    }
    OnTransposeClick() {
      this.isTableClick = false;

      this.isTranspose = !this.isTranspose;
      this.disableLineChart = this.isTranspose ? false : true;
      this.isLineChartClick = false;
      //disable significance if transpose enabled
      this.isSignificanceContainerDisabled =
        !this.isSignificanceContainerDisabled;
      if (
        (this.isVerticalGroupBarChartClick || this.isVerticalBarChartClick) &&
        this.isTranspose
      ) {
        this.VerticalChartClick('bar');
      } else if (
        (this.isVerticalGroupBarChartClick || this.isVerticalBarChartClick) &&
        !this.isTranspose
      ) {
        this.VerticalChartClick('column');
      } else if (this.isVerticalStackChartClick && this.isTranspose) {
        this.onVerticalStackBarChartIconClick('bar');
      } else if (this.isVerticalStackChartClick && !this.isTranspose) {
        this.onVerticalStackBarChartIconClick('column');
      }
      this.handleOutputdataObjectOnTransposeSelect();
      //else {
      //  this.handleOutputdataObjectOnTransposeSelect();
      //  // if (
      //  //   this.OutputdataObject != null &&
      //  //   (this.OutputdataObject['Row'].length > 0 ||
      //  //     this.OutputdataObject['Column'].length > 0)
      //  // ) {
      //  //   let tempRow = [...this.OutputdataObject.Row];
      //  //   let tempColumn = [...this.OutputdataObject.Column];
      //  //   this.OutputdataObject.Row = [...tempColumn];
      //  //   this.OutputdataObject.Column = [...tempRow];
      //  //   // this.crossTabService.setCrossTabOuputData(
      //  //   //   this.CrossTabOutPutData
      //  //   // );
      //  // }
      //}
    }
    handlePPTExportClick() {
      this.arktabaccess=true;
      let pptinputs: ChartDetails = {
        selectedSignifcance:
          this.DefaultSampleSize == 'Pairwise' ? 'None' : this.DefaultSampleSize,
        ChartCategory: this.isLineChartClick
          ? 'LineOrTrendChart'
          : this.isVerticalBarChartClick || this.isVerticalGroupBarChartClick
            ? 'Bar'
            : 'StackedBar',
        DisplayName: this.isLineChartClick
          ? 'Line Chart'
          : this.isVerticalBarChartClick
            ? 'BarChart'
            : this.isVerticalGroupBarChartClick
              ? 'GroupBarChart'
              : this.isVerticalStackChartClick && this.isTranspose
                ? 'StackedBar'
                : 'StackedColumn',
        ChartTitle: this.isLineChartClick
          ? 'Line Chart'
          : this.isVerticalBarChartClick && this.isTranspose
            ? 'horizontal bar chart'
            : this.isVerticalBarChartClick && !this.isTranspose
              ? 'vertical bar chart'
              : this.isVerticalGroupBarChartClick && this.isTranspose
                ? 'Horizontal Cluster Bar Chart'
                : this.isVerticalGroupBarChartClick && !this.isTranspose
                  ? 'Vertical Cluster Bar Chart'
                  : this.isVerticalStackChartClick && this.isTranspose
                    ? 'Horizontal Stacked Chart'
                    : 'Vertical Stacked Chart',
        ChartOutput: this.isLineChartClick
          ? JSON.stringify(this.LineChart)
          : this.isVerticalStackChartClick
            ? JSON.stringify(this.StackChart)
            : JSON.stringify(this.BarChart),
        isSolidPattern: false,
        chartId: this.isLineChartClick
          ? 1
          : this.isVerticalStackChartClick
            ? 2
            : (this.isVerticalBarChartClick || this.isVerticalGroupBarChartClick) &&
              this.isTranspose
              ? 3
              : (this.isVerticalBarChartClick || this.isVerticalGroupBarChartClick) &&
                !this.isTranspose
                ? 1
                : 0,
        Type: this.isTranspose ? 'Column' : 'Bar',
        // SelectionSummary: this.summaryText?.nativeElement.innerText,
        SelectionSummary:JSON.stringify(this.insightsVal),
        insufficientBase: this.insufficientBase,
        lowBaseValue: this.LowBase,
      };

      //excelinputs.output = JSON.stringify(this.CrossTabOutPutData)
      this.$unSubscribeSubmitData7 = this.apiService
        .exportToPPT(pptinputs)
        .subscribe(
          (pptBlob: Blob) => {
            let datetimeFormat = this.getCurrentDateTime();
            let fileName =
              'KantarXTab_' +
              this.selected_client +
              '_' +
              this.selected_study +
              '_' +
              datetimeFormat +
              '.pptx';
            // Trigger file download
            this.downloadPPTFile(pptBlob, fileName);
            this.arktabaccess=false;
          },
          (error) => {
            console.error('Error exporting to Excel', error);
            // this.errorPopup('Something went wrong, please try again');
            this.arktabaccess=false;
          }
        );
    }
    sendInputMessage() {
      // debugger
      if(this.inputValue!=""){
      this.displayTable = false;
      this.insightsVal = "";
      this.OutputdataObject.Row = [];
      this.OutputdataObject.Column = [];
      this.arktabaccess=true;
      this.isVerticalGroupBarChartClick=false;
      this.isVerticalBarChartClick=false;
      this.isVerticalStackChartClick=false;
      this.isLineChartClick=false;
      this.isInsightspopupOpen=false;
      this.fomData=JSON.parse(JSON.stringify(this.fomDataArray));
      this.inputValueShow = this.inputValue
      this.apiService.getOutputData(this.inputValue,this.fomData).subscribe((data) => {
        // this.displayTable=true;
        this.arktabaccess=false;
        this.inputValue = "";
        this.OutputdataObject.Row = [];
        this.OutputdataObject.Column = [];
        this.CrossTabOutPutData = [];
        // if (data != null && data.HasValidData) {
        if(data.outputstring !=null){
          // sessionStorage.setItem('ResponseData', JSON.stringify(data));
          // this.isExistingDataPresent=true;
          let outputstring=JSON.parse(data.outputstring);
          
          console.log(data);
          this.displayTable = true;
    
          //this.CrossTabOutPutData = JSON.parse(data.Data);
          this.CrossTabOutPutData = JSON.parse(outputstring.Data);
          console.log("output", this.CrossTabOutPutData);
          this.AssignDataToDataObject(data.datamodel);
          this.variableTabData=this.receivedData;
          //this.CrossTabOutPutEntireData = JSON.parse(data.Data);
          this.CrossTabOutPutEntireData = JSON.parse(outputstring.Data);
          let chartType = 'column';
          chartType = this.isTranspose ? 'column' : 'bar';
          if (this.CrossTabOutPutEntireData.length > 0) {
            this.CrossTabOutPutData = this.CrossTabOutPutEntireData;
          }
          // this.isTotalBase = true;
          this.isTranspose = false;
          this.insufficientBase = "";
          this.DefaultConfidenceLevel = 'None';
          this.isAnswerBase = false;
          this.isSampleSize = false;
          this.isWeightedLabel = false;
          this.LowBase = "";

          this.isPairWise = false;
          this.DefaultSampleSize = 'None';
          this.threshold = "";
          this.selectedIndex = '';
          this.isIndex = false;
          this.selectedWeight = '';
          if (!this.isTotal) {
            this.CrossTabOutPutData = this.CrossTabOutPutEntireData.filter(
              (x: any) => {
                return !x.isTotal;
              }
            );
          }
          if (this.isIndex && this.selectedIndex != '') {
            this.manipulateIndexData();
          }
          this.BindTableOutput(this.CrossTabOutPutData);
          this.sortOutputData();
          this.isCreateBtnClick = true;
          if (this.isTranspose) {
            // If 'isTranspose' is true, call a function to modify row column based on transpose selection
            this.handleOutputdataObjectOnTransposeSelect();
          }
          if (this.OutputdataObject.Row.length != 0 && this.OutputdataObject.Column.length != 0) {
            this.displayTable = true;
          }
          const columndata = this.dataObject['Column'];

          const TimePeriodinColumn = columndata.filter((parent) => {
                return parent.Data.some((child) => child.isTimePeriod === true);
              });
          
          this.disableLineChart = TimePeriodinColumn.length == 1 ? true : false;
        
          this.crossTabService.setCrossTabOuputData(this.CrossTabOutPutData);
          if (this.isLineChartClick) {
            this.onTrendChartIconClick();
          } else if (
            this.isVerticalGroupBarChartClick ||
            this.isVerticalBarChartClick
          ) {
            this.VerticalChartClick(this.chartType);
          } else if (this.isVerticalStackChartClick) {
            this.onVerticalStackBarChartIconClick(this.chartType);
          }
          this.is_Time_Period_Present=true;
        }
        this.isInsightspopupOpen = false;
        this.arktabaccess=true;
        this.openInsights();
        this.arktabaccess=false;
      })
        this.LoadRibbonData(this.filteredlist);
      }
      }
    AssignDataToDataObject(datamodel: any) {
      this.dataObject.Row=datamodel.Row;
      this.dataObject.Column=datamodel.Column;
      this.dataObject.ColNesting=datamodel.ColNesting;
      this.dataObject.RowNesting=datamodel.RowNesting;
      this.dataObject.Filter=datamodel.Filter;
      this.isTotalBaseFromPrompt=datamodel.isTotalBase;
    }
    
    LoadRibbonData(receivedData: any) {
          //  debugger
          if(this.SampleSizeList.length==0){
      for (const item of receivedData.comparison_point) {
          if (item.value) {
              this.SampleSizeList.push(item);
          }
      }
    }
      this.is_indexing = receivedData.output_page[1].value;
      this.isRowNesting =receivedData.dataformat[0].key=='Respondent Level'? receivedData.nesting[0].is_selected : false;
      this.isColumnNesting = receivedData.nesting[1].is_selected;
      this.is_TransposeBtn =receivedData.dataformat[0].key=='Respondent Level'? receivedData.output_page[0].value: false;
    this.filteringAnsBlock =receivedData.dataformat[0].key=='Respondent Level'? receivedData.filter_function[0].value: false;
    this.AnsBaseBlock =receivedData.dataformat[0].key=='Respondent Level'? receivedData.filter_function[1].value: false;
    this.is_sigtesting =receivedData.dataformat[0].key=='Respondent Level'? receivedData.output_page[2].value: false;
            this.is_brand_analysis = receivedData.is_brand_analysis,
            this.is_chart_visualization = receivedData.is_chart_visualization,
            this.is_sticky_selection = receivedData.is_sticky_selection,
            this.is_reset_selection = receivedData.is_reset_selection,
            this.is_Transpose = receivedData.output_page[0].value,
            this.is_sigtesting = receivedData.output_page[2].value,
            this.is_ranking = receivedData.output_page[3].value,
            this.is_confidenceLevel = receivedData.confidence_level;
            this.isFilterToggle = receivedData.filter_function[0].value,
            this.isAnswerToggle = receivedData.filter_function[1].value,
            this.excelExport = receivedData.export_download[0].value,
            // this.SampleSizeList = receivedData.comparison_point;
          //  this.pptExport= receivedData.export_download[1].value,
            this.repository = receivedData.export_download[2].value,
            this.lowBaseIdentifier = receivedData.export_download[3].value,
            this.is_AI_Integration = receivedData.is_AI_Integration,
            this.is_StoryBoard = receivedData.is_StoryBoard,
            this.is_Column_Row_Percentage = receivedData.is_Column_Row_Percentage,
            this.insufficentBase = receivedData.low_base_identifier[0].data_value,
            this.lowBase = receivedData.low_base_identifier[1].data_value,
            this.threshold = receivedData.threshold_identifier[0].data_value,
            this.iscustomgrp = receivedData.custom_content[0].value,
            this.iscustomCalc = receivedData.custom_content[1].value,
            this.iscustomreport = receivedData.custom_content[2].value,
            this.changeEntryPoint = receivedData.save_selection[1].value,
            // this.dataFormat: dataFormat.length > 0 ? dataFormat[0].key : '',
            this.aggregatedVariables = receivedData.aggregatedvariables,
            this.isWeightedBase = receivedData.aggregatedvariables[1].data_value == '' ? false : true,
            this.isSampleSize = receivedData.aggregatedvariables[2].data_value == '' ? false : true,
            this.mandatoryVariableMapping = receivedData.mandatory_variable_mapping,
            this.isSelectAllMetrics = receivedData.is_SelectAll_Metrics
            this.isSelectAllMetricsLevel = receivedData.isSelectAllMetrics;
            this.SampleSizeContainer =receivedData.dataformat[0].key=='Respondent Level'? true: receivedData.isWeightedBase || receivedData.isSampleSize? true: false;
            this.isSampleSizeBlock =receivedData.dataformat[0].key=='Respondent Level'? true: receivedData.isSampleSize;
            this.isWeightedSampleBlock =receivedData.dataformat[0].key=='Respondent Level'? true: receivedData.isWeightedBase;
            this.selectedDataFormat = receivedData.dataformat[0].key;
            const noneexists = this.SampleSizeList.some((item: { key: string; }) => item.key === "None");
            if(!noneexists){
              let newPair = { key: 'None', value: true };
            this.SampleSizeList.unshift(newPair);
            }
    
          
    }
    manipulateIndexData(){
      let level=0;
      if(this.dataObject.ColNesting.length>0){
        level=this.dataObject.ColNesting.length;
      }
      let selectedIndexData=this.CrossTabOutPutData.filter((x: any) => {
          if(level==1)
            return x.Level1ColChild==this.selectedIndex;
          else if(level==2)
            return x.Level2ColChild==this.selectedIndex;
          else
          return x.colChild==this.selectedIndex;
      });
      if(selectedIndexData.length>0){
        selectedIndexData=JSON.parse(JSON.stringify(selectedIndexData));
        selectedIndexData.forEach((item: any) => {
          let indexData = this.CrossTabOutPutData.filter((x: any) => {
            if(level==1){
              return x.rowChild == item.rowChild && x.Level2RowChild == item.Level2RowChild && x.Level1RowChild == item.Level1RowChild 
              && x.colChild == item.colChild
            }
            else if(level==2){  
              return x.rowChild == item.rowChild && x.Level2RowChild == item.Level2RowChild && x.Level1RowChild == item.Level1RowChild
              && x.colChild == item.colChild && x.Level1ColChild == item.Level1ColChild
            }
            else
              return x.rowChild == item.rowChild && x.Level2RowChild == item.Level2RowChild && x.Level1RowChild == item.Level1RowChild
          });
          if(indexData.length>0){
            indexData.forEach((indexItem: any) => {
              if (this.selectedWeight == "Unweighted") {
                indexItem.totalUnweightedShare = isNaN(indexItem.totalUnweightedShare / item.totalUnweightedShare) ? null : isFinite(indexItem.totalUnweightedShare / item.totalUnweightedShare) ? (indexItem.totalUnweightedShare / item.totalUnweightedShare) : null;
                indexItem.answerUnweightedShare = isNaN(indexItem.answerUnweightedShare / item.answerUnweightedShare) ? null : isFinite(indexItem.answerUnweightedShare / item.answerUnweightedShare) ? (indexItem.answerUnweightedShare / item.answerUnweightedShare) : null;
                indexItem.totalUnweightedShare_Index = indexItem.totalUnweightedShare;
                indexItem.answerUnweightedShare_Index = indexItem.answerUnweightedShare;
              }
              else {
                indexItem.totalWeightedShare = isNaN(indexItem.totalWeightedShare / item.totalWeightedShare) ? null : isFinite(indexItem.totalWeightedShare / item.totalWeightedShare) ? (indexItem.totalWeightedShare / item.totalWeightedShare) : null;
                indexItem.answerWeightedShare = isNaN(indexItem.answerWeightedShare / item.answerWeightedShare) ? null : isFinite(indexItem.answerWeightedShare / item.answerWeightedShare) ? (indexItem.answerWeightedShare / item.answerWeightedShare) : null;
                indexItem.totalWeightedShare_Index = indexItem.totalWeightedShare;
                indexItem.answerWeightedShare_Index = indexItem.answerWeightedShare;
              }
            });
          }
        })
      }
    }
    onVerticalStackBarChartIconClick(chartType: string) {
      this.isData = false;
      this.isWeightedNumerator = false;
      this.isUnWeightedNumerator = false;
      this.isTableClick = false;
      this.isTableView = false;
      this.isLineChartClick = false;
      this.isVerticalStackChartClick = true;
      this.isVerticalGroupBarChartClick = false;
      this.isVerticalBarChartClick = false;
      const columndata = this.dataObject['Column'];
      this.disableStackChart = false;
      const TimePeriodinColumn = columndata.filter((parent) => {
        return parent.Data.some((child) => child.isTimePeriod === true);
      });

      this.disableLineChart = TimePeriodinColumn.length == 1 ? true : false;

      let calculatedPercentage: number | string;
      let SigNumber: number | string;
      let changePy: number | string;
      let numberType: string = 'percentage';
      const distinctColumnChildren: string[] = Array.from(
        new Set(
          this.dataObject.Column.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );

      const distinctColChildValues: string[] = distinctColumnChildren.map(
        (child) => child.replaceAll('|', ' : ')
      );

      /*if (!this.StackChart.Data) {*/
      this.StackChart.Data = [];
      this.StackChart.Properties = [];
      /* }*/

      // Clear existing data before updating for a new chart
      this.StackChart.Data.length = 0;
      let index = 0;
      // Iterate through CrossTabOutPutData
      this.CrossTabOutPutData.forEach((item: CrossTabOutputItem) => {
        let ShareNumber = this.isTotalBase
          ? this.selectedWeight == 'Unweighted'
            ? item.totalUnweightedShare
            : item.totalWeightedShare
          : this.selectedWeight == 'Unweighted'
            ? item.answerUnweightedShare
            : item.answerWeightedShare;
        calculatedPercentage =
          item.isDerived && item.CalculationType == 'mean'
            ? ShareNumber == null
              ? 'NA'
              : Number(ShareNumber.toFixed(1))
            : ShareNumber == null
              ? 'NA'
              : Number((ShareNumber * 100).toFixed(1));
        let SignificanceValue = this.isTotalBase
          ? item.Significance_Total
          : item.Significance_Answer;
        let changevsPY = this.isTotalBase
          ? item.changeVsPY_Total
          : item.changeVsPY_Answer;

        SigNumber =
          SignificanceValue == null || isNaN(SignificanceValue)
            ? 0
            : Number(SignificanceValue.toFixed(1));

        changePy =
          changevsPY == null
            ? 'NA'
            : item.isDerived && item.CalculationType == 'mean'
              ? Number(changevsPY.toFixed(1))
              : Number((changevsPY * 100).toFixed(1));

        this.StackChart.Data.push({
          // color: this.crossTabService.customColors[index].toString(),
          'Series Name': item.rowChild.replaceAll('|', ' : '),
          'Category Name': item.colChild.replaceAll('|', ' : '),
          'Rounded Value': calculatedPercentage.toString(),
          Value: calculatedPercentage.toString(),
          Label: calculatedPercentage.toString(),
          'Change value vs PY': changePy.toString(),
          Significance: this.isTotalBase
            ? item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString()
            : item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString(),
          Significance1: this.isTotalBase
            ? item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString()
            : item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString(),
          'Sample size': this.isTotalBase
            ? item.totalUnweightedBase == null
              ? '0'
              : item.totalUnweightedBase.toString()
            : item.answerUnweightedBase == null
              ? '0'
              : item.answerUnweightedBase.toString(),
        });
        index++;
      });

      // Set the title for the chart (assuming you want to use the title from the first item)
      if (this.CrossTabOutPutData.length > 0) {
        this.StackChart.title = this.CrossTabOutPutData[0].colChild;
        numberType = this.CrossTabOutPutData[0].CalculationType;
      }

      this.StackChart.Properties.push({
        plotBands: false,
        lowSample: false,
        showNegative: false,
        allowOverlap: true,
        chartStyle: '',
        showLegend: true,
        gridlines: false,
        shadow: false,
        stacking: 0,
        darkTheme: false,
        legendPosition: 'bottom',
        axisLabelOrientation: 'horizontal',
        chartTitlePosition: 'center',
        showDataLabel: true,
        datalabelStyle: this.DefaultSampleSize == 'None' ? 'datalabel' : 'change',
        axisImage: false,
        dataLabelPosition: 'center',
        dataLabelColor: 'black',
        marker: 'disable',
        showRemaining: false,
        gradientBarColor: false,
        showPattern: false,
        dataLabelPositionPPT: 'Inside Base',
        datalabelFormat: numberType == 'mean' ? '' : 'percentage',
        datalabelOrientation: 0,
        lineWidth: 0,
        gridLineDashStyle: '',
        markerLineWidth: 0,
        markerStyle: 'circle',
        title: '',
        toolTipStyle: 'default',
        ChartCat: distinctColChildValues,
      });

      //let distinctColChildValues = Array.from(new Set(this.CrossTabOutPutData.map((item: CrossTabOutputItem) => item.colChild.replaceAll("|", " : "))));
      chartType = this.isTranspose == true ? 'bar' : 'column';
      const distinctRowChildren: string[] = Array.from(
        new Set(
          this.dataObject.Row.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );
      const distinctRowChildValues: string[] = distinctRowChildren.map((child) =>
        child.replaceAll('|', ' : ')
      );
      this.StackChart.Data.sort((a, b) => {
        const indexA = distinctRowChildValues.findIndex(
          (item) => item === a['Series Name']
        );
        const indexB = distinctRowChildValues.findIndex(
          (item) => item === b['Series Name']
        );
        return indexA - indexB;
      });
      let selectedSignifcance =
        this.DefaultSampleSize == 'Pairwise' ? 'None' : this.DefaultSampleSize;
      this.crossTabService.StackChartData(
        this.StackChart,
        distinctColChildValues,
        selectedSignifcance,
        this.DefaultConfidenceLevel,
        numberType,
        chartType
      );
    }
    onTrendChartIconClick() {
      this.disableLineChart = false;
      this.displayTable = false;
      this.isTableClick = false;
      this.isData = false;
      this.isWeightedNumerator = false;
      this.isUnWeightedNumerator = false;
      this.isSampleSize = false;
      this.isWeightedLabel = false;
      this.isLineChartClick = true;
      this.isVerticalStackChartClick = false;
      this.isVerticalGroupBarChartClick = false;
      this.isVerticalBarChartClick = false;

      const rowdata = this.dataObject['Row'];
      const columndata = this.dataObject['Column'];

      const isMultiPunchRow = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) => child.isMultiPunch === Status.MultiPunchDisableChart
        );
      });
      const isMultiPunchColumn = columndata.filter((parent) => {
        return parent.Data.some(
          (child) => child.isMultiPunch === Status.MultiPunchDisableChart
        );
      });

      const isDefaultRespGrp = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) =>
            child.CalculationType === 'RespGrp' && child.isDerviedVariable == true
        );
      });

      const isMeanMetric = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) =>
            child.CalculationType.toLowerCase() === 'mean' &&
            child.isDerviedVariable == true
        );
      });

      if (rowdata.length == 1) {
        let filteredData: any = [];
        const disctinctRowValues = this.getDistinctChildValues(
          this.dataObject.Row
        );

        let distinctFirstParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          0
        );
        let distinctSecondParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          1
        );
        let distinctThirdParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          2
        );
        let distinctFourthParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          3
        );
        let distinctFifthParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          4
        );

        if (
          distinctFifthParts.length > 0 &&
          distinctFourthParts.length == 1 &&
          distinctThirdParts.length == 1 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        } else if (
          distinctFourthParts.length > 0 &&
          distinctThirdParts.length == 1 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        } else if (
          distinctFourthParts.length > 0 &&
          distinctThirdParts.length > 0 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = false;
        } else if (
          distinctThirdParts.length > 0 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        } else if (
          distinctThirdParts.length > 0 &&
          distinctSecondParts.length > 0 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = false;
        } else if (
          distinctSecondParts.length > 0 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        }

        if (this.StackFlag) {
          rowdata[0].Data.forEach((item) => {
            const pipeCount = (item.Child.match(/\|/g) || []).length;
            if (pipeCount === 1) {
              const [level1] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) => x.Level1 === level1 && x.Header == 'VariableList'
              );
            } else if (pipeCount === 2) {
              const [level1, level2] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.Header == 'VariableList'
              );
            } else if (pipeCount === 3) {
              const [level1, level2, level3] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.level3 === level3 &&
                  x.Header == 'VariableList'
              );
            } else if (pipeCount === 4) {
              const [level1, level2, level3, level4] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.level3 === level3 &&
                  x.level4 == level4 &&
                  x.Header == 'VariableList'
              );
            } else if (pipeCount === 5) {
              const [level1, level2, level3, level4, level5] =
                item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.level3 === level3 &&
                  x.level4 == level4 &&
                  x.level5 == level5 &&
                  x.Header == 'VariableList'
              );
            }
          });
        } else {
          this.StackFlag = false;
        }
        this.disableStackChart =
          isMultiPunchRow.length > 0 ||
            isMultiPunchColumn.length > 0 ||
            isDefaultRespGrp.length > 0 ||
            isMeanMetric.length > 0
            ? false
            : rowdata[0].Data.length == filteredData.length
              ? true
              : false;
      }
      let calculatedPercentage: number | string;
      let SigNumber: number | string;
      let changePy: number | string;
      let numberType: string = 'percentage';

      /* if (!this.LineChart.Data) {*/
      this.LineChart.Data = [];
      this.LineChart.Properties = [];
      //}

      this.LineChart.Data.length = 0;
      const distinctColumnChildren: string[] = Array.from(
        new Set(
          this.dataObject.Column.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );
      const distinctRowChildren: string[] = Array.from(
        new Set(
          this.dataObject.Row.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );

      const distinctColChildValues: string[] = distinctColumnChildren.map(
        (child) => child.replaceAll('|', ' : ')
      );
      const distinctRowChildValues: string[] = distinctRowChildren.map((child) =>
        child.replaceAll('|', ' : ')
      );

      this.CrossTabOutPutData.forEach((item: CrossTabOutputItem) => {
        let ShareNumber = this.isTotalBase
          ? this.selectedWeight == 'Unweighted'
            ? item.totalUnweightedShare
            : item.totalWeightedShare
          : this.selectedWeight == 'Unweighted'
            ? item.answerUnweightedShare
            : item.answerWeightedShare;
        calculatedPercentage =
          item.isDerived && item.CalculationType == 'mean'
            ? ShareNumber == null
              ? 'NA'
              : Number(ShareNumber.toFixed(1))
            : ShareNumber == null
              ? 'NA'
              : Number((ShareNumber * 100).toFixed(1));
        let SignificanceValue = this.isTotalBase
          ? item.Significance_Total
          : item.Significance_Answer;
        let changevsPY = this.isTotalBase
          ? item.changeVsPY_Total
          : item.changeVsPY_Answer;

        SigNumber =
          SignificanceValue == null || isNaN(SignificanceValue)
            ? 0
            : Number(SignificanceValue.toFixed(1));

        changePy =
          changevsPY == null
            ? 'NA'
            : item.isDerived && item.CalculationType == 'mean'
              ? Number(changevsPY.toFixed(1))
              : Number((changevsPY * 100).toFixed(1));

        this.LineChart.Data.push({
          'Series Name': item.rowChild.replaceAll('|', ' : '),
          'Category Name': item.colChild.replaceAll('|', ' : '),
          'Rounded Value': ShareNumber == null ? 'NA' : calculatedPercentage,
          XValue: '0',
          Value: calculatedPercentage,
          Label: calculatedPercentage.toString(),
          'Change value vs PY': changePy,
          Significance: this.isTotalBase
            ? item.Significance_Total == null
              ? 0
              : item.Significance_Total
            : item.Significance_Total == null
              ? 0
              : item.Significance_Total,
          'Sample Size': this.isTotalBase
            ? item.totalUnweightedBase == null
              ? 0
              : item.totalUnweightedBase
            : item.answerUnweightedBase == null
              ? 0
              : item.answerUnweightedBase,
        });
      });

      // Set the title for the chart (assuming you want to use the title from the first item)
      if (this.CrossTabOutPutData.length > 0) {
        this.LineChart.title = this.CrossTabOutPutData[0].colChild;
        numberType = this.CrossTabOutPutData[0].CalculationType;
      }

      this.LineChart.Properties.push({
        plotBands: false,
        lowSample: false,
        showNegative: false,
        allowOverlap: false,
        chartStyle: 'area',
        showLegend: true,
        gridlines: false,
        legendPosition: 'bottom',
        stacking: 0,
        darkTheme: false,
        axisLabelOrientation: 'center',
        chartTitlePosition: 'center',
        showDataLabel: true,
        datalabelFormat: numberType == 'mean' ? 'number' : 'percentage',
        dataLabelPosition: 'center',
        dataLabelColor: 'black',
        marker: 'disable',
        showRemaining: false,
        gradientBarColor: false,
        showPattern: false,
        dataLabelPositionPPT: 'center',
        datalabelStyle: this.DefaultSampleSize == 'None' ? '' : 'sigchan',
        datalabelOrientation: 0,
        axisImage: false,
        lineWidth: 2,
        gridLineDashStyle: 'Solid',
        markerLineWidth: 1,
        markerStyle: 'circle',
        title: 'LineChart',
        shadow: false,
        toolTipStyle: 'default',
        ChartCat: distinctColChildValues,
      });

      this.LineChart.Data.sort((a, b) => {
        const indexA = distinctRowChildValues.findIndex(
          (item) => item === a['Series Name']
        );
        const indexB = distinctRowChildValues.findIndex(
          (item) => item === b['Series Name']
        );
        return indexA - indexB;
      });
      let selectedSignifcance =
        this.DefaultSampleSize == 'Pairwise' ? 'None' : this.DefaultSampleSize;
      this.crossTabService.LineChartData(
        this.LineChart,
        distinctColChildValues,
        selectedSignifcance,
        this.DefaultConfidenceLevel,
        numberType,
        ''
      );
    }
    getDistinctChildValues(data: checkboxSelectionWithParent[]): string[] {
      const distinctChildValues: string[] = [];

      data.forEach((item) => {
        item.Data.forEach((checkbox) => {
          if (!distinctChildValues.includes(checkbox.Child)) {
            distinctChildValues.push(checkbox.Child);
          }
        });
      });

      return distinctChildValues;
    }
    onVerticalBarChartIconClick(
      chartType: string,
      distinctColChildValues: string[]
    ) {
      // debugger
      this.isVerticalBarChartClick = true;
      this.isVerticalGroupBarChartClick = false;
      let calculatedPercentage: number | string;
      let SigNumber: number | string;
      let changePy: number | string;
      let numberType: string = 'percentage';
      let index = 1;
      /*if (!this.BarChart.Data) {*/
      this.BarChart.Data = [];
      this.BarChart.Properties = [];
      /* }*/

      this.BarChart.Data.length = 0;

      this.CrossTabOutPutData.forEach((item: CrossTabOutputItem) => {
        let ShareNumber = this.isTotalBase
          ? this.selectedWeight == 'Unweighted'
            ? item.totalUnweightedShare
            : item.totalWeightedShare
          : this.selectedWeight == 'Unweighted'
            ? item.answerUnweightedShare
            : item.answerWeightedShare;
        calculatedPercentage =
          item.isDerived && item.CalculationType == 'mean'
            ? ShareNumber == null
              ? 'NA'
              : Number(ShareNumber.toFixed(1))
            : ShareNumber == null
              ? 'NA'
              : Number((ShareNumber * 100).toFixed(1));
        let SignificanceValue = this.isTotalBase
          ? item.Significance_Total
          : item.Significance_Answer;
        let changevsPY = this.isTotalBase
          ? item.changeVsPY_Total
          : item.changeVsPY_Answer;

        SigNumber =
          SignificanceValue == null || isNaN(SignificanceValue)
            ? 0
            : Number(SignificanceValue.toFixed(1));

        changePy =
          changevsPY == null
            ? 'NA'
            : item.isDerived && item.CalculationType == 'mean'
              ? Number(changevsPY.toFixed(1))
              : Number((changevsPY * 100).toFixed(1));

        this.BarChart.Data.push({
          'Series Name': item.rowChild.replaceAll('|', ' : '),
          'Category Name': item.colChild.replaceAll('|', ' : '),
          'Rounded Value':
            ShareNumber == null ? 'NA' : calculatedPercentage.toString(),
          Value: ShareNumber == null ? 'NA' : calculatedPercentage.toString(),
          XValue: index.toString(),
          Label: ShareNumber == null ? 'NA' : calculatedPercentage.toString(),
          'Change value vs PY': changePy.toString(),
          Significance: this.isTotalBase
            ? item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString()
            : item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString(),
          Significance2: this.isTotalBase
            ? item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString()
            : item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString(),
          'Sample size': this.isTotalBase
            ? item.totalUnweightedBase == null
              ? '0'
              : item.totalUnweightedBase.toString()
            : item.answerUnweightedBase == null
              ? '0'
              : item.answerUnweightedBase.toString(),
        });
        index++;
      });

      if (this.CrossTabOutPutData.length > 0) {
        this.BarChart.title = this.CrossTabOutPutData[0].colChild;
        numberType = this.CrossTabOutPutData[0].CalculationType;
      }
      const distinctRowChildren: string[] = Array.from(
        new Set(
          this.dataObject.Row.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );
      const distinctRowChildValues: string[] = distinctRowChildren.map((child) =>
        child.replaceAll('|', ' : ')
      );
      this.BarChart.Properties.push({
        plotBands: false,
        lowSample: false,
        showNegative: false,
        allowOverlap: false,
        chartStyle: '',
        showLegend: true,
        gridlines: false,
        legendPosition: 'bottom',
        stacking: 0,
        darkTheme: false,
        axisLabelOrientation: 'horizontal',
        chartTitlePosition: 'center',
        showDataLabel: true,
        datalabelFormat: numberType == 'mean' ? '' : 'percentage',
        dataLabelPosition: 'Center',
        dataLabelColor: 'black',
        marker: 'disable',
        showRemaining: false,
        gradientBarColor: false,
        showPattern: false,
        dataLabelPositionPPT: 'center',
        datalabelStyle: this.DefaultSampleSize == 'None' ? 'default' : 'change',
        datalabelOrientation: 0,
        axisImage: false,
        lineWidth: 0,
        gridLineDashStyle: '',
        markerLineWidth: 0,
        markerStyle: 'circle',
        title: '',
        shadow: false,
        toolTipStyle: 'default',
        ChartCat: distinctColChildValues,
      });

      chartType = this.isTranspose == true ? 'bar' : 'column';

      this.BarChart.Data.sort((a, b) => {
        const indexA = distinctRowChildValues.findIndex(
          (item) => item === a['Series Name']
        );
        const indexB = distinctRowChildValues.findIndex(
          (item) => item === b['Series Name']
        );
        return indexA - indexB;
      });
      let selectedSignifcance =
        this.DefaultSampleSize == 'Pairwise' ? 'None' : this.DefaultSampleSize;
      this.crossTabService.ChartData(
        this.BarChart,
        distinctColChildValues,
        selectedSignifcance,
        this.DefaultConfidenceLevel,
        numberType,
        chartType
      );
    }
    onGroupVerticalChartClick(
      chartType: string,
      distinctColChildValues: string[]
    ) {
      this.isVerticalBarChartClick = false;
      this.isVerticalGroupBarChartClick = true;
      let calculatedPercentage: number | string;
      let SigNumber: number | string;
      let changePy: number | string;
      let numberType: string = 'percentage';

      /* if (!this.BarChart.Data || !this.BarChart.Properties) {*/
      this.BarChart.Data = [];
      this.BarChart.Properties = [];
      /*  }*/

      this.BarChart.Data.length = 0;

      this.CrossTabOutPutData.forEach((item: CrossTabOutputItem) => {
        let ShareNumber = this.isTotalBase
          ? this.selectedWeight == 'Unweighted'
            ? item.totalUnweightedShare
            : item.totalWeightedShare
          : this.selectedWeight == 'Unweighted'
            ? item.answerUnweightedShare
            : item.answerWeightedShare;
        calculatedPercentage =
          item.isDerived && item.CalculationType == 'mean'
            ? ShareNumber == null
              ? 'NA'
              : Number(ShareNumber.toFixed(1))
            : ShareNumber == null
              ? 'NA'
              : Number((ShareNumber * 100).toFixed(1));
        let SignificanceValue = this.isTotalBase
          ? item.Significance_Total
          : item.Significance_Answer;
        let changevsPY = this.isTotalBase
          ? item.changeVsPY_Total
          : item.changeVsPY_Answer;

        SigNumber =
          SignificanceValue == null || isNaN(SignificanceValue)
            ? 0
            : Number(SignificanceValue.toFixed(1));

        changePy =
          changevsPY == null
            ? 'NA'
            : item.isDerived && item.CalculationType == 'mean'
              ? Number(changevsPY.toFixed(1))
              : Number((changevsPY * 100).toFixed(1));

        this.BarChart.Data.push({
          'Series Name': item.rowChild.replaceAll('|', ' : '),
          'Category Name': item.colChild.replaceAll('|', ' : '),
          'Rounded Value':
            ShareNumber == null ? 'NA' : calculatedPercentage.toString(),
          Value: ShareNumber == null ? 'NA' : calculatedPercentage.toString(),
          XValue: '1',
          Label: ShareNumber == null ? 'NA' : calculatedPercentage.toString(),
          'Change value vs PY': changePy.toString(),
          Significance: this.isTotalBase
            ? item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString()
            : item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString(),
          Significance2: this.isTotalBase
            ? item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString()
            : item.Significance_Total == null
              ? '0'
              : item.Significance_Total.toString(),
          'Sample size': this.isTotalBase
            ? item.totalUnweightedBase == null
              ? '0'
              : item.totalUnweightedBase.toString()
            : item.answerUnweightedBase == null
              ? '0'
              : item.answerUnweightedBase.toString(),
        });
      });

      // Set the title for the chart (assuming you want to use the title from the first item)
      if (this.CrossTabOutPutData.length > 0) {
        this.BarChart.title = this.CrossTabOutPutData[0].colChild;
        numberType = this.CrossTabOutPutData[0].CalculationType;
      }
      const distinctRowChildren: string[] = Array.from(
        new Set(
          this.dataObject.Row.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );
      const distinctRowChildValues: string[] = distinctRowChildren.map((child) =>
        child.replaceAll('|', ' : ')
      );

      this.BarChart.Properties.push({
        plotBands: false,
        lowSample: false,
        showNegative: false,
        allowOverlap: false,
        chartStyle: '',
        showLegend: true,
        gridlines: false,
        shadow: false,
        stacking: 0,
        darkTheme: false,
        legendPosition: 'bottom',
        axisLabelOrientation: 'horizontal',
        chartTitlePosition: 'center',
        showDataLabel: true,
        datalabelStyle: this.DefaultSampleSize == 'None' ? 'datalabel' : 'change',
        axisImage: false,
        dataLabelPosition: 'center',
        dataLabelColor: 'black',
        marker: 'disable',
        showRemaining: false,
        gradientBarColor: false,
        showPattern: false,
        dataLabelPositionPPT: 'Center',
        datalabelFormat: numberType == 'mean' ? '' : 'percentage',
        datalabelOrientation: 0,
        lineWidth: 0,
        gridLineDashStyle: '',
        markerLineWidth: 0,
        markerStyle: 'circle',
        title: '',
        toolTipStyle: 'default',
        ChartCat: distinctColChildValues,
      });

      //let distinctColChildValues = Array.from(new Set(this.CrossTabOutPutData.map((item: CrossTabOutputItem) => item.colChild.replaceAll("|", " : "))));
      chartType = this.isTranspose == true ? 'bar' : 'column';

      this.BarChart.Data.sort((a, b) => {
        const indexA = distinctRowChildValues.findIndex(
          (item) => item === a['Series Name']
        );
        const indexB = distinctRowChildValues.findIndex(
          (item) => item === b['Series Name']
        );
        return indexA - indexB;
      });
      let selectedSignifcance =
        this.DefaultSampleSize == 'Pairwise' ? 'None' : this.DefaultSampleSize;
      this.crossTabService.GrpChartData(
        this.BarChart,
        distinctColChildValues,
        selectedSignifcance,
        this.DefaultConfidenceLevel,
        numberType,
        chartType
      );
    }


    VerticalChartClick(chartType: string) {
      this.displayTable = false;
      this.isTableClick = false;
      this.isData = false;
      this.isSampleSize = false;
      this.isWeightedNumerator = false;
      this.isUnWeightedNumerator = false;
      this.isWeightedLabel = false;
      this.isLineChartClick = false;
      this.isVerticalStackChartClick = false;
      const rowdata = this.dataObject['Row'];
      const columndata = this.dataObject['Column'];

      const isMultiPunchRow = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) => child.isMultiPunch === Status.MultiPunchDisableChart
        );
      });
      const isMultiPunchColumn = columndata.filter((parent) => {
        return parent.Data.some(
          (child) => child.isMultiPunch === Status.MultiPunchDisableChart
        );
      });

      const isDefaultRespGrp = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) =>
            child.CalculationType === 'RespGrp' && child.isDerviedVariable == true
        );
      });

      const isMeanMetric = rowdata.filter((parent) => {
        return parent.Data.some(
          (child) =>
            child.CalculationType.toLowerCase() === 'mean' &&
            child.isDerviedVariable == true
        );
      });

      if (rowdata.length == 1) {
        let filteredData: any = [];
        const disctinctRowValues = this.getDistinctChildValues(
          this.dataObject.Row
        );

        let distinctFirstParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          0
        );
        let distinctSecondParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          1
        );
        let distinctThirdParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          2
        );
        let distinctFourthParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          3
        );
        let distinctFifthParts = this.getDistinctValuesByPipeCount(
          disctinctRowValues,
          4
        );

        if (
          distinctFifthParts.length > 0 &&
          distinctFourthParts.length == 1 &&
          distinctThirdParts.length == 1 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        } else if (
          distinctFourthParts.length > 0 &&
          distinctThirdParts.length == 1 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        } else if (
          distinctFourthParts.length > 0 &&
          distinctThirdParts.length > 0 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = false;
        } else if (
          distinctThirdParts.length > 0 &&
          distinctSecondParts.length == 1 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        } else if (
          distinctThirdParts.length > 0 &&
          distinctSecondParts.length > 0 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = false;
        } else if (
          distinctSecondParts.length > 0 &&
          distinctFirstParts.length == 1
        ) {
          this.StackFlag = true;
        }

        if (this.StackFlag) {
          rowdata[0].Data.forEach((item) => {
            const pipeCount = (item.Child.match(/\|/g) || []).length;
            if (pipeCount === 1) {
              const [level1] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) => x.Level1 === level1 && x.Header == 'VariableList'
              );
            } else if (pipeCount === 2) {
              const [level1, level2] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.Header == 'VariableList'
              );
            } else if (pipeCount === 3) {
              const [level1, level2, level3] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.level3 === level3 &&
                  x.Header == 'VariableList'
              );
            } else if (pipeCount === 4) {
              const [level1, level2, level3, level4] = item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.level3 === level3 &&
                  x.level4 == level4 &&
                  x.Header == 'VariableList'
              );
            } else if (pipeCount === 5) {
              const [level1, level2, level3, level4, level5] =
                item.Child.split('|');
              filteredData = this.variableTabData.filter(
                (x: any) =>
                  x.Level1 === level1 &&
                  x.Level2 === level2 &&
                  x.level3 === level3 &&
                  x.level4 == level4 &&
                  x.level5 == level5 &&
                  x.Header == 'VariableList'
              );
            }
          });
        } else {
          this.StackFlag = false;
        }
        this.disableStackChart =
          isMultiPunchRow.length > 0 ||
            isMultiPunchColumn.length > 0 ||
            isDefaultRespGrp.length > 0 ||
            isMeanMetric.length > 0
            ? false
            : rowdata[0].Data.length == filteredData.length
              ? true
              : false;
      }

      const rowDataLength: number =
        rowdata.length > 0 ? rowdata[0].Data.length : 0;

      //if (this.isTranspose) {
      //  this.handleOutputdataObjectOnTransposeSelect();
      //}
      //else {
      //  let tempRow = [...this.OutputdataObject.Row];
      //  let tempColumn = [...this.OutputdataObject.Column];

      //  // Swap rows and columns to transpose the data
      //  this.OutputdataObject.Row = [...tempRow];
      //  this.OutputdataObject.Column = [...tempColumn];
      //}

      const distinctColumnChildren: string[] = Array.from(
        new Set(
          this.dataObject.Column.flatMap((parent) =>
            parent.Data.map((selection) => selection.Child)
          )
        )
      );

      const distinctColChildValues: string[] = distinctColumnChildren.map(
        (child) => child.replaceAll('|', ' : ')
      );

      const TimePeriodinColumn = columndata.filter((parent) => {
        return parent.Data.some((child) => child.isTimePeriod === true);
      });

      this.disableLineChart = TimePeriodinColumn.length == 1 ? true : false;

      if (rowDataLength == 1) {
        this.onVerticalBarChartIconClick(chartType, distinctColChildValues);
      } else {
        this.onGroupVerticalChartClick(chartType, distinctColChildValues);
      }
    }
    getDistinctValuesByPipeCount(data: string[], pipeCount: number): string[] {
      const distinctValues = new Set<string>();
      data.forEach((item) => {
        const total = (item.match(/\|/g) || []).length;
        if (pipeCount <= total) {
          const parts = item.split('|')[pipeCount];
          distinctValues.add(parts.trim());
        }
      });
      return Array.from(distinctValues);
    }
    handleOutputdataObjectOnTransposeSelect() {
      if (
        this.OutputdataObject != null &&
        (this.OutputdataObject['Row'].length > 0 ||
          this.OutputdataObject['Column'].length > 0)
      ) {
        // Create copies of rows and columns arrays to preserve the original data
        let tempRow = [...this.OutputdataObject.Row];
        let tempColumn = [...this.OutputdataObject.Column];

        // Swap rows and columns to transpose the data
        this.OutputdataObject.Row = [...tempColumn];
        this.OutputdataObject.Column = [...tempRow];
      }
    }


    openInsights() {
      this.apiService
        .getChatBotInsightsData(
          this.CrossTabOutPutData,
          this.selected_study,
          this.selected_client,
          this.isTotalBaseFromPrompt
        )
        .subscribe((result: string) => {
          // Response is valid
          // this.errorPopup(result, "Insights for the Generated Ouptput data")
          // let insightsData = JSON.parse(result.Data);
          // this.InsightsData = insightsData;
          this.isInsightspopupOpen = true;
          // this.insightsVal = result;
        
        const insightData = result.split('- ').map(item => item.trim()).filter(item => item.length > 0);
        
        console.log(insightData);
        this.insightsVal = insightData;
          //hide the loader
          // this.Loader.hideLoader();
        }
        );
    }

  }

