export const environment = {
  baseApiUrl: 'https://kapdashboardapidev.azurewebsites.net/',
  redirectUrl: 'https://dev-dataexplorer.kantar.com/landing',
  kantarClientID: '5bfc7a38-c638-4049-8f0e-aab51827893c',
  kantarApiClientID: 'eb6c005a-98e4-4375-b3ce-1c11bda51c4b',
  kantarAuthorityID: 'https://login.microsoftonline.com/1e355c04-e0a4-42ed-8e2d-7351591f0ef1',
  UserGuideUrl: 'https://elearning.easygenerator.com/bcd568f0-29e3-43fb-a042-238bfbb04e6e/',
  UserGuideSnapshotUrl: 'https://elearning.easygenerator.com/4168a9e6-5f42-4a18-80cd-5aeab0add565/',
  logoutURL: 'https://dev-dataexplorer.kantar.com/signout-oidc',
  sessionTimeOut: '20',
  env: 'dev',
  showUploadModule: 'true',
};
