import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SharedPopupService } from 'src/app/common/services/shared-popup.service';
import { COMMON_CONSTANTS } from '../../constants/common-constants';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { AlertMessageModel, results } from 'src/app/common/models/common.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CrosstabService } from '../../services/crosstab.service';
import { LoaderService } from '../../services/loader.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import {
  DataArray,
  DataObject,
  ExcelDataModel,
  ChartDetails,
  documentRepo
} from 'src/app/common/models/crosstab.model'
import { SnapshotService } from 'src/app/modules/snapshot/Services/snapshot.service';


// Now you can use lodash functions like _.filter


interface Client {
  ClientName: string;
  showStudies: boolean;
  UniqueStudies: string[];
}

interface docStorage {
  foldername: string;
  showFiles: boolean;
  filename: string[];
}

@Component({
  selector: 'app-shared-popup',
  templateUrl: './shared-popup.component.html',
  styleUrls: ['./shared-popup.component.css'],
})
export class SharedPopupComponent {
  $unSubscribeSubmitData9!: Subscription;
  @Output() isSavePopUpVisible = new EventEmitter<any>();
  //custom group
  @Output() isCustomGroupSavePopUpVisible = new EventEmitter<any>();
  @Output() isCustomGroupVisible = new EventEmitter<any>();
  @Output() isCustomGroupShareVisible = new EventEmitter<any>();
  //custom calculation
  @Output() isCustomCalculationSavePopUpVisible = new EventEmitter<any>();
  @Output() isCustomCalculationVisible = new EventEmitter<any>();
  @Output() isCustomCalculationShareVisible = new EventEmitter<any>();

  @Output() isShareSelectionVisible = new EventEmitter<any>();
  @Output() SaveSelectionClick = new EventEmitter<any>();
  @Output() ShareSelectionClick = new EventEmitter<any>();
  @Output() SaveCustomSelectionClick = new EventEmitter<any>();
  @Output() SaveCustomCalculationSelectionClick = new EventEmitter<any>();
  @Output() bindSelectedScenario = new EventEmitter<any>();
  @Output() isLoadClickOutput = new EventEmitter<any>();
  @Output() isDocClick = new EventEmitter<any>();
  @Output() isDownloadClick = new EventEmitter<any>();

  @Output() isShowDialog = new EventEmitter<any>();
  @Output() deleteMessage = new EventEmitter<any>();
  //@Output() shareMessage = new EventEmitter<any>();
  @Output() currentSavedScenarioData = new EventEmitter<any>();

  @Output() bindCustomGroupFlags = new EventEmitter<any>();
  @Output() bindCustomCalculationFlags = new EventEmitter<any>();

  @Output() bindNumeralSave = new EventEmitter<any>();

  @Output() CustomMetric = new EventEmitter<any>();

  // @Output() bindUpdateCustomGroupClick = new EventEmitter<any>();
  @Input() selected_client_name: string = '';
  @Input() selected_study_name: string = '';
  @Input() isCustomCalculation: boolean = false;
  @Input() isCustomGroups: boolean = false;
  @Input() isNumeral: boolean = false;
  @Input() isSaveSelectionClick: boolean = false;
  @Input() isSaveCustomGroupSelectionClick = false;
  @Input() isSaveCustomCalculationSelectionClick = false;
  @Input() isLoadClick: boolean = false;
  @Input() isCustomGroupClick: boolean = false;
  @Input() isCustomCalculationClick: boolean = false;
  @Input() showLoaderPopUp: boolean = true;
  @Input() showLoaderSnapShotPopup: boolean = false;
  @Input() isSuperAdmin: boolean = false;
  @Input() isChangeOfEntryPointConfigured: boolean = false;
  @Input() isDocumentRepositoryClick: boolean = false;
  @Input() data!: Client[];
  //@Input() loadSavedScenarioData: any;

  @Input() loadUserDefinedSavedScenarioData: any;
  @Input() loadSharedWithMeSavedScenarioData: any;
  @Input() docStorageData: any;
  //@Input() loadCustomGroupData: any;

  //custom group
  @Input() loadCustomGroupUserDefinedData: any;
  @Input() loadCustomGroupSharedWithMeData: any;
  @Input() customGroupShareUserList: any;

  //custom calculation
  @Input() loadCustomCalculationUserDefinedData: any;
  @Input() loadCustomCalculationSharedWithMeData: any;
  @Input() customCalculationShareUserList: any;

  @Input() shareSelectionUserList: any;
  @Input() selectedCustomGroupObj: any;
  @Input() selectedCustomCalculationObj: any;
  @Input() isShareCustomGroupPopupClick: boolean = false;
  @Input() isShareCustomCalculationPopupClick: boolean = false;
  @Input() isShareSelectionClick: boolean = false;
  @Input() isShareMetric: boolean = false;
  @Input() isMeanMetric: boolean = false;
  @Input() selectedEmailsToShare: string[] = [];
  @Input() selectedLoadSelectionObj: any;
  @Input() isCustomDelete: boolean = false;
  selectedFolder: string = '';
  infoMessage: string = '';
  searchText: string = '';
  selectedFile: string = '';
  updateSummary: any = '';
  numeralValue: string = '';

  //custom group
  selectedCustomGroupName: any = '';
  customGroupId: string = '';
  selectedCustomGroupName_Bucket: string = ''; //user defined or shared

  //custom calculation
  selectedCustomCalculationName: any = '';
  customCalculationId: string = '';
  selectedCustomCalculationName_Bucket: string = ''; //user defined or shared

  selectedSelectionName: any = '';
  isUserDefinedListVisible: boolean = false;
  isSharedWithMeListVisible: boolean = false;
  isFolderExpand: boolean = false;
  customGroupHeaderText: string = 'Variable Summary';
  customCalculationHeaderText: string = 'Variable Summary';

  // emails: any = COMMON_CONSTANTS.emailID;
  hideConfiguration: boolean = false;
  selectedStudy: string = '';
  selectedClient: string = '';
  filteredData: any[] = [];
  isNavigateButtonDisabled: boolean = true;
  isConfigurationButtonDisabled: boolean = true;
  isAddClientButtonDisabled: boolean = false;
  isStudySelected: boolean = false;
  selectedClientCode: string = '';
  popUpData: any;
  SaveSelection: string = '';
  displayTable: boolean = true;

  headerTitle: string = 'Save Selection';
  StaticText: string = 'Please enter the selection name (Max 24 characters)';

  isNavigationShown: boolean = false;
  isConfigurationShown: boolean = false;
  isUserManagementShown: boolean = false;
  isAddClientStudyShown: boolean = false;
  isUserListToShareDropdownOpen: boolean = false;
  initialSelectedEmailsToShare: any;
  @Input() isDuplicateCustomGroup: boolean = false;
  @Input() isDuplicateCustomCalculation: boolean = false;
  @Input() selectionName: string = '';
  filteredEmailsToShare: string[] = [];
  @Output() closePopUp = new EventEmitter<string>();
  userDefinedSelectionQuery: string = '';
  sharedWithMeSelectionQuery: string = '';

  @Output() dataEvent=new EventEmitter<string>;
@Input() flag=false;
@Input() message='';

  columnConfig: any[] = [
    {
      name: 'Selection',
      userDefined_width: '61.6%',
      userDefined_width_change: '80%',
      imageName: '',
      sharedWithMe_width: '38%',
      sharedWithMe_width_change: '55%',
    },
    {
      name: 'Load',
      userDefined_width: '6%',
      userDefined_width_change: '5%',
      imageClass: 'LoadImage',
      sharedWithMe_width: '6%',
      sharedWithMe_width_change: '5%',
    },
    {
      name: 'Info',
      userDefined_width: '6%',
      userDefined_width_change: '5%',
      imageClass: 'InfoImage',
      sharedWithMe_width: '6%',
      sharedWithMe_width_change: '5%',
    },
    {
      name: 'Delete',
      userDefined_width: '6%',
      userDefined_width_change: '5%',
      imageClass: 'DeleteImage',
      sharedWithMe_width: '6%',
      sharedWithMe_width_change: '5%',
    },
    {
      name: 'Change Entry Point',
      userDefined_width: '14%',
      imageClass: '',
      sharedWithMe_width: '14%',
    },
    {
      name: 'Share',
      userDefined_width: '6%',
      userDefined_width_change: '5%',
      imageClass: 'ShareImage',
      sharedWithMe_width: '6%',
      sharedWithMe_width_change: '5%',
    },
  ];
  moduleName: string = "";
  $unSubscribeSubmitData6: any;
  $unSubscribeSubmitData8: any;
  constructor(
    private msalService: MsalService,
    private router: Router,
    public SharedPopupService: SharedPopupService,
    private _authenticateService: AuthenticationService,
    private _alertService: AlertMessageService,
    private sanitizer: DomSanitizer,
    private crossTabService: CrosstabService,
    private _configService: SnapshotService,
    private Loader: LoaderService
  ) { }

  ngOnInit() {
    this.initialSelectedEmailsToShare = [...this.selectedEmailsToShare];
    if (!this.isLoadClick)
      this.getPopUpeData();
    this.headerTitle = this.isSaveCustomCalculationSelectionClick
      ? 'Save Mean Variable'
      : this.isSaveCustomGroupSelectionClick
        ? 'Save Percentage Variable'
        : this.headerTitle;
    this.StaticText = this.isSaveCustomCalculationSelectionClick
      ? 'Please enter the Mean Variable name (Max 50 characters)'
      : this.isSaveCustomGroupSelectionClick
        ? 'Please enter the Percentage Variable name (Max 50 characters)'
        : this.StaticText;
    
        if(this.selectionName!=""){
          this.SaveSelection=this.selectionName;
        }
  }

  ngOnChanges(changes: SimpleChanges): void { }

  getPopUpeData(): void {
    this.SharedPopupService.setPopUpTabData$.subscribe((data) => {
      if (data != null && data.data.length > 0) {
        //data=this.restrictClientsBasedOnEmailID(data);
        this.popUpData = data.data;
        this.moduleName = data.moduleName
        this.filteredData = data.data;
        this._configService.SnapshotList = data.data;
        this.ShowAccessLevel();
      }
    });
  }
  ShowAccessLevel() {
    let access = this._authenticateService.GetAccess();
    if (access === this._authenticateService.level1) {
      this.isAddClientStudyShown = true;
      this.isUserManagementShown = true;
      this.isConfigurationShown = true;
    }
    if (access === this._authenticateService.level2) {
      this.isUserManagementShown = true;
    }
  }

  // restrictClientsBasedOnEmailID(data: any) {
  //   if (data != null && data.length > 0) {
  //     const accounts = this.msalService.instance.getAllAccounts();
  //     if (accounts != null && accounts.length > 0) {
  //       let userEmail = accounts[0].username;
  //       if (this.emails.indexOf(userEmail.toLowerCase()) == -1) {
  //         this.hideConfiguration = true;
  //         return data.filter((x: any) => {
  //           return x.ClientName == "Demo"
  //         })
  //       }
  //     }
  //   }
  //   return data;
  // }
  closeClick() {
    this.closePopUp.emit('false');
  }

  selectStudy(clientName: string, study: string, ClientCode: string) {
    /*if (this.isStudySelected == false) {*/

    this.selectedClient = clientName;
    this.selectedStudy = study;
    this.isNavigateButtonDisabled = false;
    this.isConfigurationButtonDisabled = false;
    this.isAddClientButtonDisabled = true;
    this.selectedClientCode = clientName;
    // this.isStudySelected = true;
    //}
    //else {
    //  this.selectedClient = "";
    //  this.selectedStudy = "";
    //  this.isNavigateButtonDisabled = true;
    //  this.isConfigurationButtonDisabled = true;
    //  this.isAddClientButtonDisabled = false;
    //  this.isStudySelected = false;
    //}
  }
  isSelected(clientName: string, study: string): boolean {
    // if (this.isStudySelected == true) {
    return this.selectedStudy === study;
    //}
    //else {
    //  return false;
    //}
  }
  filterData() {
    this.filteredData = this.popUpData.filter((client: Client) =>
      client.ClientName.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  toggleStudies(clickedClient: any): void {
    // Close other clients if they are open
    this.popUpData.forEach((client: Client) => {
      if (client !== clickedClient) {
        client.showStudies = false;
      }
    });

    // Toggle the studies for the clicked client
    clickedClient.showStudies = !clickedClient.showStudies;
  }

  filterClients() {
    const lowerCaseSearchText = this.searchText.toLowerCase();
    return this.popUpData.filter((client: Client) =>
      client.ClientName.toLowerCase().includes(lowerCaseSearchText)
    );
  }
  addClientStudy() {
    this.router.navigateByUrl('configuration');
  }
  configuration() {
    let data: { client_name: string; study: string } = {
      client_name: this.selectedClient,
      study: this.selectedStudy,
    };
    this.router.navigateByUrl('configuration', { state: data });
  }
  configurationSnapshot() {
    let data: { client_name: string; study: string; configdata: any } = {
      client_name: this.selectedClient,
      study: this.selectedStudy,
      configdata: this.filteredData,
    };
    this.router.navigateByUrl('Snapshot/configuaration', { state: data });
  }
  navigate() {
    if (this.selectedClientCode === '' || this.selectedStudy === '') {
      this.router.navigateByUrl('landing');
    } else {
      let data: { client_code: string; study: string } = {
        client_code: this.selectedClientCode,
        study: this.selectedStudy,
      };
      if (COMMON_CONSTANTS.BrandAnalysisModuleNameId == this.moduleName)
        this.router.navigateByUrl('BrandAnalysis', { state: data });
      else if (COMMON_CONSTANTS.ARKName == this.moduleName)
        this.router.navigateByUrl('chatbotexpand', { state: data });
      else this.router.navigateByUrl('Crosstab', { state: data });
    }
  }

  navigateSnapshot() {
    if (this.selectedClientCode === '' || this.selectedStudy === '') {
      this.router.navigateByUrl('landing');
    }
    else {
      this._configService
        .getClientStudyData({
          client_code: this.selectedClientCode,
          snapshot_name: this.selectedStudy,
        })
        .subscribe(async (b) => {
          var x = JSON.parse(b.body.Data);
          if (
            x != null &&
            x != undefined &&
            x != ''
          ) {
            let mainStudy = x.study_list[0].study_name;
            // let data: { client_code: string; study: string } = {
            //   client_code: this.selectedClientCode,
            //   study: this.selectedStudy,
            // };

            let data: { client_code: string; study: string; main_study: string, snapList: any } = {
              client_code: this.selectedClientCode,
              study: this.selectedStudy,
              main_study: mainStudy,
              snapList: this._configService.SnapshotList.filter((a:any)=> a.ClientName == this.selectedClientCode)
            };

            if (x.product == "Standard") {
              this.router.navigateByUrl('Snapshot/snapshot-output', { state: data });
            }
            else if (x.product == "Custom") {
              this.router.navigateByUrl('Snapshot/custom-snapshot-output', { state: data });
            }

          }
          else {
            this.router.navigateByUrl('landing');
          }
        })
    }

  }
  OnUserManagementClick() {
    this.router.navigateByUrl('user-management');
  }
  closeSavePopUp() {
    if (this.isSaveCustomCalculationSelectionClick) {
      this.isSaveCustomCalculationSelectionClick = false;
      this.showLoaderPopUp = false;
      this.SaveSelection = '';
      this.isCustomCalculationSavePopUpVisible.emit({
        isClose: false,
      });
    } else if (this.isSaveCustomGroupSelectionClick) {
      this.isSaveCustomGroupSelectionClick = false;
      this.showLoaderPopUp = false;
      this.SaveSelection = '';
      this.isCustomGroupSavePopUpVisible.emit({
        isClose: false,
      });
    } else {
      this.isSaveSelectionClick = false;
      this.showLoaderPopUp = false;
      this.SaveSelection = '';
      this.isSavePopUpVisible.emit({
        isClose: false,
      });
      this.isNumeral = false;
    }
  }

  closeCustomGroupPopUp() {
    if (this.isShareMetric) {
      this.isCustomGroupVisible.emit({
        isClose: false,
        isShareMetric: false,
        isMeanMetric: false,
      });
    }
    else {
      this.isCustomCalculationVisible.emit({
        isClose: false,
        isShareMetric: false,
        isMeanMetric: false,
      });
    }
  }

  customMetrics(customMetric: string) {
    this.updateSummary = [];
    if (customMetric == "") {
      this.isShareMetric = true;
      this.isMeanMetric = false;
    }
    else {
      this.isShareMetric = false;
      this.isMeanMetric = true;
    }

    this.CustomMetric.emit({
      isCustomMetric: customMetric
    });
  }


  closeCustomGroupSharePopUp(isShared: boolean = false) {
    this.isCustomGroupShareVisible.emit({
      isClose: false,
      isShared: isShared
    });
  }

  closeCustomCalculationSharePopUp(isShared: boolean = false) {
    this.isCustomCalculationShareVisible.emit({
      isClose: false,
      isShared: isShared
    });
  }

  closeShareSelectionPopUp(isShared: boolean = false) {
    this.isShareSelectionVisible.emit({
      isClose: false,
      isShared: isShared
    });
  }

  closeLoadPopUp() {
    this.isLoadClickOutput.emit({
      isLoadClickOutput: false,
    });
    this.isLoadClick = false;
    this.showLoaderPopUp = false;
  }

  closeDocumentRepo() {
    this.isDocumentRepositoryClick = false;

    this.docStorageData.forEach((client: docStorage) => {
      client.showFiles = false;
    });
    this.isDocClick.emit({
      isDocumentRepositoryClick: false,
    });
  }

  onNumeralSubmit() {
    if (this.numeralValue == '') {
      this.errorPopup('Numeral number cannot be empty.');
    } else {
      this.bindNumeralSave.emit({
        numeralValue: this.numeralValue,
      });
      this.closeSavePopUp();
    }
  }

  onOkBtnClick() {
    if (this.SaveSelection == '') {
      this.errorPopup('Selection name cannot be empty.');
    } else {
      this.SaveSelectionClick.emit({
        isValidationPass: true,
        selectionName: this.SaveSelection,
        isShareSelection: false, //this is for save selection
        selectionType: 'userDefined', //only user defined can be saved
      });
    }
  }

  onCustomCalculationOkBtnClick() {
    if (this.SaveSelection == '') {
      this.errorPopup('Percentage Variable Selection name cannot be empty.');
    } else {
      this.SaveCustomCalculationSelectionClick.emit({
        isValidationPass: true,
        selectionName: this.SaveSelection,
        isUpdate: false,
        isDuplicate: this.isDuplicateCustomCalculation,
      });

      this.closePopUp.emit('false');
    }
  }
  onCustomOkBtnClick() {
    if (this.SaveSelection == '') {
      this.errorPopup('Percentage Variable Selection name cannot be empty.');
    } else {
      this.SaveCustomSelectionClick.emit({
        isValidationPass: true,
        selectionName: this.SaveSelection,
        isUpdate: false,
        isDuplicate: this.isDuplicateCustomGroup,
      });

      this.closePopUp.emit('false');
    }
  }

  onCreateCustomCalcBtn() {
    this.selectedCustomCalculationName_Bucket = '';

    this.bindCustomCalculationFlags.emit({
      isCreateCustomGroup: true,
      displayMainComponent: false,
      isCreateCustomCalculation: true,
      isUpdateCustomCalculation: false,
      isDeleteCustomCalculation: false,
      isDuplicateCustomCalculation: false,
      isShareCustomCalculation: false,
      isMeanMetric: true,
      isShareMetric: false,
    });
    this.closePopUp.emit('false');

    //this.displayMainComponent=false;
  }

  onCreateBtn() {
    this.selectedCustomGroupName_Bucket = '';

    this.bindCustomGroupFlags.emit({
      displayMainComponent: false,
      isCreateCustomGroup: true,
      isUpdateCustomGroup: false,
      isDeleteCustomGroup: false,
      isDuplicateCustomGroup: false,
      isShareCustomGroup: false,
      isShareMetric: true,
      isMeanMetric: false,
    });
    this.closePopUp.emit('false');

    //this.displayMainComponent=false;
  }

  onDeleteBtn() {

    this.updateSummary = [];
    this.bindCustomGroupFlags.emit({
      displayMainComponent: true,
      isUpdateCustomGroup: false,
      isCreateCustomGroup: false,
      isDeleteCustomGroup: true,
      isDuplicateCustomGroup: false,
      isShareCustomGroup: false,
      selectedCustomGroupName: this.selectedCustomGroupName,
      selectedCustomGroupName_Bucket: this.selectedCustomGroupName_Bucket,
      customGroupId: this.customGroupId,
      isShareMetric: true,
      isMeanMetric: false
    });

  }
  onDeleteCustomCalcBtn() {
    this.updateSummary = [];
    this.bindCustomCalculationFlags.emit({
      displayMainComponent: true,
      isUpdateCustomCalculation: false,
      isCreateCustomCalculation: false,
      isDeleteCustomCalculation: true,
      isDuplicateCustomCalculation: false,
      isShareCustomCalculation: false,
      selectedCustomCalculationName: this.selectedCustomCalculationName,
      selectedCustomCalculationName_Bucket:
        this.selectedCustomCalculationName_Bucket,
      customCalculationId: this.customCalculationId,
      isShareMetric: false,
      isMeanMetric: true
    });
  }

  onDuplicateBtn() {
    this.bindCustomGroupFlags.emit({
      displayMainComponent: false,
      isUpdateCustomGroup: false,
      isCreateCustomGroup: false,
      isDeleteCustomGroup: false,
      isDuplicateCustomGroup: true,
      isShareCustomGroup: false,
      selectedCustomGroupName: this.selectedCustomGroupName,
      selectedCustomGroupName_Bucket: this.selectedCustomGroupName_Bucket,
      customGroupId: this.customGroupId,
      isShareMetric: true,
      isMeanMetric: false
    });
  }
  onDuplicateCustomCalcBtn() {
    this.bindCustomCalculationFlags.emit({
      displayMainComponent: false,
      isUpdateCustomCalculation: false,
      isCreateCustomCalculation: false,
      isDeleteCustomCalculation: false,
      isDuplicateCustomCalculation: true,
      isShareCustomCalculation: false,
      selectedCustomCalculationName: this.selectedCustomCalculationName,
      selectedCustomCalculationName_Bucket:
        this.selectedCustomCalculationName_Bucket,
      customCalculationId: this.customCalculationId,
      isShareMetric: false,
      isMeanMetric: true
    });
  }

  onShareBtn() {
    //this.isShareCustomGroupPopupClick = true;

    //this.customGroupShareUserList = this.selectedCustomGroupObj.share_with;
    this.bindCustomGroupFlags.emit({
      displayMainComponent: true,
      isUpdateCustomGroup: false,
      isCreateCustomGroup: false,
      isDeleteCustomGroup: false,
      isDuplicateCustomGroup: false,
      isShareCustomGroup: true,
      selectedCustomGroupName: this.selectedCustomGroupName,
      selectedCustomGroupName_Bucket: this.selectedCustomGroupName_Bucket,
      customGroupId: this.customGroupId,
      isShareMetric: true,
      isMeanMetric: false
    });
  }

  onShareCustomCalcBtn() {
    //this.isShareCustomGroupPopupClick = true;

    //this.customGroupShareUserList = this.selectedCustomGroupObj.share_with;
    this.bindCustomCalculationFlags.emit({
      displayMainComponent: true,
      isUpdateCustomCalculation: false,
      isCreateCustomCalculation: false,
      isDeleteCustomCalculation: false,
      isDuplicateCustomCalculation: false,
      isShareCustomCalculation: true,
      selectedCustomCalculationName: this.selectedCustomCalculationName,
      selectedCustomCalculationName_Bucket:
        this.selectedCustomCalculationName_Bucket,
      customCalulationId: this.customCalculationId,
      isShareMetric: false,
      isMeanMetric: true
    });
  }

  onUpdateBtn() {
    if (this.isShareMetric) {
      this.bindCustomGroupFlags.emit({
        displayMainComponent: false,
        isUpdateCustomGroup: true,
        isCreateCustomGroup: false,
        isDeleteCustomGroup: false,
        isDuplicateCustomGroup: false,
        isShareCustomGroup: false,
        selectedCustomGroupName: this.selectedCustomGroupName,
        selectedCustomGroupName_Bucket: this.selectedCustomGroupName_Bucket,
        customGroupId: this.customGroupId,
        isShareMetric: true,
        isMeanMetric: false
      });
    }
    else {


      this.bindCustomCalculationFlags.emit({
        displayMainComponent: false,
        isUpdateCustomCalculation: true,
        isCreateCustomCalculation: false,
        isDeleteCustomCalculation: false,
        isDuplicateCustomCalculation: false,
        isShareCustomCalculation: false,
        selectedCustomCalculationName: this.selectedCustomCalculationName,
        selectedCustomCalculationName_Bucket:
          this.selectedCustomCalculationName_Bucket,
        customCalculationId: this.customCalculationId,
        isShareMetric: false,
        isMeanMetric: true
      });
    }
    // let listToUpdate = this.loadCustomGroupData.filter(
    //   (a: any) => a.selection_name === this.selectedCustomGroupName
    // );
    // JSON.parse(listToUpdate[0].selection_object); //listToUpdate[0].selection_object;
  }
  onUpdateCustomCalcBtn() {
    this.bindCustomCalculationFlags.emit({
      displayMainComponent: false,
      isUpdateCustomCalculation: true,
      isCreateCustomCalculation: false,
      isDeleteCustomCalculation: false,
      isDuplicateCustomCalculation: false,
      isShareCustomCalculation: false,
      selectedCustomCalculationName: this.selectedCustomCalculationName,
      selectedCustomCalculationName_Bucket:
        this.selectedCustomCalculationName_Bucket,
      customCalculationId: this.customCalculationId,
      isShareMetric: false,
      isMeanMetric: true
    });

    // let listToUpdate = this.loadCustomGroupData.filter(
    //   (a: any) => a.selection_name === this.selectedCustomGroupName
    // );
    // JSON.parse(listToUpdate[0].selection_object); //listToUpdate[0].selection_object;
  }

  onShareSelectionClick() {
    if (
      this.selectedEmailsToShare.length == 0 &&
      this.selectedLoadSelectionObj.shared_with == ''
    ) {
      this.errorPopup('Please select email address.', 'Alert');
    } else {
      this.shareSelectionUserList = this.selectedLoadSelectionObj.shared_with;
      this.selectedSelectionName = this.selectedLoadSelectionObj.selection_name;
      //this.selectedCustomGroupName_Bucket = 'User Defined'; //shared custom group cannot be shared again

      //append custom group name with _share if it is shared with some user for the 1st time
      var newSelectionName = this.selectedSelectionName;
      this.$unSubscribeSubmitData6 = this.crossTabService
        .shareLoadSelection(
          this.selectedLoadSelectionObj.client_name,
          this.selectedLoadSelectionObj.module_id,
          this.selectedLoadSelectionObj.study,
          newSelectionName,
          this.selectedEmailsToShare,
          true
        )
        .subscribe((result: results) => {
          // Response is valid
          if (result.HasValidData) {
            let JsonData = JSON.parse(result.Data);
            if (JsonData == 'Shared Successfully') {
              //  //load main object
              // this.loadSharedWithMeSavedScenarioData.push(
              //   this.selectedLoadSelectionObj
              // );
              this.errorPopup('Shared Successfully', 'Alert');
              this.closeShareSelectionPopUp(true);
              // this.isShareSelectionClick = false;
            } else if (JsonData == 'Share Failed') {
              this.errorPopup('Share Failed. Please try again later', 'Alert');
              this.isShareSelectionClick = false;
            }
          }
          //hide the loader
          this.Loader.hideLoader();

          //this.usageDataLog(Feature.SaveQuery);
        });
    }
  }

  onShareCustomGroups() {
    if (
      this.selectedEmailsToShare.length == 0 &&
      this.selectedCustomGroupObj.shared_with == ''
    ) {
      this.errorPopup('Please select email address.', 'Alert');
    } else {
      this.customGroupShareUserList = this.selectedCustomGroupObj.shared_with;
      this.selectedCustomGroupName = this.selectedCustomGroupObj.selection_name;
      this.selectedCustomGroupName_Bucket = 'userDefined'; //shared custom group cannot be shared again

      // //append custom group name with _share if it is shared with some user for the 1st time
      // var newCustomGroupName =
      //   this.customGroupShareUserList.length == 0
      //     ? this.selectedCustomGroupName
      //     : this.selectedCustomGroupName;
      this.$unSubscribeSubmitData6 = this.crossTabService
        .shareSelectedCustom(
          this.selectedCustomGroupObj.client_name,
          this.selectedCustomGroupObj.module_id,
          this.selectedCustomGroupObj.study,
          this.selectedCustomGroupName,
          this.selectedEmailsToShare,
          this.selectedCustomGroupObj.sortid,
          this.selectedCustomGroupObj.is_shared,
          "customGroup"
        )
        .subscribe((result: results) => {
          if (result.HasValidData) {
            let JsonData = JSON.parse(result.Data);
            if (JsonData == 'Shared Successfully')
              this.errorPopup('Percentage Variable Shared Successfully.', 'Alert');
            this.closeCustomGroupSharePopUp(true); //triggrt popup close
            // this.isShareCustomGroupPopupClick=false;

            // this.isLoadClick = false; //close and reopen the popup to rebind the contents
            // this.isLoadClick = true;
          } else {
            this.errorPopup(
              'Percentage Variable Share has Failed. Please try again later.',
              'Alert'
            );
          }
        });
    }
  }
  onShareCustomCalculation() {
    if (
      this.selectedEmailsToShare.length == 0 &&
      this.selectedCustomCalculationObj.shared_with == ''
    ) {
      this.errorPopup('Please select email address.', 'Alert');
    } else {
      this.customGroupShareUserList =
        this.selectedCustomCalculationObj.shared_with;
      this.selectedCustomCalculationName = this.selectedCustomCalculationObj.selection_name;
      this.selectedCustomCalculationName_Bucket = 'userDefined'; //shared custom group cannot be shared again

      // //append custom group name with _share if it is shared with some user for the 1st time
      // var newCustomGroupName =
      //   this.customGroupShareUserList.length == 0
      //     ? this.selectedCustomGroupName
      //     : this.selectedCustomGroupName;
      this.$unSubscribeSubmitData6 = this.crossTabService
        .shareSelectedCustom(
          this.selectedCustomCalculationObj.client_name,
          this.selectedCustomCalculationObj.module_id,
          this.selectedCustomCalculationObj.study,
          this.selectedCustomCalculationName,
          this.selectedEmailsToShare,
          this.selectedCustomCalculationObj.sortid,
          this.selectedCustomCalculationObj.is_shared,
          'customCalculation'
        )
        .subscribe((result: results) => {
          if (result.HasValidData) {
            let JsonData = JSON.parse(result.Data);
            if (JsonData == 'Shared Successfully')
              this.errorPopup(
                'Mean Variable Shared Successfully.',
                'Alert'
              );
            this.closeCustomCalculationSharePopUp(true); //triggrt popup close
            // this.isShareCustomGroupPopupClick=false;

            // this.isLoadClick = false; //close and reopen the popup to rebind the contents
            // this.isLoadClick = true;
          } else {
            this.errorPopup(
              'Mean Variable Share has Failed. Please try again later.',
              'Alert'
            );
          }
        });
    }
  }
  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }
  //onKeyDown(event: KeyboardEvent): void {
  //  // Get the pressed key
  //  const pressedKey = event.key;

  //  // Allow alphanumeric characters, space, and backspace; prevent other keys

  //  //onst isValidKey = /^[A-Za-z0-9\s\-_]+$/.test(pressedKey);

  //  //const isValidKey = /^[^\s@]+$/.test(pressedKey);
  //  const isValidKey = /^[^\@]+$/.test(pressedKey) || pressedKey === ' ';

  //  if (!isValidKey) {
  //    event.preventDefault();
  //  }
  //}

  onKeyDown(event: KeyboardEvent): void {
    const pressedKey = event.key;

    // Check if the pressed key is the "@" character
    if (pressedKey === '@' || pressedKey === '<' || pressedKey === '>') {
      event.preventDefault();
    }
  }

  isSharedAlready(email: string): boolean {
    return this.selectedEmailsToShare.includes(email);
  }

  toggleCustomCalculationList(containerName: string) {
    //custom group and custom calcuation popup will not be available at the same time so using same flags
    if (containerName == 'userDefined') {
      this.isUserDefinedListVisible = !this.isUserDefinedListVisible;
      this.isSharedWithMeListVisible = false;
    } else if (containerName == 'Shared With Me') {
      this.isUserDefinedListVisible = false;
      this.isSharedWithMeListVisible = !this.isSharedWithMeListVisible;
    }
  }
  toggleCustomGroupList(containerName: string) {
    if (containerName == 'userDefined') {
      this.isUserDefinedListVisible = !this.isUserDefinedListVisible;
      this.isSharedWithMeListVisible = false;
    } else if (containerName == 'Shared With Me') {
      this.isUserDefinedListVisible = false;
      this.isSharedWithMeListVisible = !this.isSharedWithMeListVisible;
    }
  }
  toggleSaveSelectionList(containerName: string) {
    if (containerName == 'userDefined') {
      this.isUserDefinedListVisible = !this.isUserDefinedListVisible;
      this.isSharedWithMeListVisible = false;
    } else if (containerName == 'Shared With Me') {
      this.isUserDefinedListVisible = false;
      this.isSharedWithMeListVisible = !this.isSharedWithMeListVisible;
    }
    this.sharedWithMeSelectionQuery = '';
    this.userDefinedSelectionQuery = '';
  }

  //on click of info icon bind user details towards left
  bindUserName(username: string) {
    this.updateSummary = [];
    username.split(',').forEach((element) => {
      var obj = {
        displayString: element,
      };
      this.updateSummary.push(obj);
    });

    this.customGroupHeaderText = 'Custom Email List';
  }

  handleLoadOptionsClick(
    columnName: string,
    row: any,
    scenarioType: string // user defined or shared with me
  ): void {
    this.currentSavedScenarioData.emit({ currentSavedScenarioData: row });
    if (
      columnName.toLowerCase() === 'load' ||
      columnName.toLowerCase() === 'changeentrypoint'
    ) {

      let isAllowed = true;
      if (columnName.toLowerCase() === 'load') {
        let entrypoints = JSON.parse(row.output_selection_data)
        if (entrypoints != null) {
          if (entrypoints.dataObject != null) {
            let isavailable = this.validateEntryPoints(entrypoints.dataObject.Filter);
            if (!isavailable)
              isavailable = this.validateEntryPoints(entrypoints.dataObject.Column);
            if (!isavailable)
              isavailable = this.validateEntryPoints(entrypoints.dataObject.ColNesting);
            if (isavailable)
              isAllowed = false;
          }
        }
      }
      if (!isAllowed) {
        let message = "You don't have access to this selection.";
        const alertObj: AlertMessageModel = { header: "Alert", message: message, show: true };
        this._alertService.showAlert(alertObj);
      }
      else {
        this.Loader.showLoader();
        this.$unSubscribeSubmitData6 = this.crossTabService
          .getSavedScenarioData(
            row.client_name,
            row.study,
            row.selection_name,
            scenarioType,
            row.module_id
          )
          .subscribe((result: results) => {
            this.isLoadClickOutput.emit({
              isLoadClickOutput: false,
            });
            this.isLoadClick = false;

            this.showLoaderPopUp = false;
            // Response is valid
            if (result.HasValidData) {
              this.bindSelectedScenario.emit({
                selectedScenarioData: JSON.parse(result.Data),
                columnName: columnName,
              });
            }

            this.Loader.hideLoader();

            //hide the loader
          });
      }
    } else if (columnName.toLowerCase() === 'delete') {
      this.deleteMessage.emit({
        deleteMessage:
          'Confirm to delete "' + row.selection_name + '"  selection',
        selectionType: scenarioType,
      });

      this.isShowDialog.emit({ isShowDialog: true });
    } else if (columnName.toLowerCase() === 'info') {
      this.displayTable = false;
      this.infoMessage = row.selection_summary;
      // this.infoMessage.emit({ infoMessage: row.selectionSummary });
    } else if (columnName.toLowerCase() == 'share') {
      this.ShareSelectionClick.emit({
        isValidationPass: true,
        selectionName: row.selection_name,
        isShareSelection: true,
        selectionType: scenarioType,
        is_CustomGroup: row.is_CustomGroup,
      });
    }
  }
  validateEntryPoints(panel: any) {
    let isnotPresent = false;
    if (panel.length > 0) {
      let availEntrypoint = panel.filter((x: any) => { return x["is_entrypoint"] != undefined && x["brandanalysisCode"] != 7 && x.is_entrypoint });
      if (availEntrypoint != null) {
        availEntrypoint.forEach((x: any) => {
          x.Data.forEach((y: any) => {
            let matchedEntryPoint = this.crossTabService.entrypointsData.find((z: any) => { return z.ResponseCode == y.responsecode && z.VariableCode == y.variablecode });
            if (matchedEntryPoint == undefined || matchedEntryPoint == null) {
              isnotPresent = true;
            }
          })
        })
      }
    }
    return isnotPresent;
  }
  bindBackClickEvent() {
    this.displayTable = true;
  }
  selected_client(selected_client: any, selected_study: any) {
    throw new Error('Method not implemented.');
  }
  selected_study(selected_client: any, selected_study: any) {
    throw new Error('Method not implemented.');
  }

  selectCustomCalculation(
    selectionName: string,
    customCalculationType: string,
    customCalculationId: string
  ) {
    this.updateSummary = [];

    var customCalculationData;
    if (customCalculationType == 'userDefined') {
      customCalculationData = this.loadCustomCalculationUserDefinedData;
    } else customCalculationData = this.loadCustomCalculationSharedWithMeData;

    let listToUpdate = customCalculationData.filter(
      (a: any) => a.selection_name === selectionName
    );

    if (listToUpdate.length > 0) {
      var selectionObj = JSON.parse(listToUpdate[0].selection_object);
      this.updateSummary = selectionObj.customCalculationSelectionsObj;

      this.selectedCustomCalculationName = selectionName;
      this.selectedCustomCalculationName_Bucket = customCalculationType;
      this.customCalculationId = customCalculationId;
      this.customGroupHeaderText = 'Summary';
      // this.getCompleteCustomGroupInfo();
    }
  }

  selectCustomGroup(
    selectionName: string,
    customGroupType: string,
    customGroupId: string
  ) {
    this.updateSummary = [];

    var customGroupData;
    if (customGroupType == 'userDefined') {
      customGroupData = this.loadCustomGroupUserDefinedData;
    } else customGroupData = this.loadCustomGroupSharedWithMeData;

    let listToUpdate = customGroupData.filter(
      (a: any) => a.selection_name === selectionName
    );

    if (listToUpdate.length > 0) {
      var selectionObj = JSON.parse(listToUpdate[0].selection_object);
      this.updateSummary = selectionObj.customGroupSelectionsObj;

      this.selectedCustomGroupName = selectionName;
      this.selectedCustomGroupName_Bucket = customGroupType;
      this.customGroupId = customGroupId;
      this.customGroupHeaderText = 'Summary';
      // this.getCompleteCustomGroupInfo();
    }
  }

  showMatchingEmails(event: any): void {
    var userList;
    if (this.isShareSelectionClick) {
      // to share save selection
      userList = this.shareSelectionUserList;
    } //to share custom group
    else {
      userList = this.customGroupShareUserList;
    }
    const inputValue = event.target?.value?.toLowerCase().trim();
    if (inputValue) {
      this.filteredEmailsToShare = userList.filter((email: any) =>
        email.toLowerCase().includes(inputValue)
      );
      this.isUserListToShareDropdownOpen =
        this.filteredEmailsToShare.length > 0;
    } else {
      this.filteredEmailsToShare = [];
      this.isUserListToShareDropdownOpen = false;
    }
  }

  toggleSelectedEmail(email: string): void {
    const index = this.selectedEmailsToShare.indexOf(email);
    if (index === -1) {
      this.selectedEmailsToShare.push(email);
    } else {
      this.selectedEmailsToShare.splice(index, 1);
    }
  }

  addSnapshot(clientName: any) {
    // this.router.navigateByUrl("/Snapshot/configuaration",{ state: { name:  this.selectedClient} });
    let data: { client_name: string; study: string; configdata: any } = {
      client_name: clientName,
      study: '',
      configdata: this.filteredData,
    };
    this.router.navigateByUrl('Snapshot/configuaration', { state: data });
  }

  fileExpand(clickedClient: any) {
    //this.isFolderExpand = !this.isFolderExpand;
    //this.selectedFolder = this.isFolderExpand ? foldername : '';

    // Close other clients if they are open
    this.docStorageData.forEach((client: docStorage) => {
      if (client !== clickedClient) {
        client.showFiles = false;
      }
    });

    // Toggle the studies for the clicked client
    clickedClient.showFiles = !clickedClient.showFiles;
  }

  downloadFile(folderName: string, fileName: string) {
    this.Loader.showLoader();

    let docInputs: documentRepo = {
      client_name: this.selected_client_name,
      study: this.selected_study_name,
      foldername: folderName,
      filename: fileName,
    };
    this.crossTabService.downloadFile(docInputs).subscribe((blob: any) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);

      this.Loader.hideLoader();
    });
  }

  isSelectionChanged(): boolean {
    // Compare the selected items array based on the state of the checkboxes
    return JSON.stringify(this.selectedEmailsToShare) !== JSON.stringify(this.initialSelectedEmailsToShare);
  }

  clickedResult="";
  onConfirm(x:string){
    if(x=='no'){
      this.clickedResult='no';
    }
    else{
      this.clickedResult='yes';
    }
    this.dataEvent.emit(this.clickedResult);
  }
}

