import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { getSnapshotConfiguration } from 'src/app/common/models/configurations.model';
import { checkboxSelectionWithParentSnapshot, DataObject, DataObjectSnapshot } from 'src/app/common/models/crosstab.model';
//import html2canvas from 'html2canvas';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnapshotService {
  isStaticTimeFrame:boolean=false;
  apiBaseUrl = environment.baseApiUrl + 'api/';
  productSelected = "";
  templateSelected = "";
  moduleConfigPropertiesEnableDisable: any = [];
  selectedSnapshotType: string = "";
  SnapshotList: any;
  brandSortId:any=[];
  snapshotProductType: string = '';
  selectedTimePeriod:string="";
  selectedSection: any;
  isRespondentLevel: Boolean = false;
  isAggregatedData: Boolean = false;
  isSemiAggregatedData: Boolean = false;
  selectedSubSection: any;
  clientData: any[] = [];
  widgetNametoDelete: any = '';
  dataObjectForSaveSelection: any;
  alreadySavedSnapshotData: any = [];
  widgetConfigDetails: any;
  WidgetLibSaveDataObject: any = {};
  ChartCustomSaveDataObject: any = {};
  clientName = '';
  snapshotSelectedName = '';
  snapshotTypeSelectedName = '';
  clientSnapshotSelectedData: any;
  clientAllSnapshotNames: any;
  clientSnapshotAllStudyNames: any = [];
  finalLeftPanelSubmitObject: any;
  finalLeftPanelInputSelectedObject: any;
  entryPointData: any;
  ifDataExists: Boolean = false;
  fromBackButton: Boolean = false;
  saveSelectionLoad:Boolean=false;
  widgetAllData: any = [];

  leftPanelData: any;

  leftPanelSelectionsData: any = [];

  selectionSummaryText: string = ``;
  selectionSummaryTextTitle: string = ``;

  outputSnapshotData: any = [];

  standardSnapOutputData: any = [];
  weights: any;
  staticText: any = "";
  LBthreshold: number = 0;
  ISthreshold: number = 0;
  confidenceLevel: any = '';
  thresholdIndex: number = 0;
  userRole: string = "";
  objectFromSnapshotLayout = "";
  x = 1;
  page2ToggleSubject = new BehaviorSubject<number>(1) //pages toggle for each layout(1 for 6 widget layout 2 for 3-6 widget layout)
  pageCounter = new BehaviorSubject<number>(this.x); //page counter through pagination
  LeftBtnDisable = new BehaviorSubject<boolean>(true);
  RightBtnDisable = new BehaviorSubject<boolean>(false);
  sortOption = new BehaviorSubject<string>("Default");
  PassOutput = new Subject<any>();
  passdata: any;
  brandData: any;
  selectedStudy: any = '';
  currentSavedScenarioData: any;
  showWidget1Page: boolean = false;
  showWidget2Page: boolean = false;
  itemWithTrendData:any;
  newCustomizationData : any;
  private significanceSubject = new BehaviorSubject<any>('None');
  significanceValue = this.significanceSubject.asObservable();
  private confedenceSubject = new BehaviorSubject<any>('None');
  confedenceValue = this.confedenceSubject.asObservable();

  // Method to update the value
  updateSignificanceValue(newValue: any) {
    this.significanceSubject.next(newValue);
  }
  updateConfedenceValue(newValue: any) {
    this.confedenceSubject.next(newValue);
  }


  pptChartObjects: any = {
    chartType: "",
    widgetName: "",
    widgetNumber: 0,
    pageNumber: 0,
    data: [],
    properties: {},
    selectionSummary: "",
    LSthreshold: 0,
    ISthreshold: 0,
    conversionPercentage: [],
    MDSdata: []
  };

  allPageOutputData: any;
  allPageOutputDataWithoutTrend: any;

  listOfPptChartObjects: any;
  selectedTPforNonTrendView: string = "";

  private widgetVariable = new BehaviorSubject<any>(null); // Initialize with a default value or null
  widgetVariable$ = this.widgetVariable.asObservable();

  setwidgetVariable(value: any) {
    this.widgetVariable.next(value); // Emit the new value
  }


  selectedcompareacrossOption = "";

  private loadSelec = new BehaviorSubject<any>(null); // Initialize with a default value or null
  loadSelec$ = this.loadSelec.asObservable();

  private hasLoadSelec = new BehaviorSubject<any>(false); // Initialize with a default value or null
  hasLoadSelec$ = this.hasLoadSelec.asObservable();

  setloadSelecVariable(value: any) {
    this.loadSelec.next(value); // Emit the new value
  }

  setHasDefaultSelection(value: any) {
    this.hasLoadSelec.next(value); // Emit the new value
  }

  afterRefreshShowModuleConfig: boolean = false;

  constructor(private http: HttpClient) { }

  getClientStudyData(data: {
    client_code: string;
    snapshot_name: string;
  }): Observable<any> {
    return this.http
      .post<{
        client_code: string;
        snapshot_name: string;
      }>(
        `${this.apiBaseUrl}Snapshot/getSnapshotConfiguration`,
        data,
        { observe: 'response' }
      )
      .pipe(
        tap((response) => console.log('POST request status:', response.status)),
        catchError((error: HttpErrorResponse) => {
          // You can handle the error here and throw a custom error if needed
          return throwError(error);
        })
      );
  }

  addSnapshotConfiguration(request: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiBaseUrl}Snapshot/addSnapshotConfiguration`,
        request,
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
        }),
        catchError((error: HttpErrorResponse) => {
          // You can handle the error here and throw a custom error if needed
          return throwError(() => new Error('test'));
        })
      );
  }

  getConfigurationByClientName(clientName: string): Observable<any> {
    let obj = {
      clientName: clientName,
    };
    return this.http
      .post<any>(
        `${this.apiBaseUrl}Snapshot/getConfigurationByClientName?clientName=${clientName}`,

        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(() => new Error('test'));
        })
      );
  }

  addSnapshotTypeWidgetDetails(request: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiBaseUrl}Snapshot/addSnapshotTypeWidgetDetails`,
        request,
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          console.log(
            'addSnapshotTypeWidgetDetails POST request status:',
            response.status
          );
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(() => new Error('test'));
        })
      );
  }

  getSnapshotTypeWidgetDetails(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiBaseUrl}Snapshot/getSnapshotWidgetDetails`,
        data,

        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          ;
          console.log(
            'getSnapshotTypeWidgetDetails POST request status:',
            response.status
          );
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(() => new Error('test'));
        })
      );
  }

  deleteSnapshot(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/deleteSnapshot`,
      data
    );
  }

  deleteWidget(data: any): Observable<any> {
    ;
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/deleteWidget`,
      data
    );
  }

  getCustomProperties(): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}Snapshot/getChatCustomProperties`
    );
  }

  getModuleConfigProperties(): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}Snapshot/getSnapshotConfigurationProperties`
    );
  }



  getSnapshotEntryPointData(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/getSnapshotEntryPointData`,
      data
    );
  }

  //---------- snapshot (no of preview div) validation based on module configuration--------------
  LayoutDiv = 0;
  noOfWidget(selectedLayout: any) {
    // ;
    this.LayoutDiv = selectedLayout;
    return;
  }
  divInLayout = 0;
  widgetDivs(number: any) {
    // ;
    this.divInLayout = Number(number);
    return;
  }
  //------------- For visual selected preview ------------------------------
  chartImagesPreviewList: any = {
    'Vertical Bar Chart': 'assets/ImagesSnapshot/ChartPreviewImages/Horizontal Bar.png',
    "Vertical Cluster Bar": '',
    "Horizontal Bar Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Horizontal Bar.png',
    "Horizontal Clustered Bar ": 'assets/ImagesSnapshot/ChartPreviewImages/Horizontal Clustered Bar.png',
    "Area Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Area Chart.png',
    "Line Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Line chart.png',
    "Vertical Lollipop Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Vertical lollipop Chart.png',
    "Horizontal Lollipop Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Horizontal lollipop.png',
    "Cluster Lollipop Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Cluster lollipop.png',
    "01/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 01.png',
    "02/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 02.png',
    "03/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 03.png',
    "04/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 04.png',
    "05/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 05.png',
    "06/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 06.png',
    "07/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 07.png',
    "08/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 08.png',
    "09/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 09.png',
    "10/PIE CHART": 'assets/ImagesSnapshot/ChartPreviewImages/Pie 10.png',
    'Scatter Chart': 'assets/ImagesSnapshot/ChartPreviewImages/Scatter Chart.png',
    'Quadrant Scatter Chart': 'assets/ImagesSnapshot/ChartPreviewImages/Quadrant Scatter Chart.png',
    'Bubble Chart': 'assets/ImagesSnapshot/ChartPreviewImages/Bubble Chart.png',
    'Quadrant Bubble Chart': 'assets/ImagesSnapshot/ChartPreviewImages/Quadrant Bubble Chart.png',
    "Vertical Stacked Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Vertical Stacked.png',
    "Horizontal Stacked Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Horizontal Stacked.png',
    "Funnel Chart": 'assets/ImagesSnapshot/ChartPreviewImages/Funnel Chart.png'
  }

  processData(data: any[]): any[] {
    let processedData = data.map(widget => ({
      widgetName: widget.widget_name,
      visualization: widget.widgetchartname,
      image: this.chartImagesPreviewList[widget.widgetchartname] || '',
      active: false
    }));
    return processedData;
  }


  // write a function to get the data from the server ${this.apiBaseUrl}Snapshot/getLeftPanel/getSnapshotLoadData using parameter loadParameter
  // and return the data to the component
  getSnapshotLeftPanelData(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/getSnapshotLoadData`,
      //`${this.apiBaseUrl}api/DataExplorer/getLoadData`,
      data
    );
  }

  // ------------------Standard widget snapshot------------------

  getWidgetInfo(): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}Snapshot/getWidgetInfo`
    );
  }

  getWidgetLibraryMetricList(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/getWidgetLibraryMetricList`,
      data
    );
  }

  addWidgetLibraryConfiguration(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/addWidgetLibraryConfiguration`,
      data
    );
  }

  getWidgetLibraryConfiguration(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/getWidgetLibraryConfiguration`,
      data
    );
  }

  getSnapshotOutput(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}Snapshot/getSnapshotOutput`,
      data
    );
  }

  isTrendEnabled:boolean=false;
  checkTrendEnabled(data: any): boolean {
    let isTrendEnabled = false;
    if (!this.snapshotProductType.toLowerCase().includes('custom')) {
      data[0]?.widgetPageConfiguration.forEach(
        (element: any) => {
          element.pagewidgetdetails.forEach(
            (element: any) => {
              if (element.trend) {
                isTrendEnabled = true;
              }
            }
          );
        }
      );
    }
    else{
      let snapshotType=data.snapshotType.find((s:any)=>s.snapshotType_name==this.selectedSnapshotType);
      if(snapshotType){
        let section= snapshotType.section.find((ss:any)=>ss.section_name==this.selectedSection);
        if(section){
          let subsection= section.subsection.find((sss:any)=>sss.subsection_name==this.selectedSubSection);
          if(subsection){
            subsection.widget_detail.forEach((w:any)=>{
              let properties = JSON.parse(w.customizationProperties);
              if(properties?.additionalGroup){
                if(properties?.additionalGroup?.Trendline){
                  isTrendEnabled=true;
                }
              }
            })
          }
        }
      }
    }
    this.isTrendEnabled = isTrendEnabled;
    return isTrendEnabled;
  }


  getOutPutData(
    dataObject: DataObjectSnapshot,
    clientCode: string,
    studyname: string,
    significance: string,
    isFilteringActive: boolean,
    confidanceLevel: string,
    isTotalBase: boolean,
    SelectedWeight: string,
    weightColumnName: string,
    isBoostFactor: boolean,
    SignificanceVsReference: string,
    columnLength: number,
    entryPointData: any,
    isBrandAnalysis: boolean,
    isAggregatedData: boolean,
    isSemiAggregatedData: boolean,
    isRespondentLevel: boolean,
    aggregatedVariables: string,
    istimePeriodWave: boolean,
    widegtconfigdetails: any,
    indexVsBrandReference: checkboxSelectionWithParentSnapshot[],
    significanceVsBrandReference: checkboxSelectionWithParentSnapshot[],
    isTrend: boolean
  ): Observable<any> {
    ;
    const url = this.apiBaseUrl + 'Snapshot/getSnapshotOutput';
    // ;
    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      dataModel: {
        Row: dataObject.Row,
        RowNesting: dataObject.RowNesting,
        Column: dataObject.Column,
        ColNesting: dataObject.ColNesting,
        Filter: dataObject.Filter,
        clientCode: clientCode,
        studyname: studyname,
        significance: significance,
        isFiltering: isFilteringActive,
        confidenceLevel: confidanceLevel,
        isTotalBase: isTotalBase,
        SelectedWeight: SelectedWeight,
        weightColumnName: weightColumnName,
        isBoostFactor: isBoostFactor,
        SignificanceVsReference: SignificanceVsReference,
        columnLength: columnLength,
        entryPointData: entryPointData,
        isBrandAnalysis: isBrandAnalysis,
        isAggregatedData: isAggregatedData,
        isSemiAggregatedData: isSemiAggregatedData,
        aggregatedVariables: aggregatedVariables,
        istimePeriodWave: istimePeriodWave,
        isRespondentLevel: isRespondentLevel,

      },
      widegtconfigdetails: widegtconfigdetails,
      indexVsBrandReference: indexVsBrandReference,
      significanceVsBrandReference: significanceVsBrandReference,
      isTrend: isTrend
      //isFiltering: true,
    };
    ;
    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        ;
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }


  // for low base and in-sufficient base
  getGeneralConfigData(data: any): Observable<any> {
    const url = `${this.apiBaseUrl}Configuration/getConfiguration`;
    return this.http.post<any>(url, data).pipe(
      catchError((error: HttpErrorResponse) => {

        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  CheckLSorIScondition(userRole: string, text: string, sampleSize: number) {

    if (text == null || text == "NA")
      text = "NA";
    else if (userRole != "superadmin" && sampleSize <= this.ISthreshold)
      text = "I/S";
    else if (sampleSize > this.ISthreshold && sampleSize < this.LBthreshold)
      text = text + "*";

    return text;
  }

  //only for piechart 12
  sigChangeCheck(data: any, chartmaindata: any, textCheck: any) {
    if (data != undefined) {
      let changeVal: any = (data.CalculationType != 'mean' && data.CalculationType != 'index') ? Number(data['changeVsPY'] * 100) : Number(data['changeVsPY']);
      const currentValue = this.significanceSubject.getValue();
      if (currentValue != 'None' && textCheck != 'I/S') {
        if (data['changeVsPY'] == null || data['changeVsPY'] == "NA") {
          let finalText = '<span style="color:black">(NA)</span>';
          return finalText;
        }
        else {
          let sigClass = chartmaindata[0].Significance_Value < chartmaindata[0].Sig_LowerLimt ? 'red' :
            (chartmaindata[0].Significance_Value > chartmaindata[0].Sig_UpperLimt ? 'green' : 'black');

          let finalText = `<span style="color:${sigClass}">(` + `${parseFloat(Number(changeVal).toFixed(1))}${chartmaindata[0].isPercentage ? '%' : ''})</span>`;
          return finalText;
        }
      }
      else {
        return '';
      }
    }
    else {
      return '';
    }
  }


  callPptExport(imgString: string,data: any): Observable<Blob> {
    const url = `${this.apiBaseUrl}Snapshot/pptExport`;
    //JSON.stringify(data);
    let formData = new FormData();
    data.imgString=imgString;
    formData.append("inputObj", JSON.stringify(data));
    return this.http.post(url, formData, { responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );

    // this.http.post(url, data, { responseType: 'blob' }) .subscribe(
    //   (pptBlob: Blob)=> {
    //    let fileName="presentation.pptx";
    //    this.downloadPPTFile(pptBlob, fileName);
    //   },
    //   (error) => {
    //     console.error('Error downloading the file:', error);
    //     alert('An error occurred while downloading the file.');
    //   }
    // );

    // .pipe(
    //     catchError((error: HttpErrorResponse) => {
    //       // Handle error
    //       return throwError(error);
    //     })
    //   );


  }


  callScreengrabPptExport(data: any): Observable<any> {
    const url = `${this.apiBaseUrl}Snapshot/ScreengrabPptExport`;
    return this.http.post<any>(url, data).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle error
        return throwError(error);
      })
    );
  }



  //-------------------Snapshot Excel export--------------------------
  // this._configService.setDropdownValue('significance',type.name);
  private dropdownValues: any = {
    compareAcross: "None",
    significance: "None",
    //isTranspose: false,
    baseType: "Total Base",
    confidenceLevel: "None",
    //output: '',
    studyName: '',
    clientName: '',
    snapshotName: '',
    lowBase: 0,
    insufficientBase: 0,
    thresholdIndex: 0,
    //filters: '',
    //nestingType: '',
    //isFiltering: false,
    //selectionInput: null,
    selectionSummary: '',
    //failedComments: '',
    //isTotal: false,
    //lowBase: 0,
    //InsufficientBase: 0,
    //threshold: 0,
    selectedIndex: "None",
    //selectedWeight: null,
    //DefaultWeight: null,
    //isBrandAnalysis: true,
    SignificanceVsReference: "None",
    //DataFormat: null,
    //isSampleSizeBlock: false,
    //isWeightedSampleBlock: false
    outputArray: '',
    outputTrendArray: '',
    staticText: this.staticText,
    lBThreshold: this.LBthreshold,
    iSthreshold: this.ISthreshold,
    userRole: this.userRole
  };

  setDropdownValue(key: any, value: any) {
    this.dropdownValues[key] = value;
  }

  getDropdownValues() {
    return this.dropdownValues;
  }

  exportToExcel(data: any): Observable<Blob> {
    //;
    // Adjust the API endpoint and parameters as needed
    // exportToExcel(data: ExcelDataModel): Observable<Blob> {
    const exportUrl = `${this.apiBaseUrl}Snapshot/excelExport`;
    return this.http.post(exportUrl, data, { responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
    //}
    //const exportUrl = `${this.apiBaseUrl}api/CrosstabOutput/excelExport`;
    //return this.http.post(exportUrl, data, { responseType: 'blob' });
  }

  //----------------Static ppt------------


  exportPPT(
    imgString: string,
    url: string,
    selectionSummary: string,
    inputObj: any
  ): Observable<any> {
    let data = {
      imgString: imgString,
      summary: selectionSummary, //value should be passed later, if sumamry needs to be populated as text in ppt
      inputObj: inputObj
    };
    return this.http
      .post(this.apiBaseUrl + url, data, {
        //observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response: any) => {
          //invokes function that will download the received response as file

          return response;
        })
      );
  }


  //----------------------for creating leftpanel submit output object--------------
  updatedDataForSubmit(data: any) {
    return data.map((item: any) => {
      // Extract Level0 to Level5 values
      let levels = [
        item.Level1,
        item.Level2,
        item.Level3,
        item.Level4,
        item.Level5,
      ];
      // Adding these field in existing array
      let parent = '';
      let isTimePeriod = false;
      let isMarket = false;
      let isRolling = false;

      // Filter out empty values and join with |
      let child = levels.filter(level => level).join('|');
      let arr = child.split("|");

      if (item.Level0 == 'Time Period') {
        isTimePeriod = true;
      } else {
        isTimePeriod = false;
      }

      if (item.Level0 == 'Markets') {
        isMarket = true;
      } else {
        isMarket = false;
      }

      if (item.Level1 == '3MMA' || item.Level1 == '4MMA' || item.Level1 == '6MMA' || item.Level1 == '12MMA') {
        isRolling = true;
      } else {
        isRolling = false;
      }


      parent = arr[arr.length - 2];

      let multiPunchMetric = ''
      if (item.is_multi_punch == 0 || item.is_multi_punch == "0") {
        multiPunchMetric = ''
      }
      else {
        multiPunchMetric = parent;
      }
      ;
      // Adding all extra created variable in existing data-item
      return { ...item, child: child, parent: parent, isTimePeriod: isTimePeriod, isMarket: isMarket, isRolling: isRolling, multiPunchMetric: multiPunchMetric };
    });
  }

  previewDataCreation(
    data: any
  ): checkboxSelectionWithParentSnapshot | undefined {
    for (let i = 0; i < data.length; i++) {
      const checkboxSelection = [];

      for (let j = 0; j < data.length; j++) {
        checkboxSelection.push({
          CheckboxStatus: false,
          Child: data[j].child ?? '',
          variablecode: data[j].variable_code ?? '',
          responsecode: (data[j].calc_type.toLowerCase() == "respgrp" ? data[j].derived_metric_calculation : data[j].response_code) ?? '',
          // isDerviedVariable: data[j].is_derived == null ? false : Boolean(data[j].is_derived),
          isDerviedVariable: false,
          CalculationType: data[j].calc_type ?? '',
          sort_id: data[j].sort_id == null ? 0 : Number(data[j].sort_id),
          year_response_code: data[j].year_code ?? '',
          year_variable_code: data[j].dataYearCode ?? '',
          Date: data[j].date ?? '',
          // isMultiPunch: data[j].is_multi_punch == null ? 0 : Number(data[j].is_multi_punch),
          isMultiPunch: data[j].is_multi_punch == null ? 0 : Number(data[j].is_multi_punch),
          isTimePeriod: data[j].isTimePeriod == null ? false : Boolean(data[j].isTimePeriod),
          isMarket: data[j].isMarket == null ? false : Boolean(data[j].isMarket),
          multiPunchMetric: data[j].multiPunchMetric,
          MultiPunchNumber:
            data[j].is_multi_punch == null ? 0 : Number(data[j].is_multi_punch),
          // isCustomGroup: data[j].is_CustomGroup == null ? false : Boolean(data[j].is_CustomGroup),
          isCustomGroup: false,
          // kibanaFilterScript: data[j].kibanaFilterScript ?? '',
          kibanaFilterScript: '',
          // customGroupId: data[j].customGroupId ?? '',
          customGroupId: '',
          customGroupRowOrder: 0,
          isRolling: data[j].isRolling == null ? false : Boolean(data[j].isRolling),
        });
        //;
      }
      // ;
      const columnData: checkboxSelectionWithParentSnapshot = {
        Parent: data[i].parent == null ? '' : data[i].parent,
        SortId: data[i].sort_id == null ? 0 : Number(data[i].sort_id),
        Data: checkboxSelection,
        isMultiPunch: (data[i].is_multi_punch == null || data[i].is_multi_punch == 0 || data[i].is_multi_punch == "0") ? false : Boolean(data[i].is_multi_punch),
        //isMultiPunch: false,
        MultiPunchNumber: data[i].is_multi_punch == null ? 0 : Number(data[i].is_multi_punch),
        //MultiPunchNumber: 0,
        // isCustomGroup: data[i].is_CustomGroup == null ? false : Boolean(data[i].is_CustomGroup),
        isCustomGroup: false,
        // customGroupId: data[i].customGroupId == null ? '' : data[i].customGroupId,
        customGroupId: '',
        brandanalysisCode: data[i].is_brand_analysis == null ? 0 : Number(data[i].is_brand_analysis),
      };

      // ;
      return columnData;
      break;
    }

    // Return undefined if data.length === 0
    return undefined;
  }

  //---------------Indexing Reference------------------------------------- 
  isIndexSelected: any;
  referenceData: any;
  standardSnapOutputDataWhenSigClicked: any;
  CheckIndexColoring(text: string) {
    if (Number(text) > this.thresholdIndex + 100) {
      return 'green';
    }
    else if (Number(text) < (100 - this.thresholdIndex)) {
      return 'red';
    }
    else {
      return 'black';
    }
  }


  //function to choose weighted or unweighted data 
  weightSelected: any;
  weightToggle(base_selected: any, weight_selected: any, row: any) {
    switch (weight_selected) {
      case 'Weight 1':
        {
          return base_selected.toLowerCase() != "total base" ? row.answerWeightedShare : row.totalWeightedShare;
        }
      case 'Unweighted':
        {
          return base_selected.toLowerCase() != "total base" ? row.answerUnweightedShare : row.totalUnweightedShare;
        }
      default:
        {
          return base_selected.toLowerCase() != "total base" ? row.answerWeightedShare : row.totalWeightedShare;
        }
    }
  }

  weightToggleIndex(base_selected: any, weight_selected: any, row: any) {
    switch (weight_selected) {
      case 'Weight 1':
        {
          return base_selected.toLowerCase() != "total base" ? row.answerWeightedShareIndex : row.totalWeightedShareIndex;
        }
      case 'Unweighted':
        {
          return base_selected.toLowerCase() != "total base" ? row.answerWeightedShareIndex : row.totalWeightedShareIndex;
        }
      default:
        {
          return base_selected.toLowerCase() != "total base" ? row.answerWeightedShareIndex : row.totalWeightedShareIndex;
        }
    }
  }

  //get all saved scenairos data for particular user,client and study
  loadSelectionData(
    client_name: string,
    snapshotName: string,
    snapshotType: string,
    subSection: string,
    section: string,
    study: string,
    module_id: number,
    scenarioType: string
  ): Observable<any> {
    var url;
    if (scenarioType == 'userDefined')
      url = `${this.apiBaseUrl}Snapshot/getAllUserdefinedSavedSelection`;
    else
      url = `${this.apiBaseUrl}Snapshot/getAllSharedWithMeSavedSelection`;

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      snapshotName: snapshotName,
      snapshotType: snapshotType,
      subSection: subSection,
      section: section,
      study: study,
      module_id: module_id,
      scenarioType: scenarioType,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }


  //  weightToggle_forBase(base_selected:any,weight_selected:any,row:any){
  //   switch(weight_selected){
  //    case 'Weight 1':
  //      {
  //        return base_selected.toLowerCase() != "total base" ? row.answerWeightedShare : row.totalWeightedShare;
  //      }
  //    case 'Unweighted':
  //      {
  //        return base_selected.toLowerCase() != "total base" ? row.answerUnweightedShare : row.totalUnweightedShare;
  //      }
  //    default:
  //      {
  //        return base_selected.toLowerCase() != "total base" ? row.answerWeightedShare : row.totalWeightedShare;
  //      }
  //   }
  // }




  deleteSavedScenario(
    client_name: string,
    study: string,
    snapshotName: string,
    module_id: number,
    selection_name: string,
    snapshotType: string,
    subSection: string,
    section: string,
    scenarioType: string
  ) {
    const url = `${this.apiBaseUrl}Snapshot/deleteSavedScenario`;

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      snapshotName: snapshotName,
      module_id: module_id,
      selection_name: selection_name,
      snapshotType: snapshotType,
      subSection: subSection,
      section: section,
      scenarioType: scenarioType
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  saveSelectionData(
    module_id: number,
    selection_name: string,
    client_name: string,
    study: string,
    snapshotName: string,
    snapshotType: string,
    section: string,
    subSection: string,
    leftpanel_input_data: string,
    output_selection_data: string,
    selection_summary: string,
    is_active: boolean,
    is_shared: boolean,
    modified_by: string,
    shared_with: string,
    leftpanelDataArray: string,
    is_CustomGroup: boolean,
    excelData:string = "",
    pptData:string = "",
    compareAcrossOption:string = "",
    brandSortId: string = "",
    widgetData:string="",
    baseselected:string="",
    weightSelected:string="",
    selectedTPforNonTrendView=""
    
  ): Observable<any> {
    const url = `${this.apiBaseUrl}Snapshot/saveSelection`;

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      module_id: module_id,
      selection_name: selection_name,
      client_name: client_name,
      study: study,
      snapshotName: snapshotName,
      snapshotType: snapshotType,
      section: section,
      subSection: subSection,
      leftpanel_input_data: leftpanel_input_data,
      output_selection_data: output_selection_data,
      selection_summary: selection_summary,
      modified_by: modified_by,
      shared_with: shared_with,
      is_active: is_active,
      is_shared: is_shared,
      entry_point_selection_data: leftpanelDataArray,
      is_CustomGroup: is_CustomGroup,
      excelData: excelData,
      pptData: pptData,
      compareAcrossOption : compareAcrossOption,
      brandSortId: brandSortId,
      widgetData:widgetData,
      baseselected:baseselected,
      weightSelected:weightSelected,
      selectedTPforNonTrendView: selectedTPforNonTrendView
    };
    //  return EMPTY;
    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  shareLoadSelection(
    selectedCustomGroupName: string,
    selected_snapshotName: string,
    selected_snapshotType: string,
    selected_section: string,
    selected_subSection: string,
    selected_client: string,
    selected_study: string,
    selected_module: number,
    selectedEmailsToShare: string[],
  ) {
    const url = `${this.apiBaseUrl}Snapshot/shareSelections`;

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      selection_name: selectedCustomGroupName,
      snapshotName: selected_snapshotName,
      snapshotType: selected_snapshotType,
      section: selected_section,
      subSection: selected_subSection,
      client_name: selected_client,
      study: selected_study,
      module_id: selected_module,
      shareWith: selectedEmailsToShare.toString(),
      sort_id: 0,
      is_shared: true,
      customGroupBucket: ""
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  //get selected scenario's data
  getSavedScenarioData(
    study: string,
    client_name: string,
    snapshotName: string,
    snapshotType: string,
    section: string,
    subSection: string,
    selection_name: string,
    scenarioType: string,
    module_id: number
  ) {
    const url = `${this.apiBaseUrl}Snapshot/getSelectedScenarioData`;

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      study: study,
      client_name: client_name,
      snapshotName: snapshotName,
      snapshotType: snapshotType,
      section: section,
      subSection: subSection,
      selection_name: selection_name,
      scenarioType: scenarioType,
      module_id: module_id
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  //get selected scenario's data
  setDefaultLoadSelection(
    study: string,
    client_name: string,
    snapshotName: string,
    snapshotType: string,
    section: string,
    subSection: string,
    selection_name: string,
    scenarioType: string,
    module_id: number,
    is_default: boolean
  ) {
    const url = `${this.apiBaseUrl}Snapshot/saveDefaultSelection`;

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      study: study,
      client_name: client_name,
      snapshotName: snapshotName,
      snapshotType: snapshotType,
      section: section,
      subSection: subSection,
      selection_name: selection_name,
      scenarioType: scenarioType,
      module_id: module_id,
      is_default: is_default
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }


}


