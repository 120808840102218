import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { results } from '../models/common.model';
import { environment } from 'src/app/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { result } from 'lodash';
import { access, Users } from '../models/usermanagement.model';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import {
  PublicClientApplication,
  EndSessionRequest,
} from '@azure/msal-browser';
import { COMMON_CONSTANTS } from '../constants/common-constants';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private apiUrl = environment.baseApiUrl + 'api/Authentication/';

  private MN_AuthenticateUsers: string = 'authenticateUser';

  private sessionTimeoutMinutes: number = Number(environment.sessionTimeOut);
  private sessionTimeout: number = 0;
  private sessionTimeoutTimer: any;

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private router: Router,
    private zone: NgZone
  ) {
    //this.startSessionTimeoutTimer();
  }
  level1: string = 'level1';
  level2: string = 'level2';
  level3: string = 'level3';
  level4: string = 'level4';

  /**
   * Authenticates users and  retrieves results from the server.
   *
   * @returns An Observable of results.
   */
  AuthenticateUsers(): Observable<results> {
    // Make an HTTP GET request to the authentication endpoint
    return this.http
      .get<any>(`${this.apiUrl}${this.MN_AuthenticateUsers}`)
      .pipe(
        // Process the result using the map operator
        map((result: results) => {
          // Check if the result has valid data
          if (result.HasValidData) {
            // Store access data if available

            //if ((localStorage.getItem('role') != undefined) && (result.Data != localStorage.getItem('role'))) {
            //  this.logout();
            //} else {
            this.storeAccessData(result.Data);
            //}
            // this.resetSessionTimeoutTimer();
          }
          // Return the result
          return result;
        }),
        // Handle errors using the catchError operator
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }
  /**
   * Initiates a login redirect using the authentication service.
   */
  loginRedirect() {
    // Initiate a login redirect using the authentication service
    //localStorage.removeItem('role');
    this.authService.loginRedirect();
    // this.resetSessionTimeoutTimer();
  }

  roleValidation(): Observable<results> {
    return this.http
      .get<any>(`${this.apiUrl}${this.MN_AuthenticateUsers}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }

  /**
   * Checks if the user is authenticated by verifying the existence of accounts.
   *
   * @returns A boolean indicating whether the user is authenticated.
   */
  isAuthenticated(): boolean {
    // Check if there are any authenticated accounts using the authentication service instance
    return this.authService.instance.getAllAccounts().length > 0;
  }

  /**
   * Checks if the user has the expected role.
   *
   * @param expectedRole - The expected role or an array of expected roles.
   * @returns A boolean indicating whether the user has the expected role(s).
   */
  hasRole(expectedRole: any) {
    // Retrieve user data from local storage
    let userAccess = this.GetuserData();

    // Check if userAccess is not null
    if (userAccess != null) {
      // Check if the user is a super admin
      if (userAccess.super_admin) {
        return true; // Super admin has all roles
      }
      // Check if user has access entries and expectedRole is an array
      if (
        userAccess.access != null &&
        userAccess.access.length > 0 &&
        Array.isArray(expectedRole)
      ) {
        // Check if any of the expected roles is available in user's access
        let isAvailable = userAccess.access.find(
          (x: access) => expectedRole.indexOf(x.role_name) > -1
        );
        // Return true if the expected role is available
        if (isAvailable) {
          return true;
        }
      }
    }
    // Return false if the user does not have the expected role(s)
    return false;
  }

  storeAccessData(data: string) {
    if (data != null) {
      localStorage.setItem('role', data);
    } else {
      localStorage.removeItem('role');
    }
  }
  /**
   * Decodes a base64-encoded string.
   *
   * @param encodedData - The base64-encoded string to be decoded.
   * @returns The decoded string.
   */
  decode(encodedData: string): string {
    // Use the atob function to decode the base64-encoded string
    const decodedData = atob(encodedData);

    // Return the decoded string
    return decodedData;
  }

  /**
   * Retrieves user data from local storage.
   *
   * @returns The parsed user data object or null if data is not available or cannot be decoded/parsed.
   */
  GetuserData() {
    // Retrieve encoded data from local storage
    const storedEncodedData = localStorage.getItem('role');
    // Check if storedEncodedData is not null
    if (storedEncodedData) {
      // Decode the encoded data using the decode function
      const decodedData = this.decode(storedEncodedData);
      // Parse the decoded data using the parseAccessData function
      return this.parseAccessData(decodedData);
    }
    // Return null if no data is available in local storage
    return null;
  }

  /**
   * Parses a JSON-formatted string and returns the resulting object.
   *
   * @param data - The JSON-formatted string to be parsed.
   * @returns The parsed object or null if parsing fails.
   */
  parseAccessData(data: string): Users | null {
    let access: Users;
    try {
      // Attempt to parse the JSON-formatted string
      access = JSON.parse(data);
      // Return the parsed object
      return access;
    } catch (error) {
      // If parsing fails, return null and handle the error as needed
      return null;
    }
  }

  /**
   * Retrieves landing page access based on the user's access level.
   *
   * @returns The user's access level or null.
   */
  GetLandingPageAccess() {
    // Get the user's access level
    let level = this.GetAccess();
    // Check if the access level is level3
    if (level != null && level == this.level3) {
      // Retrieve user data from local storage
      const userAccess = this.GetuserData();
      // Check if userAccess is not null and has exactly one access item
      if (userAccess?.access?.length == 1 && userAccess?.access[0]?.module_name!="Snapshot") {
        // Navigate to the CrossTab page using the first access item
        if(userAccess.access[0].module_name==COMMON_CONSTANTS.BrandAnalysisModuleNameId)
          this.navigateToBrandAnalysisTab(userAccess.access[0])
        else
        this.navigateToCrossTab(userAccess.access[0]);
      }
    }
    // Return the user's access level or null
    return level;
  }

  /**
   * Retrieves the user's access level based on user data.
   *
   * @returns The user's access level (level1, level2, level3, level4) or null if no access data is available.
   */
  GetAccess() {
    // Retrieve user data from local storage
    const userAccess = this.GetuserData();
    // Check if userAccess is null
    if (userAccess === null) {
      return null; // No access data available
    }
    // Check if the user is a super admin
    if (userAccess.super_admin) {
      return this.level1; // Super admin has access
    }
    // Determine the access level based on the number of access entries
    const accessLength = userAccess.access ? userAccess.access.length : 0;
    // Check if there are no access entries
    if (accessLength === 0) {
      return this.level4; // No access available
    }
    // Check if the user has the "Admin" role
    if (this.isAdmin(userAccess.access)) {
      return this.level2; // User has the "Admin" role
    } else {
      return this.level3; // More than one access entry, indicating no access
    }
  }

  /**
   * Checks if the user has the "Admin" role in the provided userAccess array.
   *
   * @param userAccess - An array of access objects representing the user's access roles.
   * @returns A boolean indicating whether the user has the "Admin" role.
   */
  isAdmin(userAccess: access[] | null | undefined): boolean {
    // Check if userAccess is not null
    if (userAccess != null) {
      // Find the "Admin" role in the userAccess array
      let admin = userAccess.find((x: access) => x.role_name == 'Admin');
      // If the "Admin" role is found, return true
      if (admin != null) {
        return true;
      }
    }
    // Return false if the user does not have the "Admin" role or if userAccess is null or undefined
    return false;
  }

  navigateToCrossTab(userAccess: access | null) {
    if (userAccess != null) {
      let data: { client_code: string; study: string } = {
        client_code: userAccess.client_name,
        study: userAccess.study_name,
      };
      this.router.navigateByUrl('Crosstab', { state: data });
    }
  }
  navigateToBrandAnalysisTab(userAccess: access | null) {
    if (userAccess != null) {
      let data: { client_code: string; study: string } = {
        client_code: userAccess.client_name,
        study: userAccess.study_name,
      };
      this.router.navigateByUrl('BrandAnalysis', { state: data });
    }
  }

  logout(): void {
    // Check if there are any authenticated accounts using the authentication service instance
    if (this.authService.instance.getAllAccounts().length > 0) {
      // Log out the user using the MSAL service
      const logoutRequest: EndSessionRequest = {
        postLogoutRedirectUri: environment.logoutURL,
      };

      this.authService.logout(logoutRequest);
      //clearTimeout(this.sessionTimeoutTimer);
      // Optionally, you can clear local storage or perform additional cleanup
      localStorage.removeItem('role');
      // this.authService.loginRedirect();
      // Navigate to the specified route after successful logout
      // this.router.navigate([redirectRoute]);
    }

    //this.authService.loginRedirect().subscribe(() => {
    //  // After logout, perform your desired action, such as displaying a login popup or redirecting to the login page
    //  // For example, you can display a login popup:
    //  this.authService.loginPopup().subscribe(() => {
    //    // Handle the case after the user has logged in again
    //  });
    //  // Or you can redirect to the login page:
    //  // this.authService.loginRedirect();
    //});
  }

  //startSessionTimeoutTimer() {
  //  this.sessionTimeout = this.sessionTimeoutMinutes * 60 * 1000; // Convert minutes to milliseconds

  //  this.sessionTimeoutTimer = setTimeout(() => {
  //    this.zone.run(() => {
  //      // Wrap the logout logic in the Angular zone to avoid change detection issues
  //      this.logout();
  //    });
  //  }, this.sessionTimeout);
  //}
  //resetSessionTimeoutTimer() {
  //  clearTimeout(this.sessionTimeoutTimer);
  //  this.startSessionTimeoutTimer();
  //}
}
