import { Component, HostListener, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { AlertMessageModel, results } from 'src/app/common/models/common.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { LoaderService } from 'src/app/common/services/loader.service';
import { UserProjectManagementService } from 'src/app/common/services/user-project-management.service';
import { ClientStudyNames, UserManagementDetails, access, modules, Users, ClientSnapshotNames, EntryPointDetails, EntryPointNames, entryPoints, EntryPointLevel, EntryPointMetricOption} from 'src/app/common/models/usermanagement.model';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { COMMON_CONSTANTS } from 'src/app/common/constants/common-constants';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  usersList!: Users[];
  UserManagementDetails!: UserManagementDetails;
  clientStudyList!: ClientStudyNames[];
  clientStudyARKList!: ClientStudyNames[];
  clientStudyBrandList!:ClientStudyNames[];
  clientSnapshotNameList!: ClientSnapshotNames[];
  itemsArray = new Array(20);
  searchQuery: string = "";
  searchClientStudy: string = ""
  searchModule: string = ""
  moduleList!: modules[]
  adminAccessList: access[] = [];
  clientStudyItems!: ClientStudyNames[];
  clientSnapshotItems!: ClientSnapshotNames[];
  modulesItems!: modules[];
  selectedClientCell: number | null = null;
  selectedmoduleCell: number | null = null;
  clientStudyOption: number | null = null;
  clientSnapshotOption: number | null = null;
  entryPointDropDownShow: number | null = null;
  levelIndexValue: number | null = null;
  selectedClientStudy!: ClientStudyNames;
  selectedClientSnapshot!: ClientSnapshotNames;
  showEPnextLevel: boolean = false;
  showEntryPoints: boolean = false;
  isShowDialog: boolean = false;
  confirmationMessage: string = "";
  confirmationType: string = "";
  selectedUser!: Users
  isLevel2: boolean = false;
  isLevel1: boolean = false;
  ActiveModule1: boolean = false;
  ActiveModule2: boolean = false;
  @Output() btnVisible = new EventEmitter<any>();
  @Input() userManagementShown: boolean = false;
  @Input() excelExport: boolean = false;
  @Input() helpGuide: boolean = false;
  @Input() pptExport: boolean = false;
  @Input() repository: boolean = false;
  @Input() exportDownloads: boolean = false;
CrossTabName:string=COMMON_CONSTANTS.CrossTabModuleId;
BrandAnalysisName:string=COMMON_CONSTANTS.BrandAnalysisModuleNameId;
ArkName:string=COMMON_CONSTANTS.ARKName;
DataUploadName:string=COMMON_CONSTANTS.DataUploadName;
entryPointListDetails:EntryPointDetails[]=[];
entryPointNames:EntryPointDetails[]=[];
entryPointLevels:EntryPointDetails[]=[];
entryPointMetricOption:EntryPointDetails[]= [];
selectedEntryPoint!:EntryPointDetails;
epSelecteAll:boolean=false;
entryPointSelectedList:EntryPointDetails[]= [];
errorShow:boolean=false;
errorMessage:string="";
  constructor(private _managementService: UserProjectManagementService,
    private _loaderService: LoaderService,
    private _alertService: AlertMessageService,
    private _authenticateService: AuthenticationService) {
    // assign the client and study details from router extra state
  }
  ngOnInit(): void {
    //this._authenticateService.startSessionTimeoutTimer();
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());

    this.ActiveModule1 = false;
    this.ActiveModule2 = true;
    this.getAllUsers()
  }

  previousCharLength: number = 0;  
  onSearchInputChange(char: any,user:any) {
    if(user.moduleName=="Snapshot"){
      this.showEntryPoints = false;


      if(Object.keys(this.lastClicked).length != 0){
        this.OnClickClientSnapshot(this.lastClicked.clientSnapshot, this.lastClicked.user, this.lastClicked.index);
        this.lastClicked={}
      }
      // if (this.previousCharLength === 0 && char.length === 1) {
      //   this.OnClickClientSnapshot(this.lastClicked.clientSnapshot, this.lastClicked.user, this.lastClicked.index);
      //   this.lastClicked={}
      // }
      // else{
      //   this.lastClicked={};
      // }
  
      // Update the previousCharLength with the current length
      this.previousCharLength = char.length;
    }
  }
  //ngOnDestroy() {
  //  document.removeEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
  //  document.removeEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
  //}

  getAllUsers() {
    this.btnVisible.emit({
      helpGuide: false,
      pptExport: false,
      repository: false,
      userManagementShown: false,
    });
    // Show loader while fetching user data
    this._loaderService.showLoader();

    // Subscribe to the getUsers observable
    this._managementService.getUsers().subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Get the user access level
        const accessLevel = this._authenticateService.GetAccess();
        // Set flags based on access level
        this.isLevel1 = accessLevel === this._authenticateService.level1;
        this.isLevel2 = accessLevel === this._authenticateService.level2;

        // Parse the result data
        this.UserManagementDetails = JSON.parse(result.Data);
        // Destructure properties for easier access
        const { usersList, clientStudyList,clientStudyARKList, moduleList, adminAccessList, ClientSnapshotNameList,clientStudyBrandList } = this.UserManagementDetails;

        // Map and update the usersList
        this.usersList = this.mapUsersList(usersList, adminAccessList, clientStudyList);
        this.clientStudyList = clientStudyList;
        this.clientStudyARKList=clientStudyARKList
        this.clientStudyBrandList=clientStudyBrandList;
        this.moduleList = moduleList;
        this.adminAccessList = adminAccessList;
        this.clientSnapshotNameList = ClientSnapshotNameList;
        // Hide loader after processing data
        this._loaderService.hideLoader();
      }
    });
  }

  // Extracted function to map and update usersList
  private mapUsersList(usersList: Users[], adminAccessList: any[], clientStudyList: ClientStudyNames[]): Users[] {
    // Check if usersList is empty
    if (!usersList || usersList.length === 0) {
      return [];
    }
    // Create an array of client names
    const clientNames = clientStudyList.map(({ client_name, study_name }: ClientStudyNames) => `${client_name} : ${study_name}`);

    // Map and update each user in the usersList
    return usersList.map((user: Users) => {
      // Check if user has access and access items are present
      if (user.access && user.access.length > 0) {
        // Set moduleName based on the first access item
        user.moduleName = user.access[0].module_name;
        // Filter access items based on client names
        const listAccessItems = user.access.filter((access: access) => {
          return clientNames.includes(`${access.client_name} : ${access.study_name}`);
        });
        // Set clientStudyName based on the number of filtered access items
        user.clientStudyName = listAccessItems.length === 1 ? `${user.access[0].client_name} : ${user.access[0].study_name}` : "Multiple";
      }
      return user;
    });
  }

  OndeleteUserClick(user: Users) {
    // Set confirmation type to "delete"
    this.confirmationType = "delete";
    // Store the selected user for deletion
    this.selectedUser = user;
    // Prepare the confirmation message
    let message = "Are you sure you want to delete the user?";
    // Show the confirmation dialog with the prepared message
    this.showConfirmationDialog(message);
  }

  toggleClientDropdown(index: number, user: Users) {
    this.showEntryPoints=false;
    this.clientStudyOption=null
    // Toggle selectedClientCell based on the current state
    this.selectedClientCell = this.selectedClientCell === index ? null : index;
    // Check if clientStudyList exists and clone it
    if (this.clientStudyList && (user.moduleName == this.CrossTabName|| user.moduleName?.toLocaleLowerCase() == "data upload") ) {
      // Clone clientStudyList and map it with modified client_study_name property
      this.clientStudyItems = this.cloneObject(this.clientStudyList).map((x: ClientStudyNames) => {
        x.client_study_name = `${x.client_name}:${x.study_name}`;
        return x;
      });
    }
    else if (this.clientStudyARKList && user.moduleName == this.ArkName  ) {
      // Clone clientStudyList and map it with modified client_study_name property
      this.clientStudyItems = this.cloneObject(this.clientStudyARKList).map((x: ClientStudyNames) => {
        x.client_study_name = `${x.client_name}:${x.study_name}`;
        return x;
      });
    }
    else if (this.clientStudyBrandList && user.moduleName == this.BrandAnalysisName  ) {
      // Clone clientStudyList and map it with modified client_study_name property
      this.clientStudyItems = this.cloneObject(this.clientStudyBrandList).map((x: ClientStudyNames) => {
        x.client_study_name = `${x.client_name}:${x.study_name}`;
        return x;
      });
    }
    else if (this.clientSnapshotNameList && user.moduleName == 'Snapshot') {
      this.clientSnapshotItems = this.cloneObject(this.clientSnapshotNameList).map((x: ClientSnapshotNames) => {
        x.client_snapshot_name = `${x.client_name}:${x.snapshot_name}`;
        return x;
      });
    }

    // Check if user and user.access exist
    if (user != null && user.access != null && user.access.length > 0) {
      // Iterate over user's access items and mark corresponding clientStudyItems as selected
      if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName||user.moduleName == this.ArkName||user.moduleName == this.DataUploadName) {
        user.access.forEach((x: access) => {
          if (x.module_name === user.moduleName) {
            // Find the matching study in clientStudyItems
            const study1 = this.clientStudyItems.find((y: ClientStudyNames) => y.client_name === x.client_name && y.study_name === x.study_name);
            // If study found, mark it as selected
            if (study1 != null) {
              study1.selectedtype = x.selectedtype;
              if(x.role_name == 'Admin'){
                study1.is_disabled=true
              }
            }
          }
        });
      }
      else if (user.moduleName == 'Snapshot') {
        user.access.forEach((x: access) => {
          if (x.module_name === user.moduleName) {
            // Find the matching study in clientSnapshotItems
            const study2 = this.clientSnapshotItems.find((y: ClientSnapshotNames) => y.client_name === x.client_name && y.snapshot_name === x.snapshot_name);
            // If study found, mark it as selected
            if (study2 != null) {
              study2.selectedtype = x.selectedtype;
              study2.isSelected = true;
            }
            //  ;
          }
        });
      }

    }
    // ;
    // Check if adminAccessList exists and user is Level 2
    if (this.adminAccessList != null && this.adminAccessList.length > 0 && this.isLevel2) {
      if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName ||user.moduleName == this.ArkName) {
        // Mark clientStudyItems as disabled based on adminAccessList and user's module
        this.clientStudyItems.forEach((y: ClientStudyNames) => {
          // Check if there's a matching access item in adminAccessList
          const isFound = this.adminAccessList.some((x: access) =>
            x.client_name === y.client_name && x.study_name === y.study_name && x.module_name === user.moduleName
          );
          // Set is_disabled property based on whether a match is found
          y.is_disabled = !isFound;
        });
      }
      else if (user.moduleName == 'Snapshot') {
        // Mark clientStudyItems as disabled based on adminAccessList and user's module
        this.clientSnapshotItems.forEach((y: ClientSnapshotNames) => {
          // Check if there's a matching access item in adminAccessList
          const isFound = this.adminAccessList.some((x: access) =>
            x.client_name === y.client_name && x.snapshot_name === y.snapshot_name && x.module_name === user.moduleName
          );
          // Set is_disabled property based on whether a match is found
          y.is_disabled = !isFound;
        });
      }

    }
    // ;
  }

  /**
 * Toggles the dropdown state for module selection and updates the list of modules.
 * Marks the module selected if it matches the user's moduleName.
 *
 * @param index - The index of the selected module dropdown.
 * @param user - The user object containing information about the selected module.
 */
  toggleModuleDropdown(index: number, user: Users) {
   
    // Toggle selectedmoduleCell based on the current state
    this.selectedmoduleCell = this.selectedmoduleCell === index ? null : index;
    // Check if moduleList exists and clone it
    if (this.moduleList) {
      // Clone moduleList
      this.modulesItems = this.cloneObject(this.moduleList);
    }
    // Check if user and user.moduleName exist
    if (user != null && user.moduleName != null) {
      // Mark the corresponding module in modulesItems as selected
      this.modulesItems.forEach((x: modules) => {
        // Check if the module matches user's moduleName
        x.is_selected = x.module_name === user.moduleName;
      });
    }
  }



 
  /**
  * Handles the checkbox state for a ClientStudy option and updates the user's access data accordingly.
  *
  * @param clientstudyoption - The selected ClientStudy option.
  * @param user - The user object to update with access data.
  */
  OnCheckboxClientStudy(clientstudyoption: ClientStudyNames, user: Users,ind:number) {

    
    // Toggle the isSelected state for the ClientStudy option
    if (clientstudyoption.selectedtype == null) {
      clientstudyoption.selectedtype = 1;
    } else {
      clientstudyoption.selectedtype = clientstudyoption.selectedtype==0?1:0;
    }
    // Check if user is not null
    if (user != null) {
      // Initialize user.access if it is null
      user.access = user.access == null ? [] : user.access;
      // Find the index of the selected ClientStudy option in user.access
      let index = user.access.findIndex((x: access) => {
        return x.client_name == clientstudyoption.client_name &&
          x.study_name == clientstudyoption.study_name &&
          x.module_name == user.moduleName;
      });
      // Update user.access based on the isSelected state of the ClientStudy option
      if (index === -1) {
        // If not found in user.access, add a new access item if isSelected is true
        if (clientstudyoption.selectedtype==1) {
          let module = user.moduleName == undefined ? "" : user.moduleName;

          user.access.push({
            client_name: clientstudyoption.client_name,
            study_name: clientstudyoption.study_name,
            is_active: true,
            module_name: module,
            role_name: "",
            snapshot_name: "",
            entrypoints:[],
            selectedtype:1
          });
        }
      } else {
        // If found in user.access, remove the access item if isSelected is false
        if (clientstudyoption.selectedtype==0) {
          user.access.splice(index, 1);
        }
      }
      // Update the clientStudyName property in the user object
      this.displayClientStudyName(user);
    }
    this.showEntryPoints=false;
    this.clientStudyOption=null;
    if(clientstudyoption.isEntryPointPresent)
      this.GetEntryPointList(clientstudyoption,user,"0")
  }

  OnCheckboxClientSnapshot(clientstudyoption: ClientSnapshotNames, user: Users) {
    // Toggle the isSelected state for the ClientStudy option
    if (clientstudyoption.selectedtype == null) {
      clientstudyoption.selectedtype = 1;
    } else {
      clientstudyoption.selectedtype = clientstudyoption.selectedtype == 0 ? 1 : 0;
    }
    // Check if user is not null
    if (user != null) {
      // Initialize user.access if it is null
      user.access = user.access == null ? [] : user.access;
      // Find the index of the selected ClientStudy option in user.access
      let index = user.access.findIndex((x: access) => {
        return x.client_name == clientstudyoption.client_name &&
          x.snapshot_name == clientstudyoption.snapshot_name &&
          x.module_name == user.moduleName;
      });
      // Update user.access based on the isSelected state of the ClientStudy option
      if (index === -1) {
        // If not found in user.access, add a new access item if isSelected is true
        if (clientstudyoption.selectedtype == 1) {
          let module = user.moduleName == undefined ? "" : user.moduleName;
          user.access.push({
            client_name: clientstudyoption.client_name,
            snapshot_name: clientstudyoption.snapshot_name,
            is_active: true,
            module_name: module,
            role_name: "",
            study_name: clientstudyoption.study_name,
            entrypoints:[],
            selectedtype:1
          });
        }
      } else {
        // If found in user.access, remove the access item if isSelected is false
        if (clientstudyoption.selectedtype == 0) {
          user.access.splice(index, 1);
        }
      }
      // Update the clientStudyName property in the user object
      this.displayClientStudyName(user);
    }
    this.showEntryPoints = false;
    this.clientSnapshotOption = null;
    if (clientstudyoption.isEntryPointPresent)
      this.GetEntryPointListForSnapshot(clientstudyoption, user, "0")
  }

  /**
 * Handles the click event for toggling the super_admin property of a user.
 * Clears user access data, clientStudyName, and moduleName if super_admin is true.
 *
 * @param user - The user object to update.
 */
  OnSuperAdminClick(user: Users) {
    // Toggle the super_admin property
    user.super_admin = !user.super_admin;

    // Check if user is set as super_admin
    if (user.super_admin) {
      // If super_admin is true, clear user access data, clientStudyName, and moduleName
      user.access = [];
      user.clientStudyName = null;
      user.moduleName = null;
    }
  }

  /**
 * Handles the radio button toggle event for selecting a module.
 * Updates the user's moduleName and triggers the update of clientStudyName.
 *
 * @param module - The selected module.
 * @param user - The user object to update with the selected module.
 */
  OnRadioModuleToggle(module: modules, user: Users) {
    // Set the is_selected property of the clicked module to true
    module.is_selected = true;

    // Find any other selected module and set its is_selected property to false
    let otherSelected = this.modulesItems.find((x: modules) => x.is_selected && x.module_name !== module.module_name);
    if (otherSelected != null && otherSelected != undefined) {
      otherSelected.is_selected = false;
    }
    // Update the user's moduleName with the selected module's module_name
    user.moduleName = module.module_name;
    // Trigger the update of the clientStudyName based on the new moduleName
    this.displayClientStudyName(user);
  }

  /**
 * Sets the clientStudyName property for a user based on their access data.
 *
 * @param user - The user object for which to determine the clientStudyName.
 */
  displayClientStudyName(user: Users) {
    // Check if user has no access data
    if (user.access == null || user.access.length === 0) {
      user.clientStudyName = null;
    } else {
      // Filter accessData based on the module name
      let accessData = user.access.filter((x: access) => x.module_name === user.moduleName);

      // Check if filtered accessData exists and has elements
      if (accessData != null && accessData.length > 0) {
        if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName||user.moduleName == this.ArkName || user.moduleName?.toLocaleLowerCase() == "data upload") {
          // Check the number of access items
          if (accessData.length > 1) {
            // If multiple access items, set clientStudyName to "Multiple"
            user.clientStudyName = "Multiple";
          } else {
            // If a single access item, set clientStudyName based on client and study names
            user.clientStudyName = `${accessData[0].client_name} : ${accessData[0].study_name}`;
          }
        }
        else if (user.moduleName == 'Snapshot') {
          // Check the number of access items
          if (accessData.length > 1) {
            // If multiple access items, set clientStudyName to "Multiple"
            user.clientStudyName = "Multiple";
          } else {
            // If a single access item, set clientStudyName based on client and study names
            user.clientStudyName = `${accessData[0].client_name} : ${accessData[0].snapshot_name}`;
          }
        }

      } else {
        // If no matching access data, set clientStudyName to null
        user.clientStudyName = null;
      }
    }
  }

  /**
  * Handles the click event for submitting users.
  * Sets the confirmationType to "submit" and shows a confirmation dialog.
  */
  onSubmitClick() {
    // Set confirmationType to "submit" to identify the type of action
    this.confirmationType = "submit";
    // Prepare the confirmation message
    let message = "Are you sure you want to submit users?";
    // Show the confirmation dialog with the prepared message
    this.showConfirmationDialog(message);
  }

  /**
 * Handles the insertion of users by calling the updateUsers method of the management service.
 * Shows a loader during the process and displays an alert popup based on the result.
 */
  insertUsers() {
    // Show loader while updating users
    this._loaderService.showLoader();
    // Call the updateUsers method to insert/update users
    this._managementService.updateUsers(this.usersList).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Check if the result Data is not null and equals "1" (assuming it represents success)
        if (result.Data != null && result.Data === "1") {
          // Display a success alert popup
          this.AlertPopup("Submitted successfully", "Message");
        }
      }
      // Hide the loader after the update process
      this._loaderService.hideLoader();
    });
  }

  /**
 * Handles the deletion of a user by calling the deleteUser method of the management service.
 * Shows a loader during the process and updates the usersList based on the result.
 * Displays an alert popup if the user deletion is successful.
 */
  deleteUsers() {
    // Show loader while deleting user
    this._loaderService.showLoader();
    // Call the deleteUser method to delete the selected user
    this._managementService.deleteUser(this.selectedUser).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Check if the result Data is not null and equals "1" (assuming it represents success)
        if (result.Data != null && result.Data === "1") {
          // Find the index of the selected user in usersList
          let index = this.usersList.findIndex((x: Users) => x.email == this.selectedUser.email);
          // If the user is found, remove it from usersList
          if (index > -1)
            this.usersList.splice(index, 1);
          // Display a success alert popup
          this.AlertPopup("User deleted successfully", "Message");
        }
      }
      // Hide the loader after the deletion process
      this._loaderService.hideLoader();
    });
  }


  /**
 * Displays an alert popup with the provided message and optional header text.
 *
 * @param message - The message to be displayed in the alert popup.
 * @param headerTxt - Optional header text for the alert popup (default is "Error").
 */
  AlertPopup(message: string, headerTxt: string = "Error"): void {
    // Create an alert object with the specified header and message
    const alertObj: AlertMessageModel = { header: headerTxt, message: message, show: true };
    // Show the alert popup using the alert service
    this._alertService.showAlert(alertObj);
  }

  /**
   * Shows a confirmation dialog with the provided message.
   *
   * @param message - The message to be displayed in the confirmation dialog.
   */
  showConfirmationDialog(message: string) {
    // Set the confirmation message for the dialog
    this.confirmationMessage = message;
    // Set the flag to show the confirmation dialog
    this.isShowDialog = true;
  }


  onConfirmation(operation: number) {
    if (operation === 1) {
      // If the action is confirmed, execute the corresponding action based on the confirmation type
      switch (this.confirmationType) {
        case "submit":
          // Execute the insertUsers function for submitting users
          this.insertUsers();
          break;
        case "delete":
          // Execute the deleteUsers function for deleting users
          this.deleteUsers();
          break;
        default:
          // Hide the dialog if no specific action is associated with the confirmation type
          this.isShowDialog = false;
          break;
      }
      // Hide the dialog after executing the action
      this.isShowDialog = false;
    } else {
      // User canceled the action, hide the dialog
      this.isShowDialog = false;
    }
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object))
  }
  /**
    * validate the input which is null or empty
    * @param input - . string input
    * @returns {boolean}
    */
  isNullOrEmpty(input: string | null): boolean {
    return input === null || input.trim() === '';
  }
  // Listen for click events on the document.
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
    // Check if the click event occurred outside the <div> block.
    const targetElement = event.target as HTMLElement;
    if (this.selectedClientCell != null) {
      if (!this.isClickInside(event, ".client-study-container")) {
        if(targetElement.className!="ok-btn"){
          this.selectedClientCell = null;
          this.searchClientStudy = "";
        }
      }
      
    }
    if (this.selectedmoduleCell != null) {
      if (!this.isClickInside(event, ".module-container")) {
        this.selectedmoduleCell = null;
        this.searchModule = "";
      }
    }

  }
  // Helper function to check if the click occurred inside the <div> block.
  private isClickInside(event: Event, className: string): boolean {
    const elements = document.querySelectorAll(className);
    for (let i = 0; i < elements.length; i++) {
      const container = elements[i];
      if (container.contains(event.target as Node)) {
        return true;
      }
    }
    return false;
  }

  onUserUpload(event: Event, type: string) {
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files.length > 0) {
      if (input.files[0]) {
        const selectedFile = input.files[0];
        const maxSizeInBytes = 30 * 1024 * 1024; // 10 MB
        const allowedFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        if (!allowedFileTypes.includes(selectedFile.type)) {
          this.AlertPopup("Invalid file type.", "Alert");
        }


        else if (selectedFile.size > maxSizeInBytes) {
          // Handle file size exceeds limit error
          this.AlertPopup("File size exceeds the maximum limit", "Alert");

        }

        else {
          this.uploadFile(selectedFile);
        }
        this.resetFileInput();
      }
    }
  }

  uploadFile(file: File): void {

    this._loaderService.showLoader();

    this._managementService.uploadFile(file).subscribe((result: results) => {
      if (result.HasValidData) {
        //if (result.Data == '2') {
          this.AlertPopup(result.Data, "Message");
      // }
      //  else {
        //  this.AlertPopup("Users uploaded successfully", "Message");
          this.getAllUsers();
      //  }
      }
      else {
        this.AlertPopup("Users upload failed", "Message");
      }
      

      this._loaderService.hideLoader();
    });
  }

  resetFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }
  onTemplateClick() {
    this._loaderService.showLoader();
    this._managementService
      .staticTemplate()
        .subscribe(
          (excelBlob: Blob) => {
            let fileName ='BulkUserTemplate.xlsx'
            // Trigger file download
            this.downloadFile(excelBlob, fileName);
            this._loaderService.hideLoader();
          },
          (error) => {
            console.error('Error exporting to Excel', error);
            this._loaderService.hideLoader();
          }
        );
    

  }


  private downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  lastClicked: any;
  OnClickClientSnapshot(clientSnapshot: ClientSnapshotNames, user: Users, index: number) {
    this.lastClicked = {
      clientSnapshot: clientSnapshot,
      user: user,
      index: index
    }
    this.entryPointSelectedList = [];
    this.errorShow = false;
    this.errorMessage = ""
    if (clientSnapshot.isEntryPointPresent) {

      this.selectedClientSnapshot = clientSnapshot;
      this.entryPointDropDownShow = null;
      this.showEPnextLevel = false;
      this.entryPointSelectedList = JSON.parse(JSON.stringify(this.getAccessEntryPoint(user)))
      if (this.clientSnapshotOption === index) {
        this.clientSnapshotOption = this.clientSnapshotOption === index ? null : index;
        this.showEntryPoints = false;
      }
      else {
        this.clientSnapshotOption = index
        this.showEntryPoints = true;
        this.GetEntryPointListForSnapshot(clientSnapshot, user, "1")
      }
    }
    else {
      this.showEntryPoints = false;
      this.clientSnapshotOption = null
    }
  }
  GetEntryPointListForSnapshot(clientSnapshot: ClientSnapshotNames, user: Users, type: string) {
    let presentEntryPoints = this.entryPointListDetails.filter(x => x.client == clientSnapshot.client_name && x.study == clientSnapshot.study_name);
    if (presentEntryPoints.length > 0) {
      this.GetEntryPointNames(presentEntryPoints);
      if (type == "0") {
        this.AssignAllEntryPointsForSnapshot(user, clientSnapshot)
      }
    }
    else {
      this._loaderService.showLoader();
      this._managementService.getEntryPointDetails(clientSnapshot.client_name, clientSnapshot.study_name).subscribe((result: results) => {
        // Check if the response has valid data
        if (result.HasValidData) {
          if (result.Data != null) {
            let entryPointList: EntryPointDetails[] = JSON.parse(result.Data);
            if (entryPointList != null) {
              if (this.entryPointListDetails.length > 0)
                this.entryPointListDetails = [...this.entryPointListDetails, ...entryPointList];
              else
                this.entryPointListDetails = entryPointList;
              this.GetEntryPointNames(entryPointList);
              if (type == "0") {
                this.AssignAllEntryPointsForSnapshot(user, clientSnapshot)
              }
            }
          }
        }
        // Hide the loader after the deletion process
        this._loaderService.hideLoader();
      });
    }
  }
  AssignAllEntryPointsForSnapshot(user: Users, clientSnapshotOption: ClientSnapshotNames) {
    let metricOptions = this.entryPointListDetails.filter((x: EntryPointDetails) => { return x.client == clientSnapshotOption.client_name && x.study == clientSnapshotOption.study_name });
    if (metricOptions != null && metricOptions.length > 0) {
      if (user.access != null) {
        let accessEntry = user.access.find((x: access) => { return x.client_name == clientSnapshotOption.client_name && x.study_name == clientSnapshotOption.study_name && x.snapshot_name==clientSnapshotOption.snapshot_name && x.module_name==user.moduleName });
        if (accessEntry != null) {
          accessEntry.entrypoints = [];
          metricOptions.forEach((x: EntryPointDetails) => {
            let isPresent = accessEntry?.entrypoints.some((y: EntryPointDetails) => y.metricOption == x.metricOption)
            if (!isPresent) {
              accessEntry?.entrypoints.push(x)
            }
          })
        }
      }
    }
  }

  OnClickClientStudy(clientstudy: ClientStudyNames, user: Users, index: number) {
    this.entryPointSelectedList = [];
    this.errorShow = false;
    this.errorMessage = ""
    if (clientstudy.isEntryPointPresent) {

      this.selectedClientStudy = clientstudy;
      this.entryPointDropDownShow = null;
      this.showEPnextLevel = false;
      this.entryPointSelectedList = JSON.parse(JSON.stringify(this.getAccessEntryPoint(user)))
      if (this.clientStudyOption === index) {
        this.clientStudyOption = this.clientStudyOption === index ? null : index;
        this.showEntryPoints=false;
      }
      else{
      this.clientStudyOption = index
      this.showEntryPoints=true;
      this.GetEntryPointList(clientstudy,user,"1")
      }
    }
    else{
      this.showEntryPoints=false;
      this.clientStudyOption=null
    }
  }
GetEntryPointList(clientStudyOption:ClientStudyNames,user:Users,type:string){
  let presentEntryPoints=this.entryPointListDetails.filter(x=>x.client==clientStudyOption.client_name&&x.study==clientStudyOption.study_name);
  if(presentEntryPoints.length>0){
    this.GetEntryPointNames(presentEntryPoints);
    if(type=="0"){
      this.AssignAllEntryPoints(user,clientStudyOption)
    }
  }
  else{
    this._loaderService.showLoader();
    this._managementService.getEntryPointDetails(clientStudyOption.client_name,clientStudyOption.study_name).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        if (result.Data != null ) {
          let entryPointList:EntryPointDetails[] = JSON.parse(result.Data);
          if(entryPointList!=null){
            if(this.entryPointListDetails.length>0)
              this.entryPointListDetails = [...this.entryPointListDetails, ...entryPointList];
            else
            this.entryPointListDetails=entryPointList;
            this.GetEntryPointNames(entryPointList);
            if(type=="0"){
              this.AssignAllEntryPoints(user,clientStudyOption)
            }
          }
        }
      }
      // Hide the loader after the deletion process
      this._loaderService.hideLoader();
    });
  }
}
AssignAllEntryPoints(user:Users,clientStudyOption:ClientStudyNames){
  let metricOptions=this.entryPointListDetails.filter((x:EntryPointDetails)=>{return x.client==clientStudyOption.client_name && x.study==clientStudyOption.study_name});
  if(metricOptions!=null && metricOptions.length>0){
    if(user.access!=null){
      let accessEntry=user.access.find((x:access)=>{return x.client_name==clientStudyOption.client_name && x.study_name==clientStudyOption.study_name && x.snapshot_name=="" && x.module_name==user.moduleName});
      if(accessEntry!=null){
        accessEntry.entrypoints=[];
        metricOptions.forEach((x:EntryPointDetails)=>{
          let isPresent=accessEntry?.entrypoints.some((y:EntryPointDetails)=>y.metricOption==x.metricOption)
          if(!isPresent){
            accessEntry?.entrypoints.push(x)
          }
        })
      }
    }
  }
}
GetEntryPointNames(entryPointList:EntryPointDetails[]){
  this.entryPointNames=[];
  let epData=entryPointList.sort((a, b) => a.sortid - b.sortid);
    if(epData!=null && epData.length>0){
        epData.forEach((x:EntryPointDetails)=>{
          if(!this.entryPointNames.some((y:EntryPointDetails)=>y.level1==x.level1))
          this.entryPointNames.push(this.createEntryPointObject(x))
        })
    }
}
  onEntryPointClick(entryPointItem:EntryPointDetails,index:number){
    entryPointItem.is_selected=!entryPointItem.is_selected;
    this.errorShow=false;
  this.errorMessage=""
    this.showEPnextLevel=false;
    this.selectedEntryPoint=entryPointItem;
    this.levelIndexValue=null;
    if(this.entryPointDropDownShow===index){
      this.entryPointDropDownShow=null;
    }
    else{
      this.entryPointDropDownShow=index;
      this.entryPointLevels=[];
      let epData=this.entryPointListDetails.filter(x=>x.level1==entryPointItem.level1 && x.client==entryPointItem.client && x.study==entryPointItem.study);
      if(epData!=null && epData.length>0){
        epData.sort((a,b)=>a.sortid-b.sortid)
        epData.forEach((x:EntryPointDetails)=>{
          if(!this.entryPointLevels.some((y:EntryPointDetails)=>y.level2==x.level2))
            this.entryPointLevels.push(this.createEntryPointObject(x))
        })
    }
    }
    
  }
  onEntryPointSubLevelClick(level1Item:EntryPointDetails,index:number,entryPointIndex:number,user:Users){
    this.showEPnextLevel=true;
    this.levelIndexValue=index;
    this.entryPointMetricOption=[];
    this.errorShow=false;
  this.errorMessage=""
    let epData=this.entryPointListDetails.filter(x=>x.level1==this.selectedEntryPoint?.level1 &&x.level2==level1Item.level2 && x.client==level1Item.client && x.study==level1Item.study);
    if(epData!=null && epData.length>0){
      epData.forEach((x:EntryPointDetails)=>{
        //if(!this.entryPointMetricOption.some((y:EntryPointDetails)=>y.metricOption==x.metricOption)){
          this.entryPointMetricOption.push(this.createEntryPointObject(x))
       // }
      })
      
    }
    if(entryPointIndex==1){
      let Level1Names=this.entryPointNames[entryPointIndex-1].level1
      let items=this.entryPointSelectedList.filter((x:EntryPointDetails)=>{return x.level1==Level1Names})
      let metricOption:EntryPointDetails[]=[];
      if(items.length>0){
        let ep2:any=[];
        items.forEach((y:EntryPointDetails)=>{
          ep2.push(y.e2_variable_code)
          let result=this.entryPointMetricOption.find((x:EntryPointDetails)=>{return x.e1_variable_code==y.e1_variable_code && x.e2_variable_code==y.e2_variable_code} )
          if(result!=null){
            if(!metricOption.some((z:EntryPointDetails)=>z.metricOption==result?.metricOption))
              metricOption.push(result)
          }
        });
        if(ep2.length>0){
          if(metricOption.length==0)
          this.entryPointMetricOption=this.entryPointMetricOption.filter((x:EntryPointDetails)=>{return ep2.indexOf(x.e2_variable_code)>-1} )
        else
        this.entryPointMetricOption=metricOption.filter((x:EntryPointDetails)=>{return ep2.indexOf(x.e2_variable_code)>-1} )
        }
      }
      else{
        this.entryPointMetricOption=[]
      }
      
    }
    if(entryPointIndex==2){
      let Level1Names=this.entryPointNames[entryPointIndex-1].level1
      let items=this.entryPointSelectedList.filter((x:EntryPointDetails)=>{return x.level1==Level1Names})
      let metricOption:EntryPointDetails[]=[];
      if(items.length>0){
        let ep3:any=[];
        items.forEach((y:EntryPointDetails)=>{
          ep3.push(y.e3_variable_code)
          let result=this.entryPointMetricOption.find((x:EntryPointDetails)=>{return x.e1_variable_code==y.e1_variable_code && x.e2_variable_code==y.e2_variable_code && x.e3_variable_code==y.e3_variable_code} )
          if(result!=null){
            if(!metricOption.some((z:EntryPointDetails)=>z.metricOption==result?.metricOption))
              metricOption.push(result)
          }
        });
        if(ep3.length>0){
          if(metricOption.length==0)
          this.entryPointMetricOption=this.entryPointMetricOption.filter((x:EntryPointDetails)=>{return ep3.indexOf(x.e3_variable_code)>-1} )
          else
          this.entryPointMetricOption=metricOption.filter((x:EntryPointDetails)=>{return ep3.indexOf(x.e3_variable_code)>-1} )
        }
      }
      else{
        this.entryPointMetricOption=[]
      }
    }
    if(this.entryPointMetricOption.length>0){
      this.entryPointSelectedList.forEach((x:EntryPointDetails)=>{
      let isFound=this.entryPointMetricOption.find((y:EntryPointDetails)=>{return y.metricOption==x.metricOption})
      if(isFound!=null){
        isFound.is_selected=true;
      }
    })
  }
    let isAnyMetricFalse=this.entryPointMetricOption.some((x:EntryPointDetails)=>{return !x.is_selected})
      if(isAnyMetricFalse)
        this.epSelecteAll=false;
      else
      this.epSelecteAll=true;
  }
  OnEpCheckboxClick(metricOption:EntryPointDetails,user:Users){
    metricOption.is_selected=!metricOption.is_selected;
    this.errorShow=false;
    this.errorMessage=""
    this.CheckUncheckEntryPoints(metricOption)
    if(this.entryPointMetricOption.length>0){
      let isAnyMetricFalse=this.entryPointMetricOption.some((x:EntryPointDetails)=>{return !x.is_selected})
      if(isAnyMetricFalse)
        this.epSelecteAll=false;
      else
      this.epSelecteAll=true;
    }
    this.displayClientStudyName(user)
  }
  CheckUncheckEntryPoints(metricOption:EntryPointDetails){
    let metricIndex= this.entryPointSelectedList.findIndex((x:EntryPointDetails)=>{
      return x.metricOption==metricOption.metricOption
    })
    if(metricOption.is_selected){
      if(metricIndex==-1){
        this.entryPointSelectedList.push(metricOption)
      }
    }
    else{
      if(metricIndex>-1){
        this.removeEntryPoint(metricOption)
      }
    }

    
    if(this.entryPointDropDownShow!=null && this.entryPointDropDownShow==0){
      let level1Names:any=[]
      level1Names.push(this.selectedEntryPoint.level1)
      this.removeNextLevelEntryPoint(level1Names)
    }
    if(this.entryPointDropDownShow!=null && this.entryPointDropDownShow==1){
      this.entryPointNames
      let level1Names:any=[]
      for(let i=0;i<=this.entryPointNames.length;i++){
        level1Names.push(this.entryPointNames[i].level1)
        if(i==this.entryPointDropDownShow)
          break;
      }
      this.removeNextLevelEntryPoint(level1Names)
    }
  }
  OnSelectAllEp(metricOptionList:EntryPointDetails[]){
    this.epSelecteAll=!this.epSelecteAll;
    metricOptionList.forEach((x:EntryPointDetails)=>{
      x.is_selected=this.epSelecteAll;
      this.CheckUncheckEntryPoints(x)
    })
  }
  createEntryPointObject(data:EntryPointDetails){
    let entryPoint:EntryPointDetails=JSON.parse(JSON.stringify(data))
    return entryPoint;
  }

  // addUserAccessEntryPoint(user:Users,data:EntryPointDetails){
  //   if(user.access==null && data!=null){
  //       user.access=[];
  //       this.addAccessForUser(user,data)
  //   }
  //   else{
  //     let accesFound=user.access?.find((x:access)=>{return x.client_name==data.client && x.study_name==data.study})
  //     if(accesFound!=null){
  //       //accesFound.entrypoints=[];
  //       //if(accesFound.entrypoints==null || accesFound.entrypoints.length==0){
  //         accesFound.entrypoints.push(data)
  //      // }
  //     }
  //     else{
  //       this.addAccessForUser(user,data)
  //     }
  //   }

  // }
removeEntryPoint(data:EntryPointDetails)
{
  let entryPoint=this.entryPointSelectedList.findIndex((x:EntryPointDetails)=>{return x.metricOption==data.metricOption})
  if(entryPoint>-1){
    this.entryPointSelectedList.splice(entryPoint, 1);
  }
}


  // removeEntryPoint(user:Users,data:EntryPointDetails){
  //   if(user.access!=null && data!=null){
  //     let accesFound=user.access?.find((x:access)=>{return x.client_name==data.client && x.study_name==data.study})
  //     if(accesFound!=null){
  //       if(accesFound.entrypoints!=null && accesFound.entrypoints.length>0){
  //         let entryPoint=accesFound.entrypoints.findIndex((x:EntryPointDetails)=>{return x.metricOption==data.metricOption})
  //         if(entryPoint>-1){
  //           accesFound.entrypoints.splice(entryPoint, 1);
  //         }
  //         if(accesFound.entrypoints.length==0){
  //           let foundIndex=user.access?.findIndex((x:access)=>{return x.client_name==accesFound?.client_name && x.study_name==accesFound?.study_name && x.module_name==user.moduleName})
  //           if(foundIndex>-1)
  //           {
  //             user.access?.splice(foundIndex, 1);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // addAccessForUser(user:Users,data:EntryPointDetails){
  //   let epItem:EntryPointDetails[]=[]
  //   epItem.push(data)
  //   user.access?.push({
  //     client_name: data.client,
  //     study_name: data.study,
  //     is_active: true,
  //     module_name: user.moduleName==null?"":user.moduleName,
  //     role_name: "",
  //     snapshot_name: "",
  //     entrypoints:epItem,
  //     selectedtype:1
  //   });
  // }
  // MakeSelectClientStudy(user:Users,data:EntryPointDetails){
  //   let length=this.entryPointNames.length;
  //   if(length>0){
  //     this.checkForSelectedEntryPoint(user,this.entryPointNames[0])
  //   }
  // }

  // checkForSelectedEntryPoint(user:Users,data:EntryPointDetails){
  //   let selectedName=this.entryPointListDetails.filter((x:EntryPointDetails)=>{return x.client==data.client && x.study==data.study && x.level1==data.level1}).map((x:EntryPointDetails)=>x.metricOption)
  //     if(selectedName.length>0){
  //       const uniqueStrings = Array.from(new Set(selectedName));
  //       if(user.access!=null && user.access.length>0){
  //         let accessItem=user.access.find((x:access)=>{return x.client_name==data.client && x.study_name==data.study && x.module_name==user.moduleName})
  //         if(accessItem!=null){
  //           if(accessItem.entrypoints!=null && accessItem.entrypoints.length>0){
  //             let epname= accessItem.entrypoints.filter((x:EntryPointDetails)=>{return selectedName.indexOf(x.metricOption)>-1})
  //             if(epname.length==uniqueStrings.length){
  //               this.selectedClientStudy.selectedtype=1;
  //               accessItem.selectedtype=1;
  //             }
  //             else{
  //               this.selectedClientStudy.selectedtype=2
  //               accessItem.selectedtype=2;
  //             }
  //           }
  //           else{
  //             this.selectedClientStudy.selectedtype=0;
  //             accessItem.selectedtype=0;
  //           }
  //         }
  //         else{
  //           this.selectedClientStudy.selectedtype=0;
  //         }
          
  //       }
  //       else{
  //         this.selectedClientStudy.selectedtype=0;
  //       }
  //     }
      
  // }
  getAccessEntryPoint(user: Users) {
    let result: EntryPointDetails[] = []
    if (user.access != null && user.access.length > 0) {
      if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName||user.moduleName == this.ArkName) {
        let clientStudy = user.access.find((x: access) => {
          return x.client_name == this.selectedClientStudy.client_name && x.study_name == this.selectedClientStudy.study_name && x.module_name == user.moduleName
        });
        if (clientStudy != null && clientStudy.entrypoints != null) {
          result = clientStudy.entrypoints;
        }
      }
      else if (user.moduleName == "Snapshot") {
        let clientSnapshot = user.access.find((x: access) => {
          return x.client_name == this.selectedClientSnapshot.client_name && x.study_name == this.selectedClientSnapshot.study_name && x.module_name == user.moduleName && x.snapshot_name==this.selectedClientSnapshot.snapshot_name
        });
        if (clientSnapshot != null && clientSnapshot.entrypoints != null) {
          result = clientSnapshot.entrypoints;
        }
      }
    }
    return result;
  }

  removeNextLevelEntryPoint(level1: string[]) {
    if (this.entryPointSelectedList != null && this.entryPointSelectedList.length > 0) {
      // let clientStudy=this.entryPointSelectedList.find((x:access)=>{
      //   return x.client_name==this.selectedClientStudy.client_name && x.study_name==this.selectedClientStudy.study_name && x.module_name==user.moduleName
      // });
      // if(clientStudy!=null && clientStudy.entrypoints!=null){
      this.entryPointSelectedList = this.entryPointSelectedList.filter((x: EntryPointDetails) => { return level1.indexOf(x.level1) > -1 })
    }
}
OnOkClick(entryNames:EntryPointDetails[],user:Users){
  this.errorShow=false;
  this.errorMessage=""
if(entryNames.length>0){
  for (let i = 0; i < entryNames.length; i++) {
    let isFound=this.entryPointSelectedList.some((y:EntryPointDetails)=>y.level1==entryNames[i].level1 && y.client==entryNames[i].client && y.study==entryNames[i].study)
    if(!isFound){
      this.errorShow=true;
      this.errorMessage="Please select in "+entryNames[i].level1;
      break;
    }
  }
 
  if(!this.errorShow){
    let count=0;
    for (let i = 0; i < entryNames.length; i++) {
      let uniqueItems=this.entryPointListDetails.filter((x:EntryPointDetails)=>x.level1==entryNames[i].level1&& x.client==entryNames[i].client&& x.study==entryNames[i].study)
      let uniqueEp=this.entryPointSelectedList.filter((x:EntryPointDetails)=>x.level1==entryNames[i].level1&& x.client==entryNames[i].client && x.study==entryNames[i].study)
      const entrypointslist = uniqueItems.filter((ele, index, self) => 
        index === self.findIndex(e => 
          e.metricOption === ele.metricOption
        )
      );
        
      const uniqueSelectedEp = uniqueEp.filter((ele, index, self) => 
        index === self.findIndex(e => 
          e.metricOption === ele.metricOption 
        )
      );
      if(entrypointslist.length==uniqueSelectedEp.length)
      {
        count=count+1
      }
    }
    let selectedType = 2;
        if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName||user.moduleName == this.ArkName) {
          this.selectedClientStudy.selectedtype = 2
          if (count == entryNames.length) {
            selectedType = 1;
            this.selectedClientStudy.selectedtype = 1
          }
        }
        else if (user.moduleName == "Snapshot") {
          this.selectedClientSnapshot.selectedtype = 2
          if (count == entryNames.length) {
            selectedType = 1;
            this.selectedClientSnapshot.selectedtype = 1
          }
        }
    this.addOrUpdateAccessForUser(user,this.entryPointSelectedList,selectedType)
    this.showEntryPoints=false;
    this.clientStudyOption=null
    this.displayClientStudyName(user)
  }
}
}
addOrUpdateAccessForUser(user: Users, data: EntryPointDetails[], selectedType: number) {
    if (user.access != null && user.access.length > 0) {

      let isPresentClientStudy;

      if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName||user.moduleName == this.ArkName) {
        isPresentClientStudy = user.access?.find((x: access) =>
          x.client_name == this.selectedClientStudy.client_name &&
          x.study_name == this.selectedClientStudy.study_name &&
          x.module_name == user.moduleName
        );
      } else if (user.moduleName == "Snapshot") {
        isPresentClientStudy = user.access?.find((x: access) =>
          x.client_name == this.selectedClientSnapshot.client_name &&
          x.study_name == this.selectedClientSnapshot.study_name &&
          x.module_name == user.moduleName
        );
      }

      // let isPresentClientStudy = user.access?.find((x: access) => { return x.client_name == this.selectedClientStudy.client_name && x.study_name == this.selectedClientStudy.study_name && x.module_name == user.moduleName })
      if (isPresentClientStudy != null) {
        isPresentClientStudy.entrypoints = this.entryPointSelectedList;
        isPresentClientStudy.selectedtype = selectedType
      }
      else {
        this.addAccessObject(user, data, selectedType)
      }
    }
    else {
      this.addAccessObject(user, data, selectedType)
    }
  }
  addAccessObject(user: Users, entryPoints: EntryPointDetails[], selectedType: number) {
    if (user.moduleName == this.CrossTabName||user.moduleName == this.BrandAnalysisName||user.moduleName == this.ArkName) {
      user.access?.push({
        client_name: this.selectedClientStudy.client_name,
        study_name: this.selectedClientStudy.study_name,
        is_active: true,
        module_name: user.moduleName == null ? "" : user.moduleName,
        role_name: "",
        snapshot_name: "",
        entrypoints: entryPoints,
        selectedtype: selectedType
      });
    }
    else if (user.moduleName == "Snapshot") {
      user.access?.push({
        client_name: this.selectedClientSnapshot.client_name,
        study_name: this.selectedClientSnapshot.study_name,
        is_active: true,
        module_name: user.moduleName == null ? "" : user.moduleName,
        role_name: "",
        snapshot_name: this.selectedClientSnapshot.snapshot_name,
        entrypoints: entryPoints,
        selectedtype: selectedType
      });
    }

  }
}